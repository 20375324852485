import React from 'react'

function ImplantIconSVG() {
  return (
    <div className="tooltip text-black" data-tip="Implant">
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="10" cy="10" r="10" fill="#E7F4EE"/>
    <path d="M10.4659 4.81818V15H9.23295V4.81818H10.4659Z" fill="#3DA172"/>
    </svg>
    </div>
  )
}

export default ImplantIconSVG