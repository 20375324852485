import React, { useEffect, useState, useContext } from "react";
import Calendar from "react-calendar";
import { startOfMonth } from "date-fns";
import { Link } from "react-router-dom";
import AuthContext from "../contexts/AuthContext";

import axios from "axios";
import "./Calendar.css";

import { useNavigate } from "react-router-dom";
import CaseModal from "./CaseModal";
import CaseCard from "./CaseCard";

const now = new Date();
const startOfCurrentMonth = startOfMonth(now);
const monthObj = {
  0: "January",
  1: "Februrary",
  2: "March",
  3: "April",
  4: "May",
  5: "June",
  6: "July",
  7: "August",
  8: "September",
  9: "October",
  10: "November",
  11: "December"
}


function DashboardCalendar() {
  const [dailyCaseList, setDailyCaseList] = useState({})
  const [dateSelected, setDateSelected] = useState('')
  const [activeStartDate, setActiveStartDate] = useState(startOfCurrentMonth);
  const [view, setView] = useState("month");
  const [caseData, setCaseData] = useState({})
  const [ showInvoice, setShowInvoice ] = useState(false)
  const { setAuth } = useContext(AuthContext);
  const navigate = useNavigate();

  function onActiveStartDateOrViewChange({
    activeStartDate: nextActiveStartDate,
    view: nextView,
  }) {
    setActiveStartDate(nextActiveStartDate);
    setView(nextView);
  }

  const handleMonth = (e) => {
    setActiveStartDate(new Date(activeStartDate.getFullYear(), e, 1));
  };

  const setDateAsUTC = (x) => {
    let date = new Date(Date.parse(x));
    return date.toLocaleString([], { dateStyle: 'short', timeStyle: undefined })
  }

  const toDate = (x) => {
    let date = new Date(Date.parse(x));
    return date.toDateString()
  }

  const checkAndCloseDropDown = (e) => {
    let targetEl = e.currentTarget;
    if(targetEl && targetEl.matches(':focus')){
      setTimeout(function(){
        targetEl.blur();
      }, 0);
    }
  }

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get('/api/cases/current');
        // console.log(response)
        setCaseData(response.data);
        if(response.data.cases){
          let dic = {}
          response.data.cases.forEach(e => {
            let dateRef = toDate(setDateAsUTC(e.return_date))
            if(dateRef in dic){
              dic[dateRef].push(e)
            }
            else {
              dic[dateRef] = [e]
            }
          })
          // console.log(dic)
          setDailyCaseList(dic)
        }
      } catch (error) {
        if (error.response.status === 401) {
          setAuth({})
          localStorage.removeItem("appState");
          navigate("/login");
        }
        console.log(error);
      }
    }
    fetchData()
    let dic = {}
    if(caseData.cases){
    caseData.cases.forEach(e => {
      let dateRef = toDate(setDateAsUTC(e.return_date))
      if(dateRef in dic){
        dic[dateRef].push(e)
      }
      else {
        dic[dateRef] = [e]
      }
    })
    console.log(dic)
    setDailyCaseList(dic)
    }
  }, [])

  function tileContent({ date, view }) {
    // Add class to tiles in month view only
    let count = 0
    
    if (view === 'month') {
      // Check if a date React-Calendar wants to check is on the list of dates to add class to
      // if (datesToAddContentTo.find(dDate => isSameDay(dDate, date))) {
      //   return 'My content';
      // }
      if(caseData.cases){
        caseData.cases.forEach(e => {
          if(toDate(setDateAsUTC(date)) === toDate(setDateAsUTC((e.return_date)))){
            count += 1
          }
        })
        return (
              <div className="flex flex-col font-sm w-[120px] h-[90px] align-center justify-center">
                {
                  !!count &&<>
                  <button className="flex w-full bg-[#EFEFFD] h-[26px] text-[#5C59E8] rounded-[4px] font-inter font-[700] text-[14px] items-center justify-center duration-300">
                    {`${count} ${count == 1 ? " Case" : " Cases"}`}
                  </button>
                  <dialog id={`my_daily_cases`} className="modal duration-0">
                  <div className=" bg-white relative modal-box bg-white max-w-none w-[620px] text-[#333843] rounded-lg drop-shadow-lg p-4">
                  </div>
                  <form method="dialog" className="modal-backdrop">
                      <button>Close</button>                    
                  </form>
                </dialog>
                  </>
                }
              </div>
            )
      }
      else {
        return (
          <div className="flex flex-col font-sm w-[120px] h-[90px] align-center justify-center">
            {
              !!count &&<>
              <button className="flex w-full bg-[#EFEFFD] h-[26px] text-[#5C59E8] rounded-[4px] font-inter font-[700] text-[14px] items-center justify-center duration-300">
                {`${count} ${count == 1 ? " Case" : " Cases"}`}
              </button>
              <dialog id={`my_daily_cases`} className="modal duration-0">
              <div className=" bg-white relative modal-box bg-white max-w-none w-[620px] text-[#333843] rounded-lg drop-shadow-lg p-4">
              </div>
              <form method="dialog" className="modal-backdrop">
                  <button>Close</button>                    
              </form>
            </dialog>
              </>
            }
          </div>
        )
      }
    }
  }

  return (
    <div className="flex flex-row gap-6 relative">
      <div className="w-[1100px] relative shrink-0">
        <button
          className=" z-10 absolute btn btn-primary top-4 left-5 bg-[#DEDEFA] hover:bg-[#5C59E8] text-[#5C59E8] border-white hover:text-white"
          onClick={(e) => {
            e.preventDefault();
            setActiveStartDate(startOfCurrentMonth);
          }}
        >
          Today
        </button>
          {/* <select
            className="custom-select select-bordered z-10 rounded-lg absolute select bg-[#DEDEFA] top-4 right-5 text-[#5C59E8] border-white"
            onChange={(e) => {
              e.preventDefault();
              handleMonth(e.target.value);
            }}
            value={activeStartDate.getMonth()}
          >
            <option value={0}>January</option>
            <option value={1}>February</option>
            <option value={2}>March</option>
            <option value={3}>April</option>
            <option value={4}>May</option>
            <option value={5}>June</option>
            <option value={6}>July</option>
            <option value={7}>August</option>
            <option value={8}>September</option>
            <option value={9}>October</option>
            <option value={10}>November</option>
            <option value={11}>December</option>
          </select> */}
          <div
            className="dropdown z-10 rounded-lg absolute bg-[#DEDEFA] top-4 right-5"
            onChange={(e) => {
              e.preventDefault();
              handleMonth(e.target.value);
            }}
            value={activeStartDate.getMonth()}
          >
            <label tabIndex={0} className="flex select bg-[#DEDEFA] w-[130px] items-center focus:bg-[#5C59E8] hover:bg-[#5C59E8] text-[#5C59E8] border-white hover:text-white focus:text-white duration-300"
              onMouseDown={(e) => checkAndCloseDropDown(e)}>
              {monthObj[activeStartDate.getMonth()]}
            </label>
            <ul
              tabIndex={0}
              className="bg-white dropdown-content z-[1] menu p-2 drop-shadow-lg rounded-box w-52"
            >
              {Object.keys(monthObj).map((ind) => (
                <li onClick={(e) => {
                  e.preventDefault();
                  handleMonth(ind);
                  if (document.activeElement instanceof HTMLElement) {
                    document.activeElement.blur()
                  }
                }}>
                  <button>{monthObj[ind]}</button>
                </li>
              ))}
            </ul>
      
        </div>
        <Calendar
          minDetail="month"
          maxDetail="month"
          defaultView="month"
          calendarType="US"
          showFixedNumberOfWeeks={1}
          activeStartDate={activeStartDate}
          view={view}
          onActiveStartDateChange={onActiveStartDateOrViewChange}
          onViewChange={onActiveStartDateOrViewChange}
          tileContent={tileContent}
          onClickDay={(value, event) => {
            console.log('Clicked day: ',value, dailyCaseList[toDate(setDateAsUTC(value))])
            console.log(dailyCaseList[value])
            if(dailyCaseList[toDate(setDateAsUTC(value))]){
            setDateSelected(toDate(setDateAsUTC(value)), console.log(dateSelected))
            document.getElementById(`daily_modal_dash`).showModal()
            // {<dialog id="daily_modal_dash" className="modal modal-bottom sm:modal-middle">
            //   <div className="modal-box">
            //     </div>
            //     </dialog>}
            // {
            //   <dialog id="daily_modal_dash" className="modal modal-bottom sm:modal-middle">
            //   <div className="modal-box">        
            // {dailyCaseList[toDate(setDateAsUTC(value))].map((e, idx) => (
            //       <>
            //       <li key={idx}
            //         onClick={() => document.getElementById(`my_modal_dash_${idx}`).showModal()}
            //       >
            //         <a className="text-txt text-md">
            //           <div className="flex flex-col">
            //             <div>
            //               {`Dr. ${e.doctor.doctor_first} ${e.doctor.doctor_last}`}  
            //             </div>
            //             <div>
            //               {e.patient_first} {e.patient_last}
            //             </div>
            //             <div>
            //               {setDateAsUTC(e.return_date)}
            //             </div>
            //           </div>
            //           </a>
            //       </li>
            //       <dialog id={`my_modal_dash_${idx}`} className="modal duration-0">
            //         <CaseModal caseInfo={e} showInvoice={showInvoice} setShowInvoice={setShowInvoice} />
            //         <form method="dialog" className="modal-backdrop">
            //             <button>Close</button>                    
            //         </form>
            //       </dialog>
            //     </>
            //     ))}
            //      </div>
            //       </dialog>}
            }}}
          // tileContent={({ activeStartDate, date, view }) => {
          //   return view === "month" && date.getDay() === 1 ? (
          //     <div className="text-center w-[120px] h-[90px] bg-blue"></div>
          //   ) : (
          //     <div className="w-[120px] h-[90px] bg-black"></div>
          //   );
          // }}
          tileDisabled={({ activeStartDate, date, view }) =>
            date.getDay() === 0 || date.getDay() === 6
          }
        />
        <dialog id="daily_modal_dash" className="modal">
          <div className="menu w-80 modal-box">
           <div className="text-[18px] font-medium mb-4">Cases For {dateSelected}</div> 
          {!!dateSelected && dailyCaseList[dateSelected].map((e, idx) => (
                  <>
                  <li key={idx} className="hover:bg-[#DEDEFA] duration-300 rounded-lg"
                    onClick={() => document.getElementById(`my_modal_dash_${idx}`).showModal()}
                  >
                    <a className="text-txt text-md">
                    <CaseCard patient={' ' + e.patient_first + ' ' + e.patient_last} 
                     doctor={` Dr. ${e.doctor.doctor_first} ${e.doctor.doctor_last}`}  
                     date={' ' + setDateAsUTC(e.return_date)}/>
                      </a>
                  </li>
                  <dialog id={`my_modal_dash_${idx}`} className="modal duration-0">
                    <CaseModal caseInfo={e} showInvoice={showInvoice} setShowInvoice={setShowInvoice} />
                    <form method="dialog" className="modal-backdrop">
                        <button>Close</button>                    
                    </form>
                  </dialog>
                </>
                ))}
          </div>
          <form method="dialog" className="modal-backdrop">
            <button>Close</button>                    
          </form>
        </dialog>
      </div>
      <div className="hidden bg-white flex-col px-4 pt-4 pb-2 min-h-[400px] h-[966px] w-[376px] rounded-lg drop-shadow-lg xl:flex">
        <div className="flex w-full pl-4 relative items-center justify-between">
          <div className="font-bold justify-start">
          Recent Cases
          </div>
          <Link to={'/new_case'} className="flex items-center justify-center h-[40px] px-4 py-2 items-center bg-[#5C59E8] hover:bg-[#3d3b9c] duration-300 rounded-md font-inter font-[700] text-[14px] text-[#FFF]">
            + New Case
          </Link>
        </div>

          {/* {!!caseData.cases && caseData.cases.map((e) => (
            <div className="border">
              <div>{e.doctor}</div>
              <div>{e.patient_first} {e.patient_last}</div>
              <div>{e.return_date}</div>
            </div>
          ))} */}
          <div className="menu w-80 bg-white divide-y divide-gray-300">
          {!!caseData.cases && caseData.cases.slice(0,10).map((e, idx) => (
                <>
                <li key={idx} className='hover:bg-[#DEDEFA] duration-300'
                  onClick={() => document.getElementById(`my_modal_dash_${idx}`).showModal()}
                >
                  <a className="text-txt text-md">
                    <CaseCard patient={e.patient_first + ' ' + e.patient_last} 
                     doctor={`Dr. ${e.doctor.doctor_first} ${e.doctor.doctor_last}`}  
                     date={setDateAsUTC(e.return_date)}/>
                    </a>
                </li>
                <dialog id={`my_modal_dash_${idx}`} className="modal duration-0">
                <CaseModal caseInfo={e} showInvoice={showInvoice} setShowInvoice={setShowInvoice} />
                <form method="dialog" className="modal-backdrop">
                    <button>Close</button>                    
                </form>
              </dialog>
              </>
              ))}
            </div>
      </div>  
      
      {/* <div className="flex">
        <Link to={'/new_case'} className="w-[100px] h-[38px] flex px-2 py-2 items-center bg-[#5C59E8] hover:bg-[#3d3b9c] duration-300 sticky right-0 rounded-lg font-inter font-[700] text-[14px] text-[#FFF]">
          + New Case
        </Link>
      </div> */}
    </div>
  );
}

export default DashboardCalendar;
