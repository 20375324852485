import React, { useState } from 'react'
import { Link, useParams } from "react-router-dom";
import axios from "axios";

function ResetPassword() {

  const [ password, setPassword ] = useState("")
  const [ passwordCheck, setPasswordCheck ] = useState("")
  const [passwordsMatch, setPasswordsMatch] = useState(true);

  const { token } = useParams();
  const [ successPassword , setSuccessPassword ] = useState(false);

  const onChange = (e) => {
    setPassword(e.target.value)
    setPasswordsMatch(e.target.value === passwordCheck);

  }

  const onChangeCheck = (e) => {
    setPasswordCheck(e.target.value)
    setPasswordsMatch(e.target.value === password);

  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password === passwordCheck) {
      // Perform password change logic
      console.log('Passwords matched, perform password change logic');
      try {
        let res = await axios.post(
          "/api/auth/reset_password/" + token,
          { password: password},
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log(res);
        if (res) {
          setSuccessPassword(true);
        }
      } catch (error) {
        console.log(error.response, "ERROR");
      }
    } else {
      // Display error message or prevent form submission
      console.log('Passwords do not match');
    }

  };

  return (
    <div className="flex fixed bg-cover bg h-full w-full bg-[#F9F9FC] items-center justify-center">
    <div className="flex flex-row flex-grow h-[93%]">
      <div name="left" className="flex w-1/2 items-center justify-center ">
        <div className="flex w-[90%] h-full rounded-[4px] bg-cover bg-loginsplash">
        </div>
      </div>
      <div className="relative flex overflow-hidden w-1/2 items-center justify-center">
        <div className="flex flex-row absolute left-0 top-0">
        <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17 12.75C14.6528 12.75 12.75 14.6528 12.75 17V29.75C12.75 32.0972 14.6528 34 17 34C19.3472 34 21.25 32.0972 21.25 29.75V21.25H29.75C32.0972 21.25 34 19.3472 34 17C34 14.6528 32.0972 12.75 29.75 12.75H17Z" fill="#7D7AED"/>
          <path d="M17 0C14.6528 0 12.75 1.90279 12.75 4.25V12.75H4.25C1.90279 12.75 0 14.6528 0 17C0 19.3472 1.90279 21.25 4.25 21.25H17C19.3472 21.25 21.25 19.3472 21.25 17V4.25C21.25 1.90279 19.3472 0 17 0Z" fill="#42C1E9"/>
          <path d="M21.25 12.75H17C14.6528 12.75 12.75 14.6528 12.75 17V21.25H17C19.3472 21.25 21.25 19.3472 21.25 17V12.75Z" fill="#5C59E8"/>
        </svg>
        <div className="font-inter font-[700] text-[24px] text-[#333843] pl-5">Dentopia</div>
        </div>
        <div className="flex flex-col items-center justify-center min-w-[550px] max-w-100 min-h-[650px] p-[15px] m-[15px] items-center justify-center">
         {successPassword ? <div className="grow w-full h-full items-center justify-center">
            <div className="flex flex-col gap-[8px]">
              <label className="text-[#4D5464] text-[16px] font-[500]">
                Password has been sucessfully reset. <Link to="/login" className='text-[#13B2E4]'>Click here for the login page</Link>
              </label> 
            </div>
            </div>
            : <form
            onSubmit={handleSubmit}
            className="min-w-[450px] min-h-[250px] items-center justify-center"
          > 
            <div className="grow w-full h-full items-center justify-center">
              <div className="flex flex-col gap-[8px]">
              <label className="text-[#4D5464] text-[14px] font-[500]">New Password</label>
                <input
                  type="password"
                  className="input input-bordered w-full bg-[#FFFFFF] w-full border-1 box-border pt-2 pb-2 pr-3 pl-3"
                  id="password"
                  name="password"
                  value={password}
                  onChange={onChange}
                  placeholder="Enter password"
                  required
                />
                <label className="text-[#4D5464] text-[14px] font-[500]">Confirm Password</label>
                <input
                  type="password"
                  className="input input-bordered w-full bg-[#FFFFFF] w-full border-1 box-border pt-2 pb-2 pr-3 pl-3"
                  id="passwordCheck"
                  name="passwordCheck"
                  value={passwordCheck}
                  onChange={onChangeCheck}
                  placeholder="Confirm password"
                  required
                />
               {!passwordsMatch && <label className="text-[11px] font-[500] text-red-500">Passwords do not match</label>}
                <button
                  className="btn btn-block
          non-italic normal bg-[#5C59E8] 
          border-transparent normal-case hover:bg-[#34AFF9] 
          hover:border-transparent rounded w-full h-[40px] m-t-[10px]"
                >
                  <div className="text-[#FFFFFF]">Reset Password</div>
                </button>
              </div>
            </div>
          </form>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResetPassword