import React, { useState, useContext } from "react";
import generatePDF from "../components/PdfCreator";
import DocIconSVG from "../svg/DocIconSVG";
import CaseContext from "../contexts/CaseContext";
import ChevronRightSVG from "../svg/ChevronRightSVG";
import {
  TEETH_INT,
  CROWNS_LIST,
  DENTURE_LIST,
  IMPLANT_LIST,
} from "../constants";
import ImageMapper from "react-img-mapper";
import { domToCanvas, domToDataUrl } from "modern-screenshot";

function Pdftest({ action, disab, data, type }) {
  const [teethSrc, setTeethSrc] = useState({});
  const [checkSrc, setCheckSrc] = useState({});
  const {
    caseCrownData,
    setCaseCrownData,
    setCaseList,
    setCrownTeethInt,
    crownsList,
    setCrownsList,
  } = useContext(CaseContext);

  const street = "9813 Godwin Drive"
  const city_state = "Manassas, VA 20110"
  const phone = "info@dentopiadentallab.com | 703-365-2635"
  const website = "www.dentopiadentallab.com"

  const setDateAsUTC = (x) => {
    let date = new Date(Date.parse(x));
    return date.toLocaleString([], {
      dateStyle: "short",
      timeStyle: undefined,
    });
  };

  const teethFill = (arr) => {
    let fill = arr.filter((e) => Number(e) !== 1).map((e) => ({ ...TEETH_INT[Number(e)] }));
    if (arr) {
      fill.forEach((e) => {
        try {
          e["preFillColor"] = "rgb(91, 89, 232, 0.8)";
          delete e["fillColor"];
        } catch (error) {
          console.log('error');
        }
      });
      return fill;
    }
  };

  const teethGenerator = async (i) => {
    // for (let x = 0; x <= i; x++) {
    //   const target = document.getElementById(`id${data.id}teethImg${x}`);
    //   // const target2 = document.getElementById(`id${data.id}crownChecks${x}`);
    //   await domToDataUrl(target, {
    //     quality: 1,
    //     scale: 2,
    //     onCloneNode: function (logo) {
    //       logo.style.display = "flex";
    //     },
    //   }).then(async (data1) => {
    //     // await domToDataUrl(target2, {
    //     //   quality: 1,
    //     //   onCloneNode: function (logo2) {},
    //     // })
    //     // .then((data2) => {
    //     //   setCheckSrc((prev) => ({
    //     //     ...prev,
    //     //     [x]: data2,
    //     //   }));
    //     // });
    //     setTeethSrc((prev) => ({
    //       ...prev,
    //       [x]: data1,
    //     }));
    //   });
    // }
    generatePDF(data.items.length, data.id);
  };

  // const typeSelector = (type) => {
  //   if (type.toLowerCase() === "crown") return CROWNS_LIST;
  //   if (type.toLowerCase() === "denture") return DENTURE_LIST;
  //   if (type.toLowerCase() === "implant") return IMPLANT_LIST;
  // };

  // const checkSelector = (type, caseItems, i) => {
  //   if (type.toLowerCase() === "crown") {
  //     return (
  //       <form
  //         id={type ? `id${data.id}crownChecks${i}` : `crownChecks`}
  //         className="w-[931.67px] h-[350px] columns-3 whitespace-nowrap relative"
  //       >
  //         {Object.keys(typeSelector(caseItems.type)).map((group) => (
  //           <fieldset className="border p-2 break-inside-avoid">
  //             <legend className="p-1 text-lg">{group}</legend>
  //             {Object.keys(typeSelector(caseItems.type)[group]).map(
  //               (select) => (
  //                 <div className="flex p-0.5 items-center">
  //                   <input
  //                     type="checkbox"
  //                     name={group}
  //                     id={select}
  //                     className="checkbox checkbox-primary"
  //                     checked={
  //                       Object.keys(caseItems).includes(group) &&
  //                       Object.values(caseItems).includes(select)
  //                     }
  //                     disabled={
  //                       !Object.keys(caseItems).includes(group) ||
  //                       !Object.values(caseItems).includes(select)
  //                     }
  //                   />
  //                   <label className="pl-1 text-txt text-md">{select}</label>
  //                 </div>
  //               )
  //             )}
  //           </fieldset>
  //         ))}
  //       </form>
  //     );
  //   }
  //   if (type.toLowerCase() === "denture") {
  //     return (
  //       <form
  //         id={type ? `id${data.id}crownChecks${i}` : `crownChecks`}
  //         className="w-[931.67px] h-[750px] columns-3 whitespace-nowrap"
  //       >
  //         {Object.keys(typeSelector(caseItems.type)).map((group) => (
  //           <fieldset className="border p-2 break-inside-avoid">
  //             <legend className="p-1 text-lg">{group}</legend>
  //             {Object.keys(typeSelector(caseItems.type)[group]).map(
  //               (select) => (
  //                 <div className="flex p-0.5 items-center">
  //                   <input
  //                     type="checkbox"
  //                     name={group}
  //                     id={select}
  //                     className="checkbox checkbox-primary"
  //                     checked={
  //                       Object.keys(caseItems).includes(group) &&
  //                       caseItems[group].includes(select)
  //                     }
  //                   />
  //                   <label className="pl-1 text-txt text-md">{select}</label>
  //                 </div>
  //               )
  //             )}
  //           </fieldset>
  //         ))}
  //       </form>
  //     );
  //   }
  //   if (type.toLowerCase() === "implant") {
  //     return (
  //       <div
  //         className="flex flex-col w-[931.67px] h-[300px] whitespace-nowrap"
  //         id={type ? `id${data.id}crownChecks${i}` : `crownChecks`}
  //       >
  //         <label className="text-txt text-lg mb-2">Implant Crown</label>
  //         <div className="flex flex-row justify-start">
  //           <select
  //             id="Crown Category 1"
  //             className="select select-bordered border-gray-300 w-3/5 max-w-xs bg-defbg"
  //           >
  //             <option value="" selected>
  //               {caseItems['Crown Category 1']}
  //             </option>
  //           </select>
  //           {1 && (
  //             <>
  //               <ChevronRightSVG />
  //               <select
  //                 id="Crown Category 2"
  //                 className="select select-bordered border-gray-300 w-3/5 max-w-xs bg-defbg"
  //               >
  //                 <option value="asdfasdfasdf" selected>
  //                 {caseItems['Crown Category 2'] || caseItems['third-party']}
  //                 </option>
  //               </select>
  //             </>
  //           )}
  //         </div>

  //         <label className="text-txt text-lg mb-2 mt-4">Implant Abutment</label>
  //         <div className="flex flex-row justify-start">
  //           <select
  //             id="Abutment Category 1"
  //             value={"test"}
  //             className="select select-bordered border-gray-300 w-1/4 max-w-xs bg-defbg"
  //           >
  //             <option value="" selected>
  //               {caseItems["Abutment Category 1"]}
  //             </option>
  //           </select>
  //           {1 && (
  //             <>
  //               <ChevronRightSVG />
  //               <select
  //                 id="Abutment Category 2"
  //                 value={"subCategoryAbutment"}
  //                 className="select select-bordered border-gray-300 w-1/4 max-w-xs bg-defbg"
  //               >
  //                 <option value="" selected>
  //                   {caseItems["Abutment Category 2"]}
  //                 </option>
  //               </select>
  //             </>
  //           )}
  //           {true && (
  //             <>
  //               <ChevronRightSVG />
  //               <select
  //                 id="Abutment Category 3"
  //                 className="select select-bordered border-gray-300 w-1/4 max-w-xs bg-defbg"
  //               >
  //                 <option value="" selected>
  //                   {caseItems["Abutment Category 3"]}
  //                 </option>
  //               </select>
  //             </>
  //           )}
  //         </div>
  //       </div>
  //     );
  //   }
  // };

  const multiItem = (data) => {
    if (!!data?.items.length) {
      return data.items.map((caseItems, i) => (
        <div className="w-0 h-0 overflow-hidden text-[#333843] leading-normal">
          <div
            id={`id${data.id}pdf${i}`}
            style={{
              "font-size": "textnode-font-size",
              "line-height": "textnode-line-height",
            }}
            className="flex flex-row relative h-[794px] w-[1122px] bg-white box-border font-[#000000] font-400 "
          >
            {/* <div id="pdf"  className="flex px-2 py-4 flex-row items-center invisible h-0 w-0"> */}
            <div
              id="outer"
              className="flex flex-col border-2 border-[#333843] rounded-sm m-2 w-full"
            >
              <div
                id="inner"
                className="flex items-center flex-row m-2 h-[120px] border rounded-lg"
              >
                <div className="flex flex-col w-[30%]">
                  <div className="flex box-border p-3 flex-row h-[100px] items-center">
                    <div className="flex pl-4 w-[68px] h-[68px] my-auto">
                      <svg
                        viewBox="0 0 34 34"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M17 12.75C14.6528 12.75 12.75 14.6528 12.75 17V29.75C12.75 32.0972 14.6528 34 17 34C19.3472 34 21.25 32.0972 21.25 29.75V21.25H29.75C32.0972 21.25 34 19.3472 34 17C34 14.6528 32.0972 12.75 29.75 12.75H17Z"
                          fill="#7D7AED"
                        />
                        <path
                          d="M17 0C14.6528 0 12.75 1.90279 12.75 4.25V12.75H4.25C1.90279 12.75 0 14.6528 0 17C0 19.3472 1.90279 21.25 4.25 21.25H17C19.3472 21.25 21.25 19.3472 21.25 17V4.25C21.25 1.90279 19.3472 0 17 0Z"
                          fill="#42C1E9"
                        />
                        <path
                          d="M21.25 12.75H17C14.6528 12.75 12.75 14.6528 12.75 17V21.25H17C19.3472 21.25 21.25 19.3472 21.25 17V12.75Z"
                          fill="#5C59E8"
                        />
                      </svg>
                    </div>
                    <div className="flex flex-col justify-center font-inter pl-4 h-[40px] gap-2">
                      <div className="flex items-center h-[20px] font-[700] text-[34px] text-[#333843]">
                        Dentopia
                      </div>
                      <div className="flex font-[500] h-[20px] text-[17px] text-[#667085]">
                        Dental Laboratory
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-col w-[40%] text-[20px]h-min">
                  <div className="relative flex flex-row justify-center">
                    <div className="m-2 whitespace-nowrap tracking-[0.01px]">
                      Doctor :
                    </div>
                    <div className="flex font-[700] my-2 capitaliz underline">
                      {!!data.doctor
                        ? `${data.doctor.doctor_last}, ${data.doctor.doctor_first}`
                        : ""}
                    </div>
                  </div>
                  <div className="flex flex-row justify-center">
                    <div className="m-2 whitespace-nowrap">Patient :</div>
                    <div className="flex font-[700] my-2 capitalize underline">
                      {`${data.patient_last}, ${data.patient_first}`}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col text-[12px] w-[35%] my-auto">
                  <div className="flex flex-row">
                    <div className="font-[700] mx-1 whitespace-nowrap">{street}</div>
                  </div>
                  <div className="flex flex-row">
                    <div className="font-[700] mx-1 whitespace-nowrap">{city_state}</div>
                  </div>
                  <div className="flex flex-row">
                    <div className="font-[700] mx-1 whitespace-nowrap">
                      {phone}
                    </div>
                  </div>
                  <div className="flex flex-row">
                    <div className="font-[700] mx-1 whitespace-nowrap">
                      {website}
                    </div>
                  </div>
                </div>
              </div>
              {/* <button onClick={() => teethGenerator(caseItems.teethArr)}>TEST</button> */}
              <div id="inner2" className="flex flex-row my-2 mx-8 gap-6">
                <div className="flex flex-col text-[14px] w-[50%] h-full pb-3 border-r">
                  <div className="flex flex-row">
                    <div className="m-2 whitespace-nowrap">Return Date :</div>
                    <div className="font-[700] m-2 w-full whitespace-nowrap">
                      {setDateAsUTC(data.return_date)}
                    </div>
                  </div>
                  <div className="flex flex-row">
                    <div className="mx-2 my-1 whitespace-nowrap">Type :</div>
                    <div className="font-[700] m-1 w-full capitalize whitespace-nowrap">
                      {caseItems.type}
                    </div>
                  </div>
                  {!!caseItems &&
                    Object.keys(caseItems)
                      .filter(
                        (a) =>
                          [
                            ...Object.keys(CROWNS_LIST),
                            ...Object.keys(DENTURE_LIST),
                            ...Object.keys(IMPLANT_LIST),
                            ...Object.keys(IMPLANT_LIST["Implant Abutment"]),
                          ].includes(a) || a.includes("Category")
                      )
                      .map((e) => (
                        <div className="flex flex-row">
                          <div className="mx-2 my-1 whitespace-nowrap capitalize">
                            {e === "is_custom_shade"
                              ? "Custom Shade :"
                              : `${e} :`}
                          </div>
                          <div className="font-[700] m-1 w-full text-pretty capitalize">
                            {Array.isArray(caseItems[e])
                              ? caseItems[e].join(", ")
                              : caseItems[e]}
                          </div>
                        </div>
                      ))}
                  {/* {
                                       !!data.items[1] && 
                                       Object.keys(data.items[1]).filter((i) => i.includes('Category')).map((e) => (
                                           <div>
                                       <div className="capitalize">
                                           {e === 'is_custom_shade' ? 'Custom Shade': e === 'teethArr' ? 'Teeth Selected' : e}
                                       </div>
                                       <div>
                                           {data.items[1][e]}
                                       </div>
                                   </div>
                                       ))
                                   } */}
                  <div className="flex flex-row">
                    <div className="mx-2 my-1 whitespace-nowrap">Teeth # :</div>
                    <div className="font-[700] m-1 text-pretty capitalize">
                      {caseItems.teethArr.map((e) => e.toString()).join(", ")}
                    </div>
                  </div>
                  {!!caseItems.teeth_shade && (
                    <div className="flex flex-row">
                      <div className="mx-2 my-1 whitespace-nowrap">
                        Teeth Shade :
                      </div>
                      <div className="font-[700] m-1 text-pretty capitalize">
                        {caseItems.teeth_shade}
                      </div>
                    </div>
                  )}
                  {!!caseItems.arch && (
                    <div className="flex flex-row">
                      <div className="mx-2 my-1 whitespace-nowrap">Arch :</div>
                      <div className="font-[700] m-1 text-pretty capitalize">
                        {caseItems.arch}
                      </div>
                    </div>
                  )}
                  {!!caseItems["provided_by_doctor"] && (
                    <div className="flex flex-row">
                      <div className="mx-2 my-1 whitespace-nowrap">
                        Provided by Doctor :
                      </div>
                      <div className="font-[700] m-1 text-pretty capitalize">
                        {caseItems.provided_by_doctor.toString()}
                      </div>
                    </div>
                  )}
                  {!!caseItems.incisal &&
                    !!caseItems.body &&
                    !!caseItems.cervical && (
                      <>
                        <div className="flex flex-row">
                          <div className="mx-2 my-1 whitespace-nowrap">
                            Incisal :
                          </div>
                          <div className="font-[700] m-1 text-pretty capitalize">
                            {caseItems.incisal}
                          </div>
                        </div>
                        <div className="flex flex-row">
                          <div className="mx-2 my-1 whitespace-nowrap">
                            Body :
                          </div>
                          <div className="font-[700] m-1 text-pretty capitalize">
                            {caseItems.body}
                          </div>
                        </div>
                        <div className="flex flex-row">
                          <div className="mx-2 my-1 whitespace-nowrap">
                            Cervical :
                          </div>
                          <div className="font-[700] m-1 text-pretty capitalize">
                            {caseItems.cervical}
                          </div>
                        </div>
                      </>
                    )}
                </div>
                <div className="flex flex-col text-[16px] my-2 mx-1 w-[50%] ">
                  <div className="font-[700] whitespace-nowrap break-words">
                    Notes :
                  </div>
                  <div className="flex text-pretty indent-4 break-words">
                    {caseItems.notes}
                  </div>
                </div>
                {type ? (
                  <div
                  id={type ? `id${data.id}teethImg${i}` : "teethImg"}
                  className="w-[869px] h-[353px] border rounded-lg absolute left-0 m-auto right-0 -bottom-10 scale-[.6]"
                  style={{
                    flexDirection: "row",
                    alignItems: "center",
                    width: "max-content",
                  }}
                >
                  <ImageMapper
                    src="/UNS.jpg"
                    map={{
                      name: "my-map",
                      areas: teethFill(caseItems.teethArr),
                    }}
                    width={869}
                  />
                  {!!caseItems.arch && (
                    <>
                      <button
                        className={`btn btn-lg absolute z-50 top-[190px] left-[118px] text-[42px] 
                      ${
                        caseItems.arch.toLowerCase() == "upper"
                          ? `bg-[#5b59e8cc] border-none hover:bg-[#5b59e8cc] text-white`
                          : `hover:bg-[#2d415fcc] hover:text-white`
                      } `}
                      >
                        Upper
                      </button>
                      <button
                        className={`btn btn-lg absolute z-50 top-[190px] right-[145px] text-[42px] 
                      ${
                        caseItems.arch.toLowerCase() == "lower"
                          ? `bg-[#5b59e8cc] border-none hover:bg-[#5b59e8cc] text-white`
                          : `hover:bg-[#2d415fcc] hover:text-white`
                      } `}
                      >
                        Lower
                      </button>
                    </>
                  )}
                </div>
                ) : (
                  <div
                    id={type ? `id${data.id}teethImg${i}` : "teethImg"}
                    className="w-[869px] h-[353px] border rounded-lg"
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      width: "max-content",
                    }}
                  >
                    <ImageMapper
                      src="/UNS.jpg"
                      map={{
                        name: "my-map",
                        areas: teethFill(caseItems.teethArr),
                      }}
                      width={869}
                    />
                    {!!caseItems.arch && (
                      <>
                        <button
                          className={`btn btn-lg absolute z-50 top-[190px] left-[118px] text-[42px] 
                        ${
                          caseItems.arch.toLowerCase() == "upper"
                            ? `bg-[#5b59e8cc] border-none hover:bg-[#5b59e8cc] text-white`
                            : `hover:bg-[#2d415fcc] hover:text-white`
                        } `}
                        >
                          Upper
                        </button>
                        <button
                          className={`btn btn-lg absolute z-50 top-[190px] right-[395px] text-[44px] 
                        ${
                          caseItems.arch.toLowerCase() == "lower"
                            ? `bg-[#5b59e8cc] border-none hover:bg-[#5b59e8cc] text-white`
                            : `hover:bg-[#2d415fcc] hover:text-white`
                        } `}
                        >
                          Lower
                        </button>
                      </>
                    )}
                  </div>
                )}
                {/* {!type ? (
                  <img
                    className="absolute bottom-7 right-10 w-[480px] h-auto "
                    src={caseItems.check}
                  />
                ) : (
                  <img
                    className="absolute bottom-[50px] right-10 w-[500px] h-auto"
                    src={checkSrc[i]}
                  />
                )} */}
                {/* <div className="h-0 w-0 -z-50 absolute">
                  <div
                    id={type ? `id${data.id}teethImg${i}` : "teethImg"}
                    className="w-[869px] h-[353px] border rounded-lg"
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      width: "max-content",
                    }}
                  >
                    <ImageMapper
                      src="./UNS.jpg"
                      map={{
                        name: "my-map",
                        areas: teethFill(caseItems.teethArr),
                      }}
                      width={869}
                    />
                    {!!caseItems.arch && (
                      <>
                        <button
                          className={`btn btn-lg absolute z-50 top-[190px] left-[118px] text-[42px] 
                        ${
                          caseItems.arch.toLowerCase() == "upper"
                            ? `bg-[#5b59e8cc] border-none hover:bg-[#5b59e8cc] text-white`
                            : `hover:bg-[#2d415fcc] hover:text-white`
                        } `}
                        >
                          Upper
                        </button>
                        <button
                          className={`btn btn-lg absolute z-50 top-[190px] right-[395px] text-[44px] 
                        ${
                          caseItems.arch.toLowerCase() == "lower"
                            ? `bg-[#5b59e8cc] border-none hover:bg-[#5b59e8cc] text-white`
                            : `hover:bg-[#2d415fcc] hover:text-white`
                        } `}
                        >
                          Lower
                        </button>
                      </>
                    )}
                  </div>
                </div> */}
                {/* <div className="flex bg-white flex-col gap-6 mt-3 h-0 w-0 z-50 absolute">
                <div className="flex bg-white flex-col gap-6 mt-3 absolute">
                  {!!caseItems.type &&
                    checkSelector(caseItems.type, caseItems, i)}
                </div> */}
              </div>
            </div>
          </div>
        </div>
      ));
    }
  };

  return (
    <div className="flex flex-col gap-6">
      {type === 2 ? (
        <button
          className="btn btn-primary text-white"
          onClick={(e) => {
            generatePDF(data.items.length, data.id).then(() => {
              action(e)
            })
          }
          }
          disabled={disab}
        >
          Confirm
        </button>
      ) : (
        <button
          className="cursor-pointer edit-div tooltip tooltip-bottom"
          data-tip="Generate PDF"
          onClick={() => teethGenerator(data.items.length - 1)}
        >
          <DocIconSVG />
        </button>
      )}
      {multiItem(data)}
    </div>
  );
}

export default Pdftest;
