export const TEETH_INT = {
  "2c": {
    name: "2",
    shape: "circle",
    coords: [31, 243, 15],
    fillColor: "rgb(118, 161, 170, 0.0)",
  },
  "3c": {
    name: "3",
    shape: "circle",
    coords: [40, 193, 15],
    fillColor: "rgb(118, 161, 170, 0.0)",
  },
  "4c": {
    name: "4",
    shape: "circle",
    coords: [51, 153, 15],
    fillColor: "rgb(118, 161, 170, 0.0)",
  },
  "5c": {
    name: "5",
    shape: "circle",
    coords: [63, 119, 15],
    fillColor: "rgb(118, 161, 170, 0.0)",
  },
  "6c": {
    name: "6",
    shape: "circle",
    coords: [88, 77, 15],
    fillColor: "rgb(118, 161, 170, 0.0)",
  },
  "7c": {
    name: "7",
    shape: "circle",
    coords: [126, 48, 15],
    fillColor: "rgb(118, 161, 170, 0.0)",
  },
  "8c": {
    name: "8",
    shape: "circle",
    coords: [179, 35, 15],
    fillColor: "rgb(118, 161, 170, 0.0)",
  },
  "9c": {
    name: "9",
    shape: "circle",
    coords: [228, 34, 15],
    fillColor: "rgb(118, 161, 170, 0.0)",
  },
  "10c": {
    name: "10",
    shape: "circle",
    coords: [280, 43, 16],
    fillColor: "rgb(118, 161, 170, 0.0)",
  },
  "11c": {
    name: "11",
    shape: "circle",
    coords: [319, 69, 16],
    fillColor: "rgb(118, 161, 170, 0.0)",
  },
  "12c": {
    name: "12",
    shape: "circle",
    coords: [349, 110, 16],
    fillColor: "rgb(118, 161, 170, 0.0)",
  },
  "13c": {
    name: "13",
    shape: "circle",
    coords: [365, 148, 16],
    fillColor: "rgb(118, 161, 170, 0.0)",
  },
  "14c": {
    name: "14",
    shape: "circle",
    coords: [375, 193, 16],
    fillColor: "rgb(118, 161, 170, 0.0)",
  },
  "15c": {
    name: "15",
    shape: "circle",
    coords: [384, 241, 16],
    fillColor: "rgb(118, 161, 170, 0.0)",
  },
  "18c": {
    name: "18",
    shape: "circle",
    coords: [453, 238, 17],
    fillColor: "rgb(118, 161, 170, 0.0)",
  },
  "19c": {
    name: "19",
    shape: "circle",
    coords: [461, 179, 17],
    fillColor: "rgb(118, 161, 170, 0.0)",
  },
  "20c": {
    name: "20",
    shape: "circle",
    coords: [474, 138, 17],
    fillColor: "rgb(118, 161, 170, 0.0)",
  },
  "21c": {
    name: "21",
    shape: "circle",
    coords: [507, 98, 18],
    fillColor: "rgb(118, 161, 170, 0.0)",
  },
  "22c": {
    name: "22",
    shape: "circle",
    coords: [540, 72, 18],
    fillColor: "rgb(118, 161, 170, 0.0)",
  },
  "23c": {
    name: "23",
    shape: "circle",
    coords: [580, 56, 18],
    fillColor: "rgb(118, 161, 170, 0.0)",
  },
  "24c": {
    name: "24",
    shape: "circle",
    coords: [615, 48, 18],
    fillColor: "rgb(118, 161, 170, 0.0)",
  },
  "25c": {
    name: "25",
    shape: "circle",
    coords: [654, 45, 18],
    fillColor: "rgb(118, 161, 170, 0.0)",
  },
  "26c": {
    name: "26",
    shape: "circle",
    coords: [692, 50, 18],
    fillColor: "rgb(118, 161, 170, 0.0)",
  },
  "27c": {
    name: "27",
    shape: "circle",
    coords: [729, 66, 19],
    fillColor: "rgb(118, 161, 170, 0.0)",
  },
  "28c": {
    name: "28",
    shape: "circle",
    coords: [765, 99, 19],
    fillColor: "rgb(118, 161, 170, 0.0)",
  },
  "29c": {
    name: "29",
    shape: "circle",
    coords: [781, 134, 19],
    fillColor: "rgb(118, 161, 170, 0.0)",
  },
  "30c": {
    name: "30",
    shape: "circle",
    coords: [806, 183, 18],
    fillColor: "rgb(118, 161, 170, 0.0)",
  },
  "31c": {
    name: "31",
    shape: "circle",
    coords: [814, 246, 18],
    fillColor: "rgb(118, 161, 170, 0.0)",
  },
  2: {
    name: "2",
    shape: "poly",
    coords: [
      53, 231, 63, 225, 75, 227, 85, 231, 93, 237, 99, 247, 99, 256, 93, 268,
      79, 272, 65, 270, 55, 262, 49, 247,
    ],
    fillColor: "rgb(45, 65, 95, 0.8)",
  },
  3: {
    name: "3",
    shape: "poly",
    coords: [
      63, 189, 75, 181, 91, 183, 100, 193, 106, 201, 106, 211, 100, 221, 91,
      229, 77, 225, 65, 219, 59, 205,
    ],
    fillColor: "rgb(45, 65, 95, 0.8)",
  },
  4: {
    name: "4",
    shape: "poly",
    coords: [
      79, 156, 89, 150, 99, 152, 112, 162, 114, 174, 106, 181, 95, 181, 83, 177,
      75, 168, 77, 164,
    ],
    fillColor: "rgb(45, 65, 95, 0.8)",
  },
  5: {
    name: "5",
    shape: "poly",
    coords: [
      93, 124, 102, 118, 112, 124, 126, 128, 128, 140, 122, 148, 104, 152, 93,
      146, 89, 134,
    ],
    fillColor: "rgb(45, 65, 95, 0.8)",
  },
  6: {
    name: "6",
    shape: "poly",
    coords: [
      108, 97, 116, 89, 128, 91, 134, 101, 138, 114, 130, 124, 116, 122, 104,
      112,
    ],
    fillColor: "rgb(45, 65, 95, 0.8)",
  },
  7: {
    name: "7",
    shape: "poly",
    coords: [
      132, 82, 140, 79, 150, 74, 158, 76, 165, 86, 160, 97, 152, 104, 139, 102,
      131, 92,
    ],
    fillColor: "rgb(45, 65, 95, 0.8)",
  },
  8: {
    name: "8",
    shape: "poly",
    coords: [
      165, 64, 178, 60, 189, 60, 203, 65, 198, 77, 191, 87, 183, 95, 172, 92,
      163, 77,
    ],
    fillColor: "rgb(45, 65, 95, 0.8)",
  },
  9: {
    name: "9",
    shape: "poly",
    coords: [
      207, 64, 219, 61, 230, 63, 242, 64, 245, 72, 235, 94, 222, 95, 209, 84,
      204, 69,
    ],
    fillColor: "rgb(45, 65, 95, 0.8)",
  },
  10: {
    name: "10",
    shape: "poly",
    coords: [
      253, 72, 268, 78, 274, 84, 269, 103, 255, 104, 244, 100, 241, 92, 244, 77,
    ],
    fillColor: "rgb(45, 65, 95, 0.8)",
  },
  11: {
    name: "11",
    shape: "poly",
    coords: [
      285, 92, 295, 93, 300, 99, 302, 107, 300, 115, 290, 122, 283, 127, 271,
      122, 268, 106, 273, 94,
    ],
    fillColor: "rgb(45, 65, 95, 0.8)",
  },
  12: {
    name: "12",
    shape: "poly",
    coords: [
      304, 120, 312, 123, 317, 128, 317, 136, 315, 143, 311, 149, 304, 150, 290,
      150, 279, 147, 277, 137, 282, 128, 291, 122,
    ],
    fillColor: "rgb(45, 65, 95, 0.8)",
  },
  13: {
    name: "13",
    shape: "poly",
    coords: [
      322, 153, 328, 157, 331, 161, 331, 167, 329, 173, 321, 179, 311, 182, 302,
      183, 294, 176, 293, 163, 297, 157, 305, 153, 319, 152,
    ],
    fillColor: "rgb(45, 65, 95, 0.8)",
  },
  14: {
    name: "14",
    shape: "poly",
    coords: [
      297, 205, 303, 194, 308, 190, 313, 185, 319, 182, 325, 179, 331, 179, 341,
      184, 344, 190, 345, 196, 347, 204, 343, 214, 334, 223, 329, 227, 321, 231,
      315, 230, 306, 223, 298, 210,
    ],
    fillColor: "rgb(45, 65, 95, 0.8)",
  },
  15: {
    name: "15",
    shape: "poly",
    coords: [
      309, 242, 315, 236, 320, 232, 325, 228, 335, 226, 342, 226, 349, 226, 352,
      230, 356, 234, 356, 246, 354, 252, 353, 259, 349, 264, 344, 270, 332, 272,
      323, 272, 317, 270, 306, 259, 304, 251,
    ],
    fillColor: "rgb(45, 65, 95, 0.8)",
  },
  18: {
    name: "18",
    shape: "poly",
    coords: [
      484, 230, 491, 225, 498, 219, 509, 217, 517, 220, 523, 220, 525, 228, 527,
      235, 527, 243, 527, 252, 526, 260, 521, 264, 510, 267, 500, 267, 493, 264,
      486, 258, 483, 248, 482, 242,
    ],
    fillColor: "rgb(45, 65, 95, 0.8)",
  },
  19: {
    name: "19",
    shape: "poly",
    coords: [
      495, 176, 501, 171, 509, 166, 518, 167, 525, 168, 531, 172, 536, 176, 540,
      180, 541, 187, 539, 193, 537, 200, 538, 207, 533, 212, 529, 216, 521, 218,
      514, 217, 506, 215, 498, 210, 493, 202, 490, 192, 491, 184,
    ],
    fillColor: "rgb(45, 65, 95, 0.8)",
  },
  20: {
    name: "20",
    shape: "poly",
    coords: [
      522, 139, 529, 138, 536, 138, 543, 141, 548, 145, 549, 153, 550, 159, 546,
      164, 539, 169, 532, 170, 523, 169, 517, 162, 514, 153, 514, 146,
    ],
    fillColor: "rgb(45, 65, 95, 0.8)",
  },
  21: {
    name: "21",
    shape: "poly",
    coords: [
      534, 115, 539, 110, 546, 109, 553, 108, 559, 111, 563, 115, 567, 121, 567,
      130, 563, 141, 554, 143, 547, 141, 538, 136, 534, 130, 533, 122,
    ],
    fillColor: "rgb(45, 65, 95, 0.8)",
  },
  22: {
    name: "22",
    shape: "poly",
    coords: [
      561, 88, 566, 87, 575, 87, 582, 91, 587, 97, 587, 103, 586, 109, 579, 117,
      571, 118, 562, 114, 558, 106, 557, 96,
    ],
    fillColor: "rgb(45, 65, 95, 0.8)",
  },
  23: {
    name: "23",
    shape: "poly",
    coords: [
      586, 81, 589, 77, 597, 75, 605, 77, 610, 83, 607, 94, 603, 104, 597, 105,
      592, 101, 586, 94, 583, 87,
    ],
    fillColor: "rgb(45, 65, 95, 0.8)",
  },
  24: {
    name: "24",
    shape: "poly",
    coords: [
      611, 72, 617, 71, 623, 71, 630, 71, 633, 74, 635, 82, 631, 89, 625, 96,
      617, 97, 614, 89, 607, 81, 609, 75,
    ],
    fillColor: "rgb(45, 65, 95, 0.8)",
  },
  25: {
    name: "25",
    shape: "poly",
    coords: [
      642, 73, 650, 71, 657, 70, 662, 72, 663, 78, 663, 85, 659, 90, 658, 95,
      652, 97, 646, 95, 640, 87, 637, 80, 641, 73,
    ],
    fillColor: "rgb(45, 65, 95, 0.8)",
  },
  26: {
    name: "26",
    shape: "poly",
    coords: [
      671, 76, 679, 75, 685, 77, 690, 85, 688, 90, 686, 96, 681, 103, 676, 106,
      669, 104, 665, 88, 665, 81, 667, 77,
    ],
    fillColor: "rgb(45, 65, 95, 0.8)",
  },
  27: {
    name: "27",
    shape: "poly",
    coords: [
      697, 85, 706, 85, 712, 89, 715, 95, 715, 107, 711, 115, 702, 117, 691,
      116, 687, 105, 686, 98, 689, 92,
    ],
    fillColor: "rgb(45, 65, 95, 0.8)",
  },
  28: {
    name: "28",
    shape: "poly",
    coords: [
      718, 109, 725, 107, 732, 109, 735, 113, 738, 121, 738, 127, 737, 134, 731,
      141, 725, 143, 715, 142, 706, 135, 705, 122, 709, 113,
    ],
    fillColor: "rgb(45, 65, 95, 0.8)",
  },
  29: {
    name: "29",
    shape: "poly",
    coords: [
      740, 137, 746, 137, 753, 140, 758, 145, 758, 152, 758, 161, 749, 169, 739,
      171, 730, 168, 724, 160, 724, 149, 725, 142, 733, 137,
    ],
    fillColor: "rgb(45, 65, 95, 0.8)",
  },
  30: {
    name: "30",
    shape: "poly",
    coords: [
      733, 179, 741, 172, 748, 168, 754, 165, 761, 166, 767, 167, 773, 171, 779,
      178, 782, 191, 778, 201, 775, 210, 769, 215, 758, 220, 749, 218, 740, 214,
      736, 206, 735, 198, 730, 189,
    ],
    fillColor: "rgb(45, 65, 95, 0.8)",
  },
  31: {
    name: "31",
    shape: "poly",
    coords: [
      751, 221, 757, 220, 764, 217, 771, 217, 776, 220, 782, 224, 787, 229, 789,
      236, 789, 245, 787, 256, 783, 262, 773, 268, 762, 268, 753, 265, 749, 259,
      746, 246, 745, 236, 746, 228,
    ],
    fillColor: "rgb(45, 65, 95, 0.8)",
  },
};

export const TEETH_INT_DENTURE = {
  "2c": {
    name: "2",
    shape: "circle",
    coords: [31, 243, 15],
    fillColor: "rgb(118, 161, 170, 0.0)",
  },
  "3c": {
    name: "3",
    shape: "circle",
    coords: [40, 193, 15],
    fillColor: "rgb(118, 161, 170, 0.0)",
  },
  "4c": {
    name: "4",
    shape: "circle",
    coords: [51, 153, 15],
    fillColor: "rgb(118, 161, 170, 0.0)",
  },
  "5c": {
    name: "5",
    shape: "circle",
    coords: [63, 119, 15],
    fillColor: "rgb(118, 161, 170, 0.0)",
  },
  "6c": {
    name: "6",
    shape: "circle",
    coords: [88, 77, 15],
    fillColor: "rgb(118, 161, 170, 0.0)",
  },
  "7c": {
    name: "7",
    shape: "circle",
    coords: [126, 48, 15],
    fillColor: "rgb(118, 161, 170, 0.0)",
  },
  "8c": {
    name: "8",
    shape: "circle",
    coords: [179, 35, 15],
    fillColor: "rgb(118, 161, 170, 0.0)",
  },
  "9c": {
    name: "9",
    shape: "circle",
    coords: [228, 34, 15],
    fillColor: "rgb(118, 161, 170, 0.0)",
  },
  "10c": {
    name: "10",
    shape: "circle",
    coords: [280, 43, 16],
    fillColor: "rgb(118, 161, 170, 0.0)",
  },
  "11c": {
    name: "11",
    shape: "circle",
    coords: [319, 69, 16],
    fillColor: "rgb(118, 161, 170, 0.0)",
  },
  "12c": {
    name: "12",
    shape: "circle",
    coords: [349, 110, 16],
    fillColor: "rgb(118, 161, 170, 0.0)",
  },
  "13c": {
    name: "13",
    shape: "circle",
    coords: [365, 148, 16],
    fillColor: "rgb(118, 161, 170, 0.0)",
  },
  "14c": {
    name: "14",
    shape: "circle",
    coords: [375, 193, 16],
    fillColor: "rgb(118, 161, 170, 0.0)",
  },
  "15c": {
    name: "15",
    shape: "circle",
    coords: [384, 241, 16],
    fillColor: "rgb(118, 161, 170, 0.0)",
  },
  "18c": {
    name: "18",
    shape: "circle",
    coords: [453, 238, 17],
    fillColor: "rgb(118, 161, 170, 0.0)",
  },
  "19c": {
    name: "19",
    shape: "circle",
    coords: [461, 179, 17],
    fillColor: "rgb(118, 161, 170, 0.0)",
  },
  "20c": {
    name: "20",
    shape: "circle",
    coords: [474, 138, 17],
    fillColor: "rgb(118, 161, 170, 0.0)",
  },
  "21c": {
    name: "21",
    shape: "circle",
    coords: [507, 98, 18],
    fillColor: "rgb(118, 161, 170, 0.0)",
  },
  "22c": {
    name: "22",
    shape: "circle",
    coords: [540, 72, 18],
    fillColor: "rgb(118, 161, 170, 0.0)",
  },
  "23c": {
    name: "23",
    shape: "circle",
    coords: [580, 56, 18],
    fillColor: "rgb(118, 161, 170, 0.0)",
  },
  "24c": {
    name: "24",
    shape: "circle",
    coords: [615, 48, 18],
    fillColor: "rgb(118, 161, 170, 0.0)",
  },
  "25c": {
    name: "25",
    shape: "circle",
    coords: [654, 45, 18],
    fillColor: "rgb(118, 161, 170, 0.0)",
  },
  "26c": {
    name: "26",
    shape: "circle",
    coords: [692, 50, 18],
    fillColor: "rgb(118, 161, 170, 0.0)",
  },
  "27c": {
    name: "27",
    shape: "circle",
    coords: [729, 66, 19],
    fillColor: "rgb(118, 161, 170, 0.0)",
  },
  "28c": {
    name: "28",
    shape: "circle",
    coords: [765, 99, 19],
    fillColor: "rgb(118, 161, 170, 0.0)",
  },
  "29c": {
    name: "29",
    shape: "circle",
    coords: [781, 134, 19],
    fillColor: "rgb(118, 161, 170, 0.0)",
  },
  "30c": {
    name: "30",
    shape: "circle",
    coords: [806, 183, 18],
    fillColor: "rgb(118, 161, 170, 0.0)",
  },
  "31c": {
    name: "31",
    shape: "circle",
    coords: [814, 246, 18],
    fillColor: "rgb(118, 161, 170, 0.0)",
  },
  2: {
    name: "2",
    shape: "poly",
    coords: [
      53, 231, 63, 225, 75, 227, 85, 231, 93, 237, 99, 247, 99, 256, 93, 268,
      79, 272, 65, 270, 55, 262, 49, 247,
    ],
    fillColor: "rgb(45, 65, 95, 0.8)",
  },
  3: {
    name: "3",
    shape: "poly",
    coords: [
      63, 189, 75, 181, 91, 183, 100, 193, 106, 201, 106, 211, 100, 221, 91,
      229, 77, 225, 65, 219, 59, 205,
    ],
    fillColor: "rgb(45, 65, 95, 0.8)",
  },
  4: {
    name: "4",
    shape: "poly",
    coords: [
      79, 156, 89, 150, 99, 152, 112, 162, 114, 174, 106, 181, 95, 181, 83, 177,
      75, 168, 77, 164,
    ],
    fillColor: "rgb(45, 65, 95, 0.8)",
  },
  5: {
    name: "5",
    shape: "poly",
    coords: [
      93, 124, 102, 118, 112, 124, 126, 128, 128, 140, 122, 148, 104, 152, 93,
      146, 89, 134,
    ],
    fillColor: "rgb(45, 65, 95, 0.8)",
  },
  6: {
    name: "6",
    shape: "poly",
    coords: [
      108, 97, 116, 89, 128, 91, 134, 101, 138, 114, 130, 124, 116, 122, 104,
      112,
    ],
    fillColor: "rgb(45, 65, 95, 0.8)",
  },
  7: {
    name: "7",
    shape: "poly",
    coords: [
      132, 82, 140, 79, 150, 74, 158, 76, 165, 86, 160, 97, 152, 104, 139, 102,
      131, 92,
    ],
    fillColor: "rgb(45, 65, 95, 0.8)",
  },
  8: {
    name: "8",
    shape: "poly",
    coords: [
      165, 64, 178, 60, 189, 60, 203, 65, 198, 77, 191, 87, 183, 95, 172, 92,
      163, 77,
    ],
    fillColor: "rgb(45, 65, 95, 0.8)",
  },
  9: {
    name: "9",
    shape: "poly",
    coords: [
      207, 64, 219, 61, 230, 63, 242, 64, 245, 72, 235, 94, 222, 95, 209, 84,
      204, 69,
    ],
    fillColor: "rgb(45, 65, 95, 0.8)",
  },
  10: {
    name: "10",
    shape: "poly",
    coords: [
      253, 72, 268, 78, 274, 84, 269, 103, 255, 104, 244, 100, 241, 92, 244, 77,
    ],
    fillColor: "rgb(45, 65, 95, 0.8)",
  },
  11: {
    name: "11",
    shape: "poly",
    coords: [
      285, 92, 295, 93, 300, 99, 302, 107, 300, 115, 290, 122, 283, 127, 271,
      122, 268, 106, 273, 94,
    ],
    fillColor: "rgb(45, 65, 95, 0.8)",
  },
  12: {
    name: "12",
    shape: "poly",
    coords: [
      304, 120, 312, 123, 317, 128, 317, 136, 315, 143, 311, 149, 304, 150, 290,
      150, 279, 147, 277, 137, 282, 128, 291, 122,
    ],
    fillColor: "rgb(45, 65, 95, 0.8)",
  },
  13: {
    name: "13",
    shape: "poly",
    coords: [
      322, 153, 328, 157, 331, 161, 331, 167, 329, 173, 321, 179, 311, 182, 302,
      183, 294, 176, 293, 163, 297, 157, 305, 153, 319, 152,
    ],
    fillColor: "rgb(45, 65, 95, 0.8)",
  },
  14: {
    name: "14",
    shape: "poly",
    coords: [
      297, 205, 303, 194, 308, 190, 313, 185, 319, 182, 325, 179, 331, 179, 341,
      184, 344, 190, 345, 196, 347, 204, 343, 214, 334, 223, 329, 227, 321, 231,
      315, 230, 306, 223, 298, 210,
    ],
    fillColor: "rgb(45, 65, 95, 0.8)",
  },
  15: {
    name: "15",
    shape: "poly",
    coords: [
      309, 242, 315, 236, 320, 232, 325, 228, 335, 226, 342, 226, 349, 226, 352,
      230, 356, 234, 356, 246, 354, 252, 353, 259, 349, 264, 344, 270, 332, 272,
      323, 272, 317, 270, 306, 259, 304, 251,
    ],
    fillColor: "rgb(45, 65, 95, 0.8)",
  },
  18: {
    name: "18",
    shape: "poly",
    coords: [
      484, 230, 491, 225, 498, 219, 509, 217, 517, 220, 523, 220, 525, 228, 527,
      235, 527, 243, 527, 252, 526, 260, 521, 264, 510, 267, 500, 267, 493, 264,
      486, 258, 483, 248, 482, 242,
    ],
    fillColor: "rgb(45, 65, 95, 0.8)",
  },
  19: {
    name: "19",
    shape: "poly",
    coords: [
      495, 176, 501, 171, 509, 166, 518, 167, 525, 168, 531, 172, 536, 176, 540,
      180, 541, 187, 539, 193, 537, 200, 538, 207, 533, 212, 529, 216, 521, 218,
      514, 217, 506, 215, 498, 210, 493, 202, 490, 192, 491, 184,
    ],
    fillColor: "rgb(45, 65, 95, 0.8)",
  },
  20: {
    name: "20",
    shape: "poly",
    coords: [
      522, 139, 529, 138, 536, 138, 543, 141, 548, 145, 549, 153, 550, 159, 546,
      164, 539, 169, 532, 170, 523, 169, 517, 162, 514, 153, 514, 146,
    ],
    fillColor: "rgb(45, 65, 95, 0.8)",
  },
  21: {
    name: "21",
    shape: "poly",
    coords: [
      534, 115, 539, 110, 546, 109, 553, 108, 559, 111, 563, 115, 567, 121, 567,
      130, 563, 141, 554, 143, 547, 141, 538, 136, 534, 130, 533, 122,
    ],
    fillColor: "rgb(45, 65, 95, 0.8)",
  },
  22: {
    name: "22",
    shape: "poly",
    coords: [
      561, 88, 566, 87, 575, 87, 582, 91, 587, 97, 587, 103, 586, 109, 579, 117,
      571, 118, 562, 114, 558, 106, 557, 96,
    ],
    fillColor: "rgb(45, 65, 95, 0.8)",
  },
  23: {
    name: "23",
    shape: "poly",
    coords: [
      586, 81, 589, 77, 597, 75, 605, 77, 610, 83, 607, 94, 603, 104, 597, 105,
      592, 101, 586, 94, 583, 87,
    ],
    fillColor: "rgb(45, 65, 95, 0.8)",
  },
  24: {
    name: "24",
    shape: "poly",
    coords: [
      611, 72, 617, 71, 623, 71, 630, 71, 633, 74, 635, 82, 631, 89, 625, 96,
      617, 97, 614, 89, 607, 81, 609, 75,
    ],
    fillColor: "rgb(45, 65, 95, 0.8)",
  },
  25: {
    name: "25",
    shape: "poly",
    coords: [
      642, 73, 650, 71, 657, 70, 662, 72, 663, 78, 663, 85, 659, 90, 658, 95,
      652, 97, 646, 95, 640, 87, 637, 80, 641, 73,
    ],
    fillColor: "rgb(45, 65, 95, 0.8)",
  },
  26: {
    name: "26",
    shape: "poly",
    coords: [
      671, 76, 679, 75, 685, 77, 690, 85, 688, 90, 686, 96, 681, 103, 676, 106,
      669, 104, 665, 88, 665, 81, 667, 77,
    ],
    fillColor: "rgb(45, 65, 95, 0.8)",
  },
  27: {
    name: "27",
    shape: "poly",
    coords: [
      697, 85, 706, 85, 712, 89, 715, 95, 715, 107, 711, 115, 702, 117, 691,
      116, 687, 105, 686, 98, 689, 92,
    ],
    fillColor: "rgb(45, 65, 95, 0.8)",
  },
  28: {
    name: "28",
    shape: "poly",
    coords: [
      718, 109, 725, 107, 732, 109, 735, 113, 738, 121, 738, 127, 737, 134, 731,
      141, 725, 143, 715, 142, 706, 135, 705, 122, 709, 113,
    ],
    fillColor: "rgb(45, 65, 95, 0.8)",
  },
  29: {
    name: "29",
    shape: "poly",
    coords: [
      740, 137, 746, 137, 753, 140, 758, 145, 758, 152, 758, 161, 749, 169, 739,
      171, 730, 168, 724, 160, 724, 149, 725, 142, 733, 137,
    ],
    fillColor: "rgb(45, 65, 95, 0.8)",
  },
  30: {
    name: "30",
    shape: "poly",
    coords: [
      733, 179, 741, 172, 748, 168, 754, 165, 761, 166, 767, 167, 773, 171, 779,
      178, 782, 191, 778, 201, 775, 210, 769, 215, 758, 220, 749, 218, 740, 214,
      736, 206, 735, 198, 730, 189,
    ],
    fillColor: "rgb(45, 65, 95, 0.8)",
  },
  31: {
    name: "31",
    shape: "poly",
    coords: [
      751, 221, 757, 220, 764, 217, 771, 217, 776, 220, 782, 224, 787, 229, 789,
      236, 789, 245, 787, 256, 783, 262, 773, 268, 762, 268, 753, 265, 749, 259,
      746, 246, 745, 236, 746, 228,
    ],
    fillColor: "rgb(45, 65, 95, 0.8)",
  },
};

export const OCCLUSAL_LIST = {
  "If No Occlusal Clearance *": {
    "Adjust Opposing": false,
    "Reduction Coping": false,
    "Phone Call": false,
  },
}

export const CROWNS_LIST = {

  "All Ceramic Crowns": {
    "Full Zirconia": {isSelected: false, price_id: 'price_1Njv3xJn010gzbm6YfWxLEWD'},
    "Layered Zirconia (PFZ)": {isSelected: false, price_id: 'price_1Njv3xJn010gzbm6YfWxLEWD'},
    "ZiRok XT (High Translucent)": {isSelected: false, price_id: 'price_1Njv3xJn010gzbm6YfWxLEWD'},
    "Hybrid Zirconia (Cut-Black)": {isSelected: false, price_id: 'price_1Njv3xJn010gzbm6YfWxLEWD'},
    "Full Emax": {isSelected: false, price_id: 'price_1Njv3xJn010gzbm6YfWxLEWD'},
    "Layered Emax": {isSelected: false, price_id: 'price_1Njv3xJn010gzbm6YfWxLEWD'},
    "Veneer": {isSelected: false, price_id: 'price_1Njv3xJn010gzbm6YfWxLEWD'},
    "Inlay / Onlay (Emax)": {isSelected: false, price_id: 'price_1Njv3xJn010gzbm6YfWxLEWD'},
    "Fiberglass Reinforced MD Bridge": {isSelected: false, price_id: 'price_1Njv3xJn010gzbm6YfWxLEWD'},
  },
  "Full-Cast Crowns": {
    "Non Precious": {isSelected: false, price_id: 'price_1Njv3xJn010gzbm6YfWxLEWD'},
    "Semi Precious": {isSelected: false, price_id: 'price_1Njv3xJn010gzbm6YfWxLEWD'},
    "Full Gold": {isSelected: false, price_id: 'price_1Njv3xJn010gzbm6YfWxLEWD'},
    "White High Noble": {isSelected: false, price_id: 'price_1Njv3xJn010gzbm6YfWxLEWD'},
    "Post-Core": {isSelected: false, price_id: 'price_1Njv3xJn010gzbm6YfWxLEWD'},
  },
  "PFM Crowns": {
    "Non Precious": {isSelected: false, price_id: 'price_1Njv3xJn010gzbm6YfWxLEWD'},
    "Semi Precious": {isSelected: false, price_id: 'price_1Njv3xJn010gzbm6YfWxLEWD'},
    "White High Noble": {isSelected: false, price_id: 'price_1Njv3xJn010gzbm6YfWxLEWD'},
  },
  "Other": {
    "Temp. Crown (PMMA)": {isSelected: false, price_id: 'price_1Njv3xJn010gzbm6YfWxLEWD'},
    "Diagnostic Wax-Up": {isSelected: false, price_id: 'price_1Njv3xJn010gzbm6YfWxLEWD'},
  },
};

export const DENTURE_LIST = {
  "Denture / Partial": {
    "Immediate Denture": {isSelected: false, price_id: 'price_1Njv3xJn010gzbm6YfWxLEWD'},
    "Complete Denture": {isSelected: false, price_id: 'price_1Njv3xJn010gzbm6YfWxLEWD'},
    "Metal Frame Partial": {isSelected: false, price_id: 'price_1Njv3xJn010gzbm6YfWxLEWD'},
    "Acrylic Partial": {isSelected: false, price_id: 'price_1Njv3xJn010gzbm6YfWxLEWD'},   
     "Valplast Partial": {isSelected: false, price_id: 'price_1Njv3xJn010gzbm6YfWxLEWD'},
    "Flipper (1-2 Teeth)": {isSelected: false, price_id: 'price_1Njv3xJn010gzbm6YfWxLEWD'},
  },
  "Gum Shade": {
    Light: {isSelected: false, price_id: 'price_1Njv3xJn010gzbm6YfWxLEWD'},
    Original: {isSelected: false, price_id: 'price_1Njv3xJn010gzbm6YfWxLEWD'},
    "Redish Pink": {isSelected: false, price_id: 'price_1Njv3xJn010gzbm6YfWxLEWD'},
    Dark: {isSelected: false, price_id: 'price_1Njv3xJn010gzbm6YfWxLEWD'},
  },
  Teeth: { Premium: {isSelected: false, price_id: 'price_1Njv3xJn010gzbm6YfWxLEWD'}, Stock: {isSelected: false, price_id: 'price_1Njv3xJn010gzbm6YfWxLEWD'} },
  "Night Guard / Retainer": {
    "Thermo": {isSelected: false, price_id: 'price_1Njv3xJn010gzbm6YfWxLEWD'},
    "Comfort Hard & Soft Splint": {isSelected: false, price_id: 'price_1PB6JCHkh3QqkHTqIuACGolA'},
    "Essix Retainer": {isSelected: false, price_id: 'price_1PB6KDHkh3QqkHTqZyAeee6g'},
    "Whitening Tray": {isSelected: false, price_id: 'price_1Njv3xJn010gzbm6YfWxLEWD'},
  },
  "Implant Denture": { "Hybrid Denture": {isSelected: false, price_id: 'price_1Njv3xJn010gzbm6YfWxLEWD'}, "Implant Over Denture": {isSelected: false, price_id: 'price_1Njv3xJn010gzbm6YfWxLEWD'} },
  Other: {
    "Custom Tray": {isSelected: false, price_id: 'price_1Njv3xJn010gzbm6YfWxLEWD'},
    "Reline / Rebase": {isSelected: false, price_id: 'price_1Njv3xJn010gzbm6YfWxLEWD'},
    "Denture Repair": {isSelected: false, price_id: 'price_1PB6KhHkh3QqkHTqmokNH2wM'},
    "Valplast Clasp": {isSelected: false, price_id: 'price_1Njv3xJn010gzbm6YfWxLEWD'},
  },
  "Return For": {
    "Base Plate & Wax-Rim": {isSelected: false, price_id: 'price_1Njv3xJn010gzbm6YfWxLEWD'},
    "Metal Frame & Wax-Rim": {isSelected: false, price_id: 'price_1Njv3xJn010gzbm6YfWxLEWD'},
    "Teeth Arrangement": {isSelected: false, price_id: 'price_1Njv3xJn010gzbm6YfWxLEWD'},
    "Cure & Finish": {isSelected: false, price_id: 'price_1Njv3xJn010gzbm6YfWxLEWD'},
  },
};

export const IMPLANT_LIST = {
  "Implant Abutment": {
    "Genuine": {
      "Cement-retained": {
        "Custom-Titanium": { isSelected: false, price_id:"price_1Njv3xJn010gzbm6YfWxLEWD"},
        "Custom-Zirconia": { isSelected: false, price_id:"price_1Njv3xJn010gzbm6YfWxLEWD"},
        "UCLA": { isSelected: false, price_id:"price_1Njv3xJn010gzbm6YfWxLEWD", provided: true},
        "Angled Screw Channel (ASC)": { isSelected: false, price_id:"price_1Njv3xJn010gzbm6YfWxLEWD"},
      },
      "Screw-retained": {
        "Custom-Titanium": { isSelected: false, price_id:"price_1Njv3xJn010gzbm6YfWxLEWD"},
        "Ti-base": { isSelected: false, price_id:"price_1Njv3xJn010gzbm6YfWxLEWD"},
        "UCLA": { isSelected: false, price_id:"price_1Njv3xJn010gzbm6YfWxLEWD", provided: true},
      }
    },
    "Dentopia": {
      "Cement-retained": {
        "Custom-Titanium": { isSelected: false, price_id:"price_1Njv3xJn010gzbm6YfWxLEWD"},
        "Custom-Zirconia": { isSelected: false, price_id:"price_1Njv3xJn010gzbm6YfWxLEWD"},
      },
      "Screw-retained": {
        "Ti-base": { isSelected: false, price_id:"price_1Njv3xJn010gzbm6YfWxLEWD"},
        "Custom-Titanium": { isSelected: false, price_id:"price_1Njv3xJn010gzbm6YfWxLEWD"},
        "Angled Screw Channel (ASC)": { isSelected: false, price_id:"price_1Njv3xJn010gzbm6YfWxLEWD"},
      }
    },
    "Encode": {
      "Cement-retained": {
        "Custom-Titanium": { isSelected: false, price_id:"price_1Njv3xJn010gzbm6YfWxLEWD"},
        "Custom-Zirconia": { isSelected: false, price_id:"price_1Njv3xJn010gzbm6YfWxLEWD"},
      },
      "Screw-retained": {
        "Custom-Titanium": { isSelected: false, price_id:"price_1Njv3xJn010gzbm6YfWxLEWD"},
        "Angled Screw Channel (ASC)": { isSelected: false, price_id:"price_1Njv3xJn010gzbm6YfWxLEWD"},
      }
    },
    "Stock Abutment": {
      "Cement-retained": {  "Cement-retained": { isSelected: false, price_id:"price_1Njv3xJn010gzbm6YfWxLEWD", provided: true},},
      "Screw-retained": {  "Screw-retained": { isSelected: false, price_id:"price_1Njv3xJn010gzbm6YfWxLEWD", provided: true},}
    },
    "All on X": {
      "Screw Retained Hybrid": {
        "Call": { isSelected: false, price_id:"price_1Njv3xJn010gzbm6YfWxLEWD"}
      },
      "Denture": {
        "Call": { isSelected: false, price_id:"price_1Njv3xJn010gzbm6YfWxLEWD"}
      }
    },
    "Other": {
      "third-party": {
        "Cement-retained": {
          isSelected: false, price_id:"price_1Njv3xJn010gzbm6YfWxLEWD"
        },
        "Screw-retained": {
          isSelected: false, price_id:"price_1Njv3xJn010gzbm6YfWxLEWD"
        }
      }
    }
  },
  "Implant Crowns": {
    "Zirconia": {
      "Regular Zirconia": { isSelected: false, price_id:'price_1Njv3xJn010gzbm6YfWxLEWD'},
      "Zirok High Translucent": { isSelected: false, price_id:'price_1Njv3xJn010gzbm6YfWxLEWD'}
    },
    "PFZ": {
      "Porcelain Fused to Zirconia": { isSelected: false, price_id:'price_1Njv3xJn010gzbm6YfWxLEWD'},
    },
    "PFM": {
      "Non Precious": { isSelected: false, price_id:'price_1Njv3xJn010gzbm6YfWxLEWD'},
      "Semi Precious": { isSelected: false, price_id:'price_1Njv3xJn010gzbm6YfWxLEWD'},
      "High Noble": { isSelected: false, price_id:'price_1Njv3xJn010gzbm6YfWxLEWD'}
    },
    "Full Cast": {
      "Full Gold": { isSelected: false, price_id:'price_1Njv3xJn010gzbm6YfWxLEWD'},
      "Semi Precious": { isSelected: false, price_id:'price_1Njv3xJn010gzbm6YfWxLEWD'},
      "Non Precious": { isSelected: false, price_id:'price_1Njv3xJn010gzbm6YfWxLEWD'}
    },
    "EMAX": {
      "Layered Emax": { isSelected: false, price_id:'price_1Njv3xJn010gzbm6YfWxLEWD'},
      "Full Emax": { isSelected: false, price_id:'price_1Njv3xJn010gzbm6YfWxLEWD'},
    },
    "PMMA": {
      "PMMA": {
        isSelected: false, price_id:"price_1Njv3xJn010gzbm6YfWxLEWD"
      }
    }
  },
};
