import React from 'react'

function StatusSVG() {
  return (
    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.7857 8C10.7995 8 10 8.79948 10 9.78569V10.2273C10 10.7796 9.55228 11.2273 9 11.2273C8.44772 11.2273 8 10.7796 8 10.2273V9.78569C8 7.69491 9.69491 6 11.7857 6H11.9339C13.6089 6 15.0625 7.15543 15.4403 8.78721C15.8594 10.5966 14.8328 12.4278 13.0705 13.0143L12.9679 13.0484C12.8212 13.0973 12.7222 13.2345 12.7222 13.3891V14C12.7222 14.5523 12.2745 15 11.7222 15C11.1699 15 10.7222 14.5523 10.7222 14V13.3891C10.7222 12.3733 11.3725 11.4716 12.3363 11.1508L12.4389 11.1166C13.222 10.856 13.6781 10.0424 13.4919 9.23843C13.324 8.51339 12.6781 8 11.9339 8H11.7857Z" fill="#5C59E8"/>
    <path d="M11.75 16C12.3023 16 12.75 16.4477 12.75 17C12.75 17.5523 12.3023 18 11.75 18C11.1977 18 10.75 17.5523 10.75 17C10.75 16.4477 11.1977 16 11.75 16Z" fill="#5C59E8"/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22ZM12 20C16.4183 20 20 16.4183 20 12C20 7.58172 16.4183 4 12 4C7.58172 4 4 7.58172 4 12C4 16.4183 7.58172 20 12 20Z" fill="#5C59E8"/>
    </svg>
    
  )
}

export default StatusSVG