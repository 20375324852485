import React from 'react'

function ChevronRightSVG() {
  return (
    <svg width="45" height="45" viewBox="0 0 24 24" fill="#BEBDF6" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M8.79289 5.29289C8.40237 5.68342 8.40237 6.31658 8.79289 6.70711L14.0858 12L8.79289 17.2929C8.40237 17.6834 8.40237 18.3166 8.79289 18.7071C9.18342 19.0976 9.81658 19.0976 10.2071 18.7071L16.5607 12.3536C16.7559 12.1583 16.7559 11.8417 16.5607 11.6464L10.2071 5.29289C9.81658 4.90237 9.18342 4.90237 8.79289 5.29289Z" fill="#5C59E8"/>
    </svg>
  )
}

export default ChevronRightSVG