import React from 'react'

function CrownIconSVG() {
  return (
    <div className="tooltip text-black" data-tip="Crown">
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="10" cy="10" r="10" fill="#FDF1E8"/>
    <path d="M13.4063 8.5H12.4375C12.3802 8.22135 12.2799 7.97656 12.1367 7.76562C11.9961 7.55469 11.8242 7.3776 11.6211 7.23438C11.4206 7.08854 11.1979 6.97917 10.9531 6.90625C10.7083 6.83333 10.4531 6.79687 10.1875 6.79687C9.70313 6.79687 9.26432 6.91927 8.87109 7.16406C8.48047 7.40885 8.16927 7.76953 7.9375 8.24609C7.70833 8.72266 7.59375 9.30729 7.59375 10C7.59375 10.6927 7.70833 11.2773 7.9375 11.7539C8.16927 12.2305 8.48047 12.5911 8.87109 12.8359C9.26432 13.0807 9.70313 13.2031 10.1875 13.2031C10.4531 13.2031 10.7083 13.1667 10.9531 13.0937C11.1979 13.0208 11.4206 12.9128 11.6211 12.7695C11.8242 12.6237 11.9961 12.4453 12.1367 12.2344C12.2799 12.0208 12.3802 11.776 12.4375 11.5H13.4063C13.3333 11.9089 13.2005 12.2747 13.0078 12.5977C12.8151 12.9206 12.5755 13.1953 12.2891 13.4219C12.0026 13.6458 11.681 13.8164 11.3242 13.9336C10.9701 14.0508 10.5911 14.1094 10.1875 14.1094C9.50521 14.1094 8.89844 13.9427 8.36719 13.6094C7.83594 13.276 7.41797 12.8021 7.11328 12.1875C6.80859 11.5729 6.65625 10.8437 6.65625 10C6.65625 9.15625 6.80859 8.42708 7.11328 7.8125C7.41797 7.19792 7.83594 6.72396 8.36719 6.39062C8.89844 6.05729 9.50521 5.89062 10.1875 5.89062C10.5911 5.89062 10.9701 5.94922 11.3242 6.06641C11.681 6.18359 12.0026 6.35547 12.2891 6.58203C12.5755 6.80599 12.8151 7.07943 13.0078 7.40234C13.2005 7.72266 13.3333 8.08854 13.4063 8.5Z" fill="#E46A11"/>
    </svg>
    </div>
  )
}

export default CrownIconSVG