import React from 'react'

function RushCaseIconSVG() {
  return (
    <div className="tooltip text-black" data-tip="Rush Case">
    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.2707 2.03709C12.394 2.07166 12.5075 2.12925 12.6066 2.20493C12.72 2.29128 12.8104 2.39816 12.8758 2.51695C12.96 2.66994 13.0027 2.8427 13 3.01685V9H18.5446C19.5309 9 20.1287 10.0889 19.5991 10.9211L12.8528 21.5224C12.835 21.5515 12.8157 21.5796 12.7951 21.6066C12.7091 21.7196 12.6027 21.8097 12.4845 21.875C12.3626 21.9426 12.2251 21.9855 12.0787 21.9969C11.9625 22.0062 11.8442 21.9953 11.7292 21.9629C11.606 21.9283 11.4924 21.8707 11.3934 21.7951C11.2799 21.7087 11.1895 21.6018 11.1241 21.483C11.0575 21.3624 11.0151 21.2267 11.0033 21.0822C11.0006 21.0493 10.9995 21.0163 11 20.9831V15H5.4554C4.46903 15 3.87127 13.9111 4.40082 13.0789L11.1471 2.47755C11.165 2.44849 11.1843 2.4204 11.2049 2.3934C11.2909 2.28047 11.3972 2.1904 11.5153 2.12509C11.7433 1.99905 12.0155 1.96519 12.2707 2.03709ZM11 6.43406L6.82165 13H11.75C12.4403 13 13 13.5596 13 14.25V17.5659L17.1783 11H12.25C11.5596 11 11 10.4404 11 9.75V6.43406Z" fill="#F36960"/>
</svg>
    </div>
  )
}

export default RushCaseIconSVG