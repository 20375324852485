import React, { useState, useContext } from "react";
import DoctorTable from "../components/DoctorTable";
import AddNewDoctor from "../components/AddNewDoctor";
import GeneralInformation from "../components/GeneralInformation";

function Settings() {
  const [formSubmitted, setFormSubmitted] = useState(false);

  return (
    <div className="flex flex-col p-6 gap-6">
      <div className="text-xl drop-shadow-lg text-[#333843]">Settings</div>
      <div className="flex 2xl:flex-row flex-col gap-6">
        <div className="flex flex-col rounded-lg drop-shadow-lg gap-6">
          <div className="flex bg-white min-h-[550px] rounded-lg w-[850px]">
            <DoctorTable formSubmitted={formSubmitted} setFormSubmitted={setFormSubmitted} />
          </div>
        </div>
        <div className="flex flex-col bg-white min-w-[550px] w-[600px] min-h-[500px] w-1/2 rounded-lg drop-shadow-lg">
          <div className="text-lg p-8 text-[#333843]">General Information</div>
          <GeneralInformation />
        </div>
      </div>
    </div>
  );
}

export default Settings;
