import React, { useContext, useState } from 'react'
import CaseContext from '../contexts/CaseContext';
import { DENTURE_LIST, TEETH_INT_DENTURE } from '../constants';
import { domToDataUrl } from 'modern-screenshot';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./Project.css";

function DentureList({ setArch }) {
  const { caseDentureData, setCaseDentureData, 
    setCaseList, setDentureTeethInt, 
    denturesList, setDenturesList } = useContext(CaseContext);
  const [ selected, setSelected ] = useState(false);
  const [ notes, setNotes ] = useState("");

  const onChange = (e) => {
    //if the value of the checkbox is already inside caseDentureData (unchecking the box)
    if(e.target.id in caseDentureData && caseDentureData[e.target.id].includes(e.target.value)){
      if(caseDentureData[e.target.id].length === 1) {
        setCaseDentureData((prevState) => {
          const nextState = { ...prevState };

          // Add new key-value pairs
          nextState.price_id = denturesList[e.target.id][e.target.value].price_id;
          nextState.type = "denture";
        
          // Delete the key
          delete nextState[e.target.id];
        
          return nextState;
        })
      }else {
        setCaseDentureData((prevState) => ({
          ...prevState,
          [e.target.id]: prevState[e.target.id].filter(item => item !== e.target.value), // Remove specific value
          price_id: denturesList[e.target.id][e.target.value]['price_id'],
          type: "denture"
        })) 
      }
      setDenturesList(prevState => ({
        ...prevState,
        [e.target.id]: {
          ...prevState[e.target.id],
          [e.target.value]: {
            ...prevState[e.target.id][e.target.value],
            isSelected: false
          }   
        }
        
      }))
      setSelected(false)
    }else {
      setCaseDentureData((prevState) => ({
        ...prevState,
        [e.target.id]: [ ...(prevState[e.target.id] || []), e.target.value],
        price_id: denturesList[e.target.id][e.target.value]['price_id'],
        type: "denture"
    }))
    setDenturesList((prevState) => ({
      ...prevState,
      [e.target.id]: {
        ...prevState[e.target.id],
        [e.target.value]: {
          ...prevState[e.target.id][e.target.value],
          isSelected: true
        } 
      }
    }))
    setSelected(true)
    }
  }

  const handleCaseNotesChange = (e) => {
    setNotes(e.target.value);
    setCaseDentureData((prevState) => ({
     ...prevState,
     [e.target.id]:e.target.value
 }))
 };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    switch (name) {
      case 'teeth_shade':
        setCaseDentureData(prev => ({
          ...prev,
          'teeth_shade': value
      }))
        break;
      default:
        break;
    }
    console.log(caseDentureData)
  };

  const onClick = async (e) => {
    e.preventDefault()
    const target = document.getElementById("teethImg");
    const target2 = document.getElementById("dentureChecks");
    let updatedState = { ...caseDentureData }
    await domToDataUrl(target, {
      quality: 1,
      scale: 2,
    }).then(async (data) => {
      await domToDataUrl(target2, {
        quality: 1,
        scale: 2,
        onCloneNode: function (logo) {
          logo.style.columns = '3'
          logo.style.columnFill = 'balance-all'
          const addButton = logo.querySelector('#addButton')
          addButton.remove()
        }
      }).then((data2) =>{ 
        updatedState = { ...updatedState, 'check':data2 };
      })
          updatedState = { ...updatedState, 'img':data }
    setCaseList((prevState) => [...prevState, updatedState]);
    setCaseDentureData({ teethArr: [], teeth_shade: "", arch: { upper: false, lower: false } });
    setArch({
      upper: false,
      lower: false
    })
    setNotes("")
    setDentureTeethInt(TEETH_INT_DENTURE)
    setDenturesList(DENTURE_LIST)
    setSelected(false)
    toast.success("Denture Added",{
      className: "toast-message",
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      progress: undefined,
      theme: "light",
      });
  })}

  return (
    <>
    <div className='w-full justify-center' style={{display:"inline-flex", flexDirection:"row",alignItems:"center",position:"relative", padding: "5px 0px"}}>
    <div className='flex flex-row gap-1 ml-1 w-4/5 justify-center'>
      <label htmlFor="">Teeth Shade</label>
      <input
        name='teeth_shade'
        value={caseDentureData['teeth_shade']} 
        onChange={(e) => handleInputChange(e)}
        className="input input-bordered border-gray-300 input-sm w-3/5 max-w-xs bg-defbg ml-2" />
    </div>
  </div>
    <div className='gap-1 w-4/5'>
        <textarea id="notes" value={notes} 
          onChange={(e) => handleCaseNotesChange(e)} 
          className="textarea textarea-bordered border-gray-500 mt-2 w-full m-x-2 h-[155px]" 
          placeholder="Notes"></textarea>
     </div>
    <div className="flex bg-white flex-col gap-6 mt-3">
    <form id='dentureChecks' className="columns-3 whitespace-nowrap">
    { Object.keys(denturesList).map((group) => (         
    <fieldset className="border p-2 break-inside-avoid">
            <legend className="p-2">{group}</legend>
            {Object.keys(denturesList[group]).map((select, idx) => (              
            <div key={idx} className="flex p-0.5 items-center">
              <input type="checkbox" id={group} className="checkbox checkbox-primary" 
            value={select} 
            onChange={onChange} 
            checked={denturesList[group][select]['isSelected']} 
           // Scenario when only need 1 selected
           // disabled={selected && !denturesList[group][select]['isSelected']} 
            />
            <label className="pl-1 text-txt text-md">{select}</label>
          </div>
            ))}
          </fieldset>
        ))}
        <button id='addButton' className={"btn btn-primary disabled:btn-outline disabled:border-[#CBCBCB] bg-[#DEDEFA] mt-10 w-4/5 ml-8 text-[#5C59E8] border-white disabled:text-gray-200 hover:text-white"}
        disabled={!(((caseDentureData?.arch === 'upper' || caseDentureData?.arch === 'lower') || caseDentureData?.teethArr.length > 0)
        && caseDentureData?.teeth_shade !== '' 
        && (Object.keys(caseDentureData).filter(key => !['teeth_shade', 'arch', 'teethArr','type','notes', 'price_id'].includes(key)).length > 0) )}
         onClick={(e) => onClick(e)}>
          + Add Denture 
        </button>   
        <ToastContainer />
       </form>  
  </div>
  </>
  )
}

export default DentureList