import React from "react";
import { useState, useEffect, useRef, useContext } from "react";
import CaseContext from "../contexts/CaseContext";
import generatePDF from "../components/PdfCreator";
import "../styles/react-datepicker.css";
import ItemCard from "../components/ItemCard";
import axios from "axios";
import AuthContext from "../contexts/AuthContext";
import ImageGallery from "react-image-gallery";
// import "react-image-gallery/styles/css/image-gallery.css";
import UTCDatePicker from "./UTCDatePicker";
import { enIE } from "date-fns/locale";
import { useNavigate } from "react-router-dom";
import AddNewDoctor from "../components/AddNewDoctor";
import AddSVG from "../svg/AddIconSVG";
import Pdftest from "./Pdftest";

function replaceUnderscoresWithSpaces(inputString) {
  // Split the string into an array of words
  var words = inputString.split("_");

  // Capitalize the first letter of each word and convert underscores to spaces
  for (var i = 0; i < words.length; i++) {
    words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
  }

  // Join the words back together with spaces
  var resultString = words.join(" ");

  return resultString;
}

function NewCase() {
  const { auth } = useContext(AuthContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  // const [ pdfData, setPdfData ] = useState({})

  const [startDate, setStartDate] = useState(null);
  const [doctorData, setDoctorData] = useState([]);
  const [patientFirst, setPatientFirst] = useState("");
  const [patientLast, setPatientLast] = useState("");
  const [imgPrev, setImgPrev] = useState([]);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const { setCaseCrownData, caseList, setCaseList, isRushCase, setIsRushCase } =
    useContext(CaseContext);
  //TODO: Add Denture and Implant Data to clear state when adding items

  const [selectedDoctorId, setSelectedDoctorId] = useState(null);
  const navigate = useNavigate();

  const handleSelectChange = (event) => {
    setSelectedDoctorId(event.target.value);
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await axios.get("/api/users/current/doctors");
        setDoctorData(res.data.doctors);
        if (res.data.doctors.length === 1) {
          setSelectedDoctorId(res.data.doctors[0].id ?? null);
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [formSubmitted]);

  const removeItemHandler = (item) => {
    setCaseList((prev) => prev.filter((val, ind) => ind !== item));
  };

  const onChangeFirst = (e) => {
    setPatientFirst(e.target.value);
  };

  const onChangeLast = (e) => {
    setPatientLast(e.target.value);
  };

  const imageView = (e) => {
    let images = document.querySelector(`#file-upload-${e}`);
    if (images && images.files.length) {
      setImgPrev(Array.from(images.files));
      // console.log(imgPrev, "imgprev")
    } else {
      setImgPrev([]);
    }
  };

  const fixCaseList = (list) => {
    const fixedList = JSON.parse(JSON.stringify(list))
    const result = fixedList.map(({ img, check, ...rest}) => ({...rest}));
    return result
  }

  const imageTest = async (e) => {
    for (let i = 0; i < caseList.length; i++) {
      let images = document.querySelector(`#file-upload-${i}`);
      let formData;
      if (images.files.length) {
        formData = new FormData();
        for (const img of images.files) {
          formData.append("image", img);
        }
        const res = await fetch("/api/cases/images", {
          method: "POST",
          body: formData,
        });
        if (res.ok) {
          let data = await res.json();
          setCaseList((prev) => {
            prev[i].images = data.images;
            return [...prev];
          });
        } else {
          console.log(res);
        }
      }
    }
  };

  const id_filters = ["price_id", 'img', 'check'];

  //   const handleTest = async (e) => {
  //     e.preventDefault();
  //     let payload = {
  //         user_id: user.user.id,
  //         post_body: newPost,
  //         images: ""
  //     }
  //     let images = document.querySelector("#file-upload")
  //     let formData
  //     // console.log(images.files.length)
  //     if (images.files.length) {
  //         // console.log(images.files)
  //         formData = new FormData();
  //         for (const img of images.files) {
  //             formData.append("image", img);
  //         }
  //     }

  //     // aws uploads can be a bit slow—displaying
  //     // some sort of loading message is a good idea
  //     setImageLoading(true);

  //     const res = await fetch('/api/cases/images', {
  //         method: "POST",
  //         body: formData,
  //     });
  //     if (res.ok) {
  //         let data = await res.json();
  //         payload.images = data.images
  //         let postres = await dispatch(postActions.addUserPost(payload))
  //         if(postres){
  //             setErrors(postres)
  //         }
  //         else{
  //             // document.body.style.overflow = 'scroll'
  //             setShowModal(false)
  //         }
  //         setImageLoading(false);

  //     }
  //     else {
  //         setImageLoading(false);
  //         let data = await res.json();
  //         setErrors(data)
  //     }
  // }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isSubmitting) return; // Prevent multiple submissions

    try {
      setIsSubmitting(true);
      for (let i = 0; i < caseList.length; i++) {
        let images = document.querySelector(`#file-upload-${i}`);
        let formData;
        if (images.files.length) {
          formData = new FormData();
          for (const img of images.files) {
            formData.append("image", img);
          }
          const res = await fetch("/api/cases/images", {
            method: "POST",
            body: formData,
          });
          if (res.ok) {
            let data = await res.json();
            setCaseList((prev) => {
              prev[i].images = data.images;
              return [...prev];
            });
          } else {
            console.log(res);
          }
        }
      }
      let res2 = await axios.post(
        "/api/cases/",
        {
          user_id: auth.id,
          doctor_id: selectedDoctorId,
          patient_first: patientFirst,
          patient_last: patientLast,
          return_date: startDate,
          items: fixCaseList(caseList),
          is_rush_case: isRushCase,
          rush_case_status: isRushCase ? "pending" : null,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setIsSubmitting(false);
      // console.log(res2, 1);
      if (res2) {
        // setPdfData(res2.data)
        setCaseList([]);
        setCaseCrownData({ teethArr: [] });
        setPatientFirst("");
        setPatientLast("");
        setSelectedDoctorId(null);
        setIsRushCase(false);
        // generatePDF(res2.data);
        navigate("/cases");
      }
    } catch (error) {
      console.log(error);
      setIsSubmitting(false); // Reset isSubmitting in case of error
    }
  };

  return (
    <div className="flex flex-col p-6 gap-6">
      <div className="flex flex-col xl:flex-row gap-6">
        <div className="flex flex-col w-[1100px] shrink-0">
          <ItemCard />
        </div>
        <div className="flex flex-row xl:flex-col rounded-lg gap-[25px] xl:gap-6 sticky w-[1100px] xl:w-[350px] xl:mt-12">
          <form className="flex bg-white flex-col p-6 w-[350px] xl:w-auto gap-2 rounded-lg shadow-md overflow-hidden h-[378px]">
            <div className="flex flex-col dropdown">
              <div className="flex justify-between h-12 flex-row mt-2">
                <label className="text-txt text-lg">Dr. Name</label>
                <button
                  className="h-[15px] mb-3 btn btn-sm btn-primary
                 hover:bg-[#3d3b9c] duration-300 absolute right-0 
                 rounded-lg font-inter font-[700] text-[14px] text-[#FFF]"
                  onClick={(e) => {
                    document.getElementById("add_dr_modal").showModal();
                    e.preventDefault();
                  }}
                >
                  <AddSVG />
                  Add Doctor
                </button>
              </div>
              <select
                className="select select-bordered border-gray-300 w-full max-w-xs bg-defbg select-sm"
                value={selectedDoctorId}
                onChange={handleSelectChange}
              >
                <option value="">Select a doctor</option>
                {!!doctorData &&
                  doctorData.map((e, i) => (
                    <option key={e.id} value={e.id}>
                      {`Dr. ${e.doctor_first} ${
                        e.doctor_middle ? e.doctor_middle.charAt(0) + "." : ""
                      } ${e.doctor_last}`}
                    </option>
                  ))}
              </select>
            </div>
            <div className="flex flex-col">
              <label className="text-txt text-lg">Patient First Name</label>
              <input
                id="firstName"
                value={patientFirst}
                placeholder=""
                onChange={onChangeFirst}
                className="input input-bordered border-gray-300 input-sm w-full max-w-xs bg-defbg"
              ></input>
            </div>
            <div className="flex flex-col">
              <label className="text-txt text-lg">Patient Last Name</label>
              <input
                id="lastName"
                value={patientLast}
                placeholder=""
                onChange={onChangeLast}
                className="input input-bordered border-gray-300 input-sm w-full max-w-xs bg-defbg"
              ></input>
            </div>
            <div className="flex flex-col">
              <label className="text-txt text-lg">Return Date</label>
              <UTCDatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                isAdminPage={false}
              />
            </div>
            {/* <div className="flex flex-col">
              <label className="text-txt">Dr Signature</label>
              <input placeholder="" className="input input-bordered border-gray-300 input-sm w-full max-w-xs bg-defbg"></input>
            </div> */}
          </form>
          <dialog id="add_dr_modal" className="modal duration-0">
            <AddNewDoctor
              formSubmitted={formSubmitted}
              setFormSubmitted={setFormSubmitted}
            />
            <form method="dialog" className="modal-backdrop">
              <button>close</button>
            </form>
          </dialog>
          <div className="flex bg-white flex-col p-6 gap-4 rounded-lg shadow-md sticky top-5 w-[350px]">
            {caseList.map((t, ind) => (
              <div className="flex flex-col relative">
                {Object.keys(t)
                  .filter((f) => !id_filters.includes(f))
                  .map((i, idx) => (
                    <div
                      key={idx}
                      className={`text-txt break-words ${
                        i === "teethArr" ? "w-[90%]" : ""
                      }`}
                    >
                      {i === "teethArr" ? (
                        <b className="font-bold">Selected Teeth #</b>
                      ) : (
                        <b className="font-bold">
                          {replaceUnderscoresWithSpaces(i)}
                        </b>
                      )}
                      :{" "}
                      {typeof t[i] === "string" || typeof t[i] === "boolean"
                        ? t[i].toString()
                        : Array.isArray(t[i])
                        ? t[i].join(", ")
                        : Object.keys(t[i]).find((key) => t[key])}
                    </div>
                  ))}
                <div className="flex flex-row justify-between">
                  <div>
                    <label
                      htmlFor={`file-upload-${ind}`}
                      className="custom-file-upload"
                      onClick={() => console.log("Clicked")}
                    >
                      + Upload Images
                    </label>
                    <input id={`file-upload-${ind}`} multiple type="file" />
                  </div>
                  <button
                    className="custom-file-upload"
                    onClick={() => {
                      document.getElementById("my_img_modal").showModal();
                      imageView(ind);
                    }}
                  >
                    {`View Images ${
                      !!document.querySelector(`#file-upload-${ind}`) &&
                      document.querySelector(`#file-upload-${ind}`).files
                        ? `(${
                            document.querySelector(`#file-upload-${ind}`).files
                              .length
                          })`
                        : ""
                    }`}
                  </button>
                  <dialog id="my_img_modal" className="modal duration-0">
                    <form
                      method="dialog"
                      className="modal-box bg-white h-[800px]"
                    >
                      <h3 className="font-bold text-lg"></h3>
                      <div className="flex flex-col">
                        {/* <div>
                          {imgPrev.map((each) => (
                                <img src={URL.createObjectURL(each)}/>
                        ))}
                          </div> */}
                        <ImageGallery
                          items={imgPrev.map((e) => ({
                            original: URL.createObjectURL(e),
                            thumbnail: URL.createObjectURL(e),
                            showIndex: true,
                            showPlayButton: false,
                          }))}
                        />
                      </div>
                      <div className="modal-action">
                        <button className="btn">Close</button>
                      </div>
                    </form>
                    <form method="dialog" className="modal-backdrop">
                      <button>close</button>
                    </form>
                  </dialog>
                </div>
                <hr className="h-px mt-4 bg-gray-200 border-0"></hr>
                <button
                  className="absolute btn btn-circle btn-outline btn-error btn-xs top-0 right-0 h-2"
                  onClick={() => removeItemHandler(ind)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
            ))}
            <button
              id="bottom"
              className={
                caseList.length === 0
                  ? "btn btn-outline mt-2"
                  : "btn btn-primary mt-2 text-white"
              }
              disabled={
                caseList.length === 0 ||
                patientFirst === "" ||
                patientLast === "" ||
                startDate === null ||
                selectedDoctorId === null ||
                selectedDoctorId === ""
                  ? true
                  : false
              }
              onClick={() => document.getElementById("my_modal_2").showModal()}
              title="All fields are required"
            >
              Place Order ({caseList.length})
            </button>
            <dialog id="my_modal_2" className="modal duration-0">
              <form method="dialog" className="modal-box bg-white">
                <p className="text-lg">
                  Press confirm to generate the order and PDF
                </p>
                <p className="pb-4 text-red-500 italic">
                  Please Print the PDF and put the slip in the Case Box
                </p>
                <div className="modal-action">
                  {/* <button
                    className="btn btn-primary text-white"
                    onClick={handleSubmit}
                    disabled={isSubmitting}
                  >
                    Confirm
                  </button> */}
                  <Pdftest action={handleSubmit} type={2} disab={isSubmitting} data={{
                    id: 0,
                    doctor: doctorData.filter((e) => e.id === Number(selectedDoctorId))[0],
                    patient_first: patientFirst,
                    patient_last: patientLast,
                    return_date: startDate,
                    items: caseList
                  }}/>
                  <button className="btn btn-primary btn-outline">
                    Cancel
                  </button>
                </div>
              </form>
              <form method="dialog" className="modal-backdrop">
                <button>close</button>
              </form>
            </dialog>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NewCase;
