import React, { useState} from 'react'
import { NavLink } from "react-router-dom";
import axios from 'axios'

function AdminCaseStatus() {

    const [id, setId] = useState("");
    const [status, setStatus] = useState("");
  
    const handleIdChange = (e) => {
        setId(e.target.value);
    };
  
    const handleStatusChange = (e) => {
        setStatus(e.target.value);
    };

      const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          let res = await axios.put(
            `/api/cases/${id}`,
            { status },
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          if (res) {
            console.log(res)
            setId("")
            setStatus("")
          }
        } catch (error) {
          console.log(error.response, "ERROR");
        }
      };

  return (
    <div className='mt-5'>
        <NavLink to='/admin-page' className='w-24'>
      <button className='btn btn-primary w-24'>
              Back
      </button>
          </NavLink>
    <div className="flex flex-col items-center justify-center min-w-[550px] max-w-100 min-h-[650px] p-[15px] m-[15px] items-center justify-center">
    <form
      onSubmit={handleSubmit}
      className="min-w-[450px] min-h-[250px] items-center justify-center"
    >
      <div className="grow w-full h-full items-center justify-center">
        <div className="flex flex-col gap-[8px]">
        <label className="text-[#4D5464] text-[14px] font-[500]">Case ID</label>
          <input
            className="input input-bordered w-full bg-[#FFFFFF] w-full border-1 box-border pt-2 pb-2 pr-3 pl-3"
            id="id"
            name="id"
            value={id}
            onChange={handleIdChange}
            placeholder="Case Id"
            required
          />
        <label className="text-[#4D5464] text-[14px] font-[500]">Case Status</label>
          <input
            className="input input-bordered w-full bg-[#FFFFFF] w-full border-1 box-border pt-2 pb-2 pr-3 pl-3"
            id="status"
            name="status"
            value={status}
            onChange={handleStatusChange}
            placeholder="Case Status"
            required
          />
          <button
            className="btn btn-block
    non-italic normal bg-[#5C59E8] 
    border-transparent normal-case hover:bg-[#34AFF9] 
    hover:border-transparent rounded w-full h-[40px] m-t-[10px]"
          >
            <div className="text-[#FFFFFF]">Change Status</div>
          </button>
        </div>
      </div>
    </form>
    </div>
    </div>
  )
}

export default AdminCaseStatus