import React, { useState, useEffect } from 'react'
import axios from 'axios'
import AddNewDoctor from './AddNewDoctor';
import AddSVG from '../svg/AddIconSVG';

function paginate(array, page_number) {
    // human-readable page numbers usually start with 1, so we reduce 1 in the first argument
    return array.slice((page_number - 1) * 5, page_number * 5); // 5 is the number of items you want in the list
  }

function DoctorTable({formSubmitted, setFormSubmitted}) {
    const [ doctorData, setDoctorData ] = useState([]);
    const [ pageNumber, setPageNumber ] = useState(1);
    
    useEffect(() => {
        async function fetchData() {
          try {
            const res = await axios.get("/api/users/current/doctors");
            setDoctorData(res.data.doctors);
          } catch (error) {
            console.log(error);
          }
        }
        fetchData()
      }, [formSubmitted])

    return (
      <div className='min-h-[400px]'>
        <div className='flex flex-row justify-between'>
      <div className='text-lg p-8 text-[#333843]'>Doctors</div>
      <button className="btn btn-sm btn-primary self-center mr-4
                 hover:bg-[#3d3b9c] duration-300
                 rounded-lg font-inter font-[700] text-[14px] text-[#FFF]" 
                 onClick={(e) => {document.getElementById("add_dr_modal").showModal();e.preventDefault()}}>
                  <AddSVG />
                  Add Doctor
      </button>
            <dialog id="add_dr_modal" className="modal duration-0">
                <AddNewDoctor formSubmitted={formSubmitted} setFormSubmitted={setFormSubmitted} />
                <form method="dialog" className="modal-backdrop">
                  <button>close</button>
                </form>
            </dialog>  
        </div>
        <div className="flex flex-row p-6 bg-[#F9F9FC] shadow-sm">
          <div htmlFor="my-drawer-2" className="w-20 text-[14px]">
           ID
          </div>
           <div htmlFor="my-drawer-2" className="w-60 text-[14px]">
            First Name 
           </div>
           <div htmlFor="my-drawer-2" className="w-60 text-[14px]">
             Middle Name
           </div>
           <div htmlFor="my-drawer-2" className="w-60 text-[14px]">
             Last Name
           </div>   
          </div>
            <div className="flex flex-col min-h-[300px] max-h-[350px] top-5 box-border">
            {!!doctorData &&
             paginate(doctorData, pageNumber).map((e) => (
            <div className='flex flex-row mt-2 pb-3 pl-6 border-b box-border' key={e.id}>
                <div className="p-2 w-20 text-[14px]">{e.id}</div>
                <div className="p-2 w-60 text-[14px]"> {e.doctor_first}</div>
                <div className="p-2 w-60 text-[14px]">{e.doctor_middle} </div>
                <div className="p-2 w-60 text-[14px]">{e.doctor_last}</div>
            </div>
            ))}
            </div>
            <div className="flex btn-group mt-5 justify-end mr-5">
            <button className="w-8 h-8 min-w-8 rounded-lg bg-[#DEDEFA]" onClick={pageNumber === 1 ? null : () => setPageNumber(pageNumber - 1)}>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" transform='translate(5)'>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M12.6726 4.4107C12.998 4.73614 12.998 5.26378 12.6726 5.58921L8.26183 9.99996L12.6726 14.4107C12.998 14.7361 12.998 15.2638 12.6726 15.5892C12.3471 15.9147 11.8195 15.9147 11.4941 15.5892L6.19943 10.2946C6.03671 10.1319 6.03671 9.86805 6.19943 9.70533L11.4941 4.4107C11.8195 4.08527 12.3471 4.08527 12.6726 4.4107Z" fill="#5C59E8"/>
              </svg>
            </button>
            <button className="w-8 h-8 min-w-8 rounded-lg bg-[#DEDEFA] ml-2 mr-2 text-[#5C59E8] cursor-default">{pageNumber}</button>
            <button className="w-8 h-8 min-w-8 rounded-lg bg-[#DEDEFA]" onClick={() => setPageNumber(pageNumber + 1)}>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" transform='translate(5) rotate(180)'>
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M12.6726 4.4107C12.998 4.73614 12.998 5.26378 12.6726 5.58921L8.26183 9.99996L12.6726 14.4107C12.998 14.7361 12.998 15.2638 12.6726 15.5892C12.3471 15.9147 11.8195 15.9147 11.4941 15.5892L6.19943 10.2946C6.03671 10.1319 6.03671 9.86805 6.19943 9.70533L11.4941 4.4107C11.8195 4.08527 12.3471 4.08527 12.6726 4.4107Z" fill="#5C59E8"/>
              </svg>
            </button>
            </div>
        </div>
    )
}

export default DoctorTable