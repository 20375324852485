import { Navigate, Outlet } from "react-router-dom";
import { useContext, useState, useEffect } from 'react';
import { useAuthStatus } from "../hooks/useAuthStatus";
import axios from 'axios';
import AuthContext from "../contexts/AuthContext";
import Navbar from "./Navbar";
import Menu from './Menu';

const AdminPrivateRoute = () => {
  const { loggedIn, checkingStatus } = useAuthStatus();
  const { auth } = useContext(AuthContext);
  const  [ view, setView ] = useState(false)
  
  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(`/api/users/${auth.id}`);
        setView(response.data.admin);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [auth]);

  if (checkingStatus) {
    return <div>Spinner</div>
  }

  return (loggedIn && view ? (
    <div className="flex flex-row min-h-[100vh] overflow-auto bg-[#F9F9FC]">
      <Navbar />
      <div className="flex flex-col w-full">
        <Menu />
        <Outlet />
      </div>
    </div>
  ) : (
    <></>
  ));
};

export default AdminPrivateRoute;
