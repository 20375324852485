import React, { useState } from 'react'

function AdminCasesCard({ receivedCases, newCases, deliveredCases, cases, setCurrentCases }) {
  const [ newButton , setNewButton ] = useState(false)
  const [ pendingButton , setPendingButton ] = useState(false)
  const [ deliveredButton , setDeliveredButton ] = useState(false)

  const handleNewClick = () => {
    if(newButton){
      setNewButton(false)
      setCurrentCases(cases)
    }else {
      setNewButton(true)
      setPendingButton(false)
      setDeliveredButton(false)
      setCurrentCases(newCases)
    }
  }

  const handlePendingClick = () => {

    if(pendingButton){
      setPendingButton(false)
      setCurrentCases(cases)
    }else {
      setPendingButton(true)
      setNewButton(false)
      setDeliveredButton(false)
      setCurrentCases(receivedCases)
    }
  }

  const handleDeliveredClick = () => {

    if(deliveredButton){
      setDeliveredButton(false)
      setCurrentCases(cases)
    }else {
      setDeliveredButton(true)
      setNewButton(false)
      setPendingButton(false)
      setCurrentCases(deliveredCases)
    }
  }

  return (
    <div className='flex flex-row gap-4 mx-4'>
    <div 
    onClick={handleNewClick}
    className={`card w-1/3 bg-base-100 shadow-xl mb-10 bg-green-300 hover:cursor-pointer 
    text-green-800 hover:border-4 hover:border-green-800 hover:bg-green-500 border-4 
    ${newButton ? 'border-green-800' : 'border-transparent'}`}>
        <div className="card-body">
            <h1 className="card-title">{newCases.length} New Cases</h1>
        </div>
    </div>
    <div 
     onClick={handlePendingClick}
    className={`card w-1/3 bg-base-100 shadow-xl mb-10 bg-blue-300 hover:cursor-pointer
     text-blue-800 hover:border-4 hover:border-blue-800 hover:bg-blue-500 border-4 
     ${pendingButton ? 'border-blue-800' : 'border-transparent'}`}>
        <div className="card-body">
            <h1 className="card-title">{receivedCases.length} Received Cases</h1>
        </div>
    </div>
    <div 
    onClick={handleDeliveredClick}
    className={`card w-1/3 bg-base-100 shadow-xl mb-10 bg-purple-300 hover:cursor-pointer
     text-purple-800 hover:border-4 hover:border-purple-800 hover:bg-purple-500 border-4 
     ${deliveredButton ? 'border-purple-800' : 'border-transparent'}`}>
        <div className="card-body">
            <h1 className="card-title">{deliveredCases.length} Delivery Cases</h1>
        </div>
    </div>
    </div>
  )
}

export default AdminCasesCard