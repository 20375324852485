import React, { useState, useEffect } from 'react'
import axios from 'axios';
import StatementModal from '../components/StatementModal';

function Billing() {
  const [ query, setQuery ] = useState("")
  const [ invoices, setInvoices ] = useState([]);

  const keys = ["value"]
  
  const search = (data) => {
    return data.filter((item) => keys.some((key) => item.custom_fields?.[0][key]?.includes(query) || item.custom_fields?.[0][key]?.toLowerCase().includes(query)))
  }

  useEffect(() => {
    async function fetchData() {
      try {
        const res = await axios.get("/api/invoices"); 
        if (res?.data.data === undefined || res?.data.data === null) {
          setInvoices([]);

        }else {
          setInvoices(res?.data?.data);
          console.log(res?.data?.data)
        }
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  
  return (
    <div className="flex flex-col p-6 gap-6">
      <div className="text-xl drop-shadow-lg text-[#333843]">
        Billing & Payment
      </div>
      <label className='relative focus-within:text-gray-500 block'>
      <svg className='pointer-events-none w-8 h-8 absolute top-1/2 transform -translate-y-1/2 left-3' width="20" height="20" viewBox="0 -2 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M14.7847 16.1991C11.6462 18.6416 7.10654 18.4205 4.22181 15.5358C1.09761 12.4116 1.09761 7.34625 4.22181 4.22205C7.346 1.09786 12.4113 1.09786 15.5355 4.22205C18.4202 7.10677 18.6414 11.6464 16.1989 14.7849L20.4853 19.0713C20.8758 19.4618 20.8758 20.095 20.4853 20.4855C20.0948 20.876 19.4616 20.876 19.0711 20.4855L14.7847 16.1991ZM5.63602 14.1215C7.97917 16.4647 11.7782 16.4647 14.1213 14.1215C16.4644 11.7784 16.4644 7.97941 14.1213 5.63627C11.7782 3.29312 7.97917 3.29312 5.63602 5.63627C3.29288 7.97941 3.29288 11.7784 5.63602 14.1215Z" fill="#667085"/>
      </svg>
         <input 
         type="text"
         placeholder='Search Statement...' 
         className="input input-bordered bg-[#FFFFFF] w-2/5 border border-[#E0E2E7] box-border pt-2 pb-2 pr-3 block pl-12 focus:outline-none"
         onChange={(e) => setQuery(e.target.value)} />

      </label>
    <div className="flex flex-col bg-white min-h-[800px] rounded-lg border border-[#E0E2E7] overflow-auto">
      <div className="flex flex-row p-6 w-full bg-[#F9F9FC] border-b border-[#F9F9FC]">
        <div htmlFor="my-drawer-2" className="drawer-overlay w-80 text-base">
         Statement
        </div>
        <div htmlFor="my-drawer-2" className="flex drawer-overlay w-96 text-base">
          Due Date
        </div>
        <div htmlFor="my-drawer-2" className="flex drawer-overlay w-52 text-base">
          Status
        </div>
      </div>
      <div className="flex flex-row p-6 w-full top-5">
      <tbody>
            {search(invoices).map((invoice, idx) => (
               <div key={invoice.id} className='border-b border-[#F9F9FC] mb-4 pb-4 text-base'>
                <tr className='p-2'>
                 <td className='w-80'>{invoice.custom_fields 
                 ? invoice.custom_fields[0]['value'] : "Statement"}</td>
                 <td className='w-96 ml-5 text-base'>{new Date(invoice.due_date * 1000).toLocaleString("en-US", {
                    weekday: "long",
                    day: "numeric",
                    month: "long",
                    year: "numeric"
                  })}</td>
                  <td className='w-52 ml-5'>
                 <button className={`btn btn-${invoice.status.toLowerCase() === "paid" 
                 ? 'success text-[#3DA172] bg-[#E7F4EE] hover:bg-[#E7F4EE]' : invoice.status.toLowerCase() === "open"
                  ? ' text-[#E46A11] bg-[#FDF1E8] hover:bg-[#FDF1E8]' : 'info text-[#13B2E4] bg-[#E8F8FD] hover:bg-[#E8F8FD]'} text-base border-0 btn-sm no-animation cursor-default`}>{invoice.status}</button>
                 </td>
                 <td className='w-64'>
                  {/* <button onClick={()=> {console.log(idx);document.getElementById(`my_modal_billing_${idx}`).showModal()}}
                 className='btn btn-sm btn-primary bg-[#DEDEFA] text-sm text-[#5C59E8] hover:text-white border-none hover:border-none'>
                    Open Statement
                    </button> */}
                  <button className={
                    `btn btn-${invoice?.status?.toLowerCase() === "paid" ? 
                    "primary bg-[#E0E2E7] text-[#858D9D] btn-disabled" : invoice.status.toLowerCase() === "open" ?
                    "primary bg-[#DEDEFA] text-[#5C59E8] hover:text-white" : 
                    "primary bg-[#E0E2E7] text-[#858D9D] btn-disabled"} border border-[#F9F9FC] btn-md text-base`}>
                    <a href={invoice?.hosted_invoice_url} target="_blank" >{invoice?.status?.toLowerCase() === "open" ? 
                    "Pay Invoice" : invoice?.status?.toLowerCase() === "draft" ? 'Pending': 'Paid'}</a>
                </button>
                </td>
                </tr>
                <dialog id={`my_modal_billing_${idx}`} className="modal duration-0">
                 <StatementModal invoice={invoice}/>
                <form method="dialog" className="modal-backdrop">
                  <button>close</button>
                </form>
              </dialog>
                </div>
            ))}
        </tbody>
        </div>
    </div>
  </div>)
}

export default Billing