import React from 'react'

function BillingSVG({fill}) {
  return (
    <svg width="24" height="24" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M4 12C4 11.4477 4.44772 11 5 11H11C11.5523 11 12 11.4477 12 12C12 12.5523 11.5523 13 11 13H5C4.44772 13 4 12.5523 4 12Z" fill={fill}/>
<path d="M5 7.5C4.44772 7.5 4 7.94772 4 8.5C4 9.05229 4.44772 9.5 5 9.5H11C11.5523 9.5 12 9.05229 12 8.5C12 7.94772 11.5523 7.5 11 7.5H5Z" fill={fill}/>
<path d="M4 15.5C4 14.9477 4.44772 14.5 5 14.5H11C11.5523 14.5 12 14.9477 12 15.5C12 16.0523 11.5523 16.5 11 16.5H5C4.44772 16.5 4 16.0523 4 15.5Z" fill={fill}/>
<path fillRule="evenodd" clipRule="evenodd" d="M6 0C4.89543 0 4 0.89543 4 2L3 2C1.34315 2 0 3.34315 0 5V17C0 18.6569 1.34315 20 3 20H13C14.6569 20 16 18.6569 16 17V5C16 3.34315 14.6569 2 13 2H12C12 0.895431 11.1046 0 10 0H6ZM10 2H6V4H10V2ZM4 4H3C2.44772 4 2 4.44772 2 5V17C2 17.5523 2.44772 18 3 18H13C13.5523 18 14 17.5523 14 17V5C14 4.44772 13.5523 4 13 4H12C12 5.10457 11.1046 6 10 6H6C4.89543 6 4 5.10457 4 4Z" fill={fill}/>
</svg>

  )
}

export default BillingSVG