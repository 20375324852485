import React, { useState, useEffect } from 'react'

function GeneralInformation() {

  const  [ generalInformation, setGeneralInformation ] = useState({})

  useEffect(() => {
    setGeneralInformation(JSON.parse(localStorage.getItem('appState')));
  }, [])

  console.log()

  const cssStyle ='input input-bordered border-gray-300 input-sm w-full max-w-xs bg-defbg disabled:border-inherit disabled:bg-defbg'


  return (
        <div className='flex flex-col gap-3 ml-8 mr-8 mt-4'>
            <div className="grid grid-cols-2 gap-4">
                <div className="flex flex-col gap-2">
                    <label className="text-txt w-24 pl-2">Street</label>
                     <input value={generalInformation?.street} disabled={true} className={cssStyle} />
                    <label className="text-txt w-24 pl-2">State</label>
                     <input value={generalInformation?.state} disabled={true} className={cssStyle} />
                    <label className="text-txt w-24 pl-2">Phone</label>
                     <input value={generalInformation?.phone} disabled={true} className={cssStyle} />
                    </div>
                    <div className="flex flex-col gap-2">
                    <label className="text-txt w-24 pl-2">City</label>
                     <input value={generalInformation?.city} disabled={true} className={cssStyle} />
                    <label className="text-txt w-24 pl-2">Zipcode</label>
                     <input value={generalInformation?.zip} disabled={true} className={cssStyle} />
                    <label className="text-txt w-24 pl-2">Email</label>
                     <input value={generalInformation?.email} disabled={true} className={cssStyle} />
                    </div>
                </div>
        </div>
  )
}

export default GeneralInformation