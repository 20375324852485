import React from 'react'

function SubIconSVG() {
  return (
    <svg fill="#667085" width="20px" height="35px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg">
<path d="M23.070 21.82h-12.892v-12.892c0-0.69-0.56-1.25-1.25-1.25s-1.25 0.56-1.25 1.25v0 14.142c0 0.69 0.56 1.25 1.25 1.25h14.142c0.69 0 1.25-0.56 1.25-1.25s-0.56-1.25-1.25-1.25v0z"></path>
</svg>
    
  )
}

export default SubIconSVG