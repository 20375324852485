import React, {useState , useContext} from 'react';
import DatePicker from 'react-datepicker';
import CaseContext from '../contexts/CaseContext';

function convertUTCToLocalDate(date) {
  if (!date) {
    return date
  }
  date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
  return date
}

function convertLocalToUTCDate(date) {
  if (!date) {
    return date
  }
  date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
  return date
}

export default function UTCDatePicker({
  startDate,
  endDate,
  selected,
  onChange,
  isAdminPage,
  ...props
}) {

const { setIsRushCase } = useContext(CaseContext);

const isDateWithin10Days = (date) => {
  const today = new Date();
  const selected = new Date(date);
  const differenceInDays = Math.floor(selected - today) / (1000 * 60 * 60 * 24);
  return differenceInDays >= 0 && differenceInDays <= 10;
};
const isWeekday = (date) => {
  const day = date.getDay();
  return day !== 0 && day !== 6;
};

const handleDateChange = (date) => {
  if (isDateWithin10Days(date) && !isAdminPage) {
    document.getElementById("rush_case_modal").showModal();
    onChange(date);
    setIsRushCase(true);
    console.log("test")
  } else {
    onChange(date);
    setIsRushCase(false);
  }
}

return (
  <div>
    
    <DatePicker
      // startDate={convertLocalToUTCDate(startDate)}
      // endDate={convertLocalToUTCDate(endDate)}
      dateFormat="MM/dd/yyyy"
      selected={selected}
      onChange={date => handleDateChange(date)}
      minDate={new Date()}
      filterDate={isWeekday}
      {...props}
      />
      <>
        <dialog id="rush_case_modal" className="modal duration-0">
          <form method="dialog" className="modal-box bg-white">
            <h3>The date selected is within 10 business days which defines this as a rush case. 
                      There is an extra surchage of $100 for a rush case </h3>
            <div className="modal-action">
              <button className="btn btn-primary text-white">Close</button>
            </div>
           </form>
          <form method="dialog" className="modal-backdrop">
          <button>close</button>
          </form>
          </dialog>
        </>
      </div>
  )
}