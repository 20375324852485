import React, { useContext, useEffect, useState } from 'react'
import { Navigate, Outlet } from "react-router-dom";
import { useAuthStatus } from "../hooks/useAuthStatus";
import CaseContext from '../contexts/CaseContext';
import CaseModal from './CaseModal';
import Navbar from "./Navbar";
import Menu from './Menu';
import "./Project.css"

const PrivateRoute = () => {
  const { savedList, setSavedList } = useContext(CaseContext);
  const [ showInvoice, setShowInvoice ] = useState(false)
  const { loggedIn, checkingStatus } = useAuthStatus();

  useEffect(() => {
    // console.log(savedList, "list")
  }, [savedList]);

  if (checkingStatus) {
    return <div>Spinner</div>
  }

  const setDateAsUTC = (x) => {
    let date = new Date(Date.parse(x));
    return date.toLocaleString([], { dateStyle: 'short', timeStyle: 'short' })
  }

  const remFromArr = (x) => {
    // console.log(x)
    // console.log(savedList)
    setSavedList(prev => {
      let temp = [...prev]
      temp.splice(x,1)
      return temp
    })
  }
  

  return loggedIn ? (
    <div className="flex flex-row min-h-[100vh] bg-[#F9F9FC]">
      <Navbar />
      <div className="flex flex-col w-full">
        <Menu />
        <Outlet />
      </div>

      <div className="m-1 fixed bottom-0 right-0 border-[#E0E2E7]">
        <ul className="menu bg-white w-[280px] drop-shadow-lg rounded-lg border-primary bg-white w-[200px] text-[14px] text-[#667085] hover:bg-[#e6eefc] hover:text-[#fffff] duration-300">
          <li>
            <details open>
              <summary className="hover:bg-white font-inter font-[700] text-[16px]" >Saved Cases</summary>
              <ul className='ml-0 p-0'>
                {!!savedList &&
                  savedList.map((e,i) => (
                    <>
                    <li key={i} className='hover:bg-white hover:rounded-lg duration-300'
                    onClick={() => document.getElementById(`my_modal_dash_${i}`).showModal()}
                    >
                      <div href="" className="text-txt text-md">
                        <div className="flex flex-col w-full">
                          <div>
                            {`Dr. ${e.doctor.doctor_first} ${e.doctor.doctor_last}`}  
                          </div>
                          <div>
                            {e.patient_first} {e.patient_last}
                          </div>
                          <div>
                            {setDateAsUTC(e.return_date)}
                          </div>
                        </div>
                        <button
                          style={{zIndex:10}}
                          className='absolute right-[5px] btn btn-square btn-xs'
                          onClick={(e) => {e.stopPropagation();remFromArr(i)}}
                        >
                          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="red"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" /></svg>
                        </button>
                      </div>
                    </li>
                    <dialog id={`my_modal_dash_${i}`} className="modal duration-0">
                      <CaseModal caseInfo={e} showInvoice={showInvoice} setShowInvoice={setShowInvoice} />
                      <form method="dialog" className="modal-backdrop">
                          <button>Close</button>                    
                      </form>
                    </dialog>
                    </>
                  ))
                  
                }
              </ul>
            </details>
          </li>
        </ul>
      </div>
    </div>
  ) : (
    <Navigate to="/login" />
  );
};

export default PrivateRoute;
