import React, { useEffect, useState } from 'react'
import { NavLink } from "react-router-dom";
import axios from 'axios'
import CaseModal from '../../components/CaseModal';
import AdminCasesCard from './AdminCasesCard';


function Admin() {
    const [ cases, setCases ] = useState([])
    const [ receivedCases , setReceivedCases ] = useState([])
    const [ newCases, setNewCases ] = useState([])
    const [ deliveredCases, setDeliveredCases ] = useState([])
    const [ isPatientFilter , setIsPatientFilter] = useState(true)

    const [ currentCases, setCurrentCases ] = useState([])

    const [ query, setQuery ] = useState("")
    const [loading, setLoading] = useState(false);

    const currentDate = new Date();
    const currentMonth = currentDate.toLocaleString('default', { month: 'long' });
    const currentYear = currentDate.getFullYear();
    const output = `${currentMonth} ${currentYear}`;

    const headers = {
      headers: {
        "Content-Type": "application/json",
      },
    }

    const changeTime = (time) => {
      const dateObj = new Date(time);

      // Extract the date and time components
      const year = dateObj.getFullYear();
      const month = ("0" + (dateObj.getMonth() + 1)).slice(-2); // Adding 1 because getMonth() returns zero-based month
      const day = ("0" + dateObj.getDate()).slice(-2);
      let hours = dateObj.getHours();
      const minutes = ("0" + dateObj.getMinutes()).slice(-2);
      
      // Determine AM or PM
      const period = hours >= 12 ? "PM" : "AM";
    
      // Convert hours to 12-hour format
      hours = hours % 12;
      if (hours === 0) {
        hours = 12; // 12:00 AM instead of 0:00 AM
      }
      
      // Construct the output string
      const outputString = `${year}-${month}-${day} ${hours}:${minutes} ${period}`;
      return outputString;
    }

    
    function postInvoice(output) {
      return new Promise(async (resolve, reject) => {
        try {
          const response_invoice = await axios.post('/api/invoices/',          
          { 
            statement: output
          },
          headers);
    
          // Assuming that you want to resolve with the response data
          console.log(response_invoice, 'postInvoice')
          resolve(response_invoice.data);
        } catch (error) {
          reject(error);
        }
      });
    }

    function postInvoiceSearch(output) {
      return new Promise(async (resolve, reject) => {
        try {
          const response_invoice = await axios.post('/api/invoices/search', { statement: output });
    
          // Assuming that you want to resolve with the response data
          resolve(response_invoice);
        } catch (error) {
          reject(error);
        }
      });
    }

    function postInvoiceItem(item, e, response_invoice) {
      return new Promise(async (resolve, reject) => {
        // Simulate an API call that uses data from the first call
        try {
          console.log(e, 'e');
          console.log(response_invoice, 'response_invoice')
          console.log(item, 'item0')
          const response_item = await axios.post('/api/invoices/item', {
            price_id: item.price_id,
            invoice_id: e.invoice_id || response_invoice.id,
            id: e.id,
            quantity: item.teethArr.length
          }, {
            headers: {
              "Content-Type": "application/json",
            },
          });
    
          // Assuming that you want to resolve with the response data
          console.log(response_item);
          resolve(response_item.data);
        } catch (error) {
          reject(error);
        }
      });
    }

    //keys to filter by
    const patient_keys = ["patient_first", "patient_last"]
    const doctor_keys = ["doctor_first", "doctor_last"]
  
    const searchAndSort = (data) => {
      // Filter the data based on the query
      const filteredData = data.filter((item) => 
      isPatientFilter ? 
      patient_keys.some((key) =>
          item[key]?.includes(query) || item[key]?.toLowerCase().includes(query)
        ) : 
        doctor_keys.some((key) =>
        item['doctor'][key]?.includes(query) || item['doctor'][key]?.toLowerCase().includes(query)
      )
      );
    
      // Sort the filtered data by the 'id' field in descending order
      filteredData.sort((a, b) => b['id'] - a['id']);
    
      return filteredData;
    };

    function convertLocalToUTCDate(date) {
      if (!date) {
        return date
      }
      date = new Date(date)
      date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
      return date
    }

    useEffect(() => {
        async function fetchData() {
          try {
            const response = await axios.get("/api/users/all/cases");
            setCases(response.data.cases);
            const responseNew = await axios.get("/api/users/new/cases");
            setNewCases(responseNew.data.cases);
            const responseReceived = await axios.get("/api/users/received/cases");
            setReceivedCases(responseReceived.data.cases);
            const responseDelivered = await axios.get("/api/users/delivered/cases");
            setDeliveredCases(responseDelivered.data.cases);
            setCurrentCases(response.data.cases);
          } catch (error) {
            console.log(error);
          }
        }
        fetchData();
      }, [loading]);
      
      const handleFirstClick = async (e) => {
        console.log(e, 'e');
        let response_invoice;
        let itemsWithInvoice = [];
        const body = {  status: "In the Lab", receive_date: convertLocalToUTCDate(new Date())}
        const response_case = await axios.put(`/api/cases/${e.id}`, body, headers);
        console.log(response_case); 
        try {
          setLoading(true);
        if (e.invoice_id === null || e.invoice_id === undefined)  {
            postInvoiceSearch(output)
            .then((result)=> {
                if (result.data.data.length === 0){
                  return postInvoice(output);
                } else {
                  response_invoice = result.data.data[0];
                  console.log(response_invoice, "inside"); 
                  return response_invoice
                }
              })
            .then((invoice)=> {
              e.items.map(async (item) => {
                console.log(e, "overall object")
                if(item.price_id) {
                  console.log(item, 'item1')
                postInvoiceItem(item, e, invoice)
                .then(async (result) => {
                  console.log(result); 
                  itemsWithInvoice = [...itemsWithInvoice, {
                    ...item,
                    'invoice_item_id': result.id
                  }]
                  console.log(itemsWithInvoice, 'items with invoice')
                  const body = e.invoice_id == null ? 
                  {  status: "In the Lab", invoice_id: invoice.id, items: itemsWithInvoice} 
                  : {  status: "In the Lab", items: itemsWithInvoice}
                  const response_case = await axios.put(`/api/cases/${e.id}`, body, headers);
                  console.log(response_case); 
                })
              }
              })
            })
              .catch((error) => {
                console.error("Error: ", error)
          })
        } 
        const response = await axios.get("/api/users/all/cases");
        setCases(response.data.cases);
        } catch (error) {
          console.log(error);
        } finally {
          setLoading(false)
        }
      }

     const handleSecondClick = async (e) => {
      const today = new Date();
      let tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);
    
      // Check if tomorrow is a weekend (Saturday or Sunday)
      const dayOfWeek = tomorrow.getDay();
      if (dayOfWeek === 6) {
        // If it's Saturday, set to Monday
        tomorrow.setDate(tomorrow.getDate() + 2);
      } else if (dayOfWeek === 0) {
        // If it's Sunday, set to Monday
        tomorrow.setDate(tomorrow.getDate() + 1);
      }
      let month = tomorrow.getMonth() + 1; // Adding 1 because getMonth() returns zero-based month
      let day = tomorrow.getDate();
      const year = tomorrow.getFullYear();
      month = month < 10 ? '0' + month : month;
      day = day < 10 ? '0' + day : day;
      const tomorrowFormatted = `${month}/${day}/${year}`;
      try {
      setLoading(true);
      const body = {  status: "Delivery on " + tomorrowFormatted }
      const response_case = await axios.put(`/api/cases/${e.id}`, body, headers);

      console.log(response_case)
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
     } 
      

  return (
        <div className="card w-full bg-white mt-5 overflow-x-auto">
          <div>
            <AdminCasesCard receivedCases={receivedCases} newCases={newCases} deliveredCases={deliveredCases} cases={cases} setCurrentCases={setCurrentCases}/>
          </div>
          <div className='flex flex-row gap-2 ml-4'>
          <NavLink to='/admin-page/company' className='w-24'>
            <button className='btn btn-primary w-24'>
              Create Company
            </button>
          </NavLink>
          <NavLink to='/admin-page/user' className='w-24'>
            <button className='btn btn-primary w-24'>
              Create User
            </button>
          </NavLink>
          <NavLink to='/admin-page/case' className='w-24'>
            <button className='btn btn-primary w-24'>
              Change Status
            </button>
          </NavLink>
          </div>
          <div className='flex flex-row justify-start ml-4'>
            <label className="swap btn btn-primary mt-10 text-white">
              <input type="checkbox" onClick={() => {setIsPatientFilter(!isPatientFilter)}} />
               <div value="doctor" className="swap-on">Search by Doctor</div>
              <div value="patient" className="swap-off">Search by Patient</div>
              </label>
                <input 
                    type="text"   
                    placeholder={isPatientFilter ? "Search Patient Name" : "Search Doctor Name"}
                    className="input input-bordered w-4/5 mt-10 ml-2"
                    onChange={(e) => setQuery(e.target.value)} />
                    </div>
            <table className="table table-md">
                <thead>
                    <tr>
                        <th className='text-txt'>Case ID</th> 
                        <th className='text-txt'>Doctor </th> 
                        <th className='text-txt'>Patient Name</th> 
                        <th className='text-txt'>Return Date</th>
                        <th className='text-txt'>Status</th>
                        <th className='text-txt'>Created At</th>
                        <th className='text-txt'>View Case</th>
                        <th className='text-txt'>Verify</th> 
                    </tr>
                </thead> 
                <tbody>
                {searchAndSort(currentCases).map((e,idx) => (
                    <tr className='text-txt'>
                        <td>{e.id}</td> 
                        <td>
                            {`Dr. ${e.doctor.doctor_first} 
                            ${e.doctor.doctor_middle ? e.doctor.doctor_middle.charAt(0)+"." : ''} 
                            ${e.doctor.doctor_last}`}
                        </td>
                        <td>{e.patient_first + ' ' + e.patient_last}</td>
                        <td>{`${e.return_date.replace(/\s\d{2}:\d{2}:\d{2}\sGMT$/, '')}`}</td>
                        <td>{e.status}</td>
                        <td>{changeTime(e.created_on)}</td>
                        <td> 
                        <NavLink to={`/admin-page/case/${e.id}`} className='w-24'>
                        <button className='flex h-[40px] mx-2 px-2 py-2 items-center 
                        bg-[#5C59E8] hover:bg-[#3d3b9c] duration-300 rounded-lg font-inter 
                        font-[700] text-[14px] text-[#FFF]'>
                            View Case
                          </button>
                       </NavLink>
                        </td>
                        <td>
                    { loading ? 
                        <button className="h-[40px] mx-2 px-2 py-2 items-center bg-[#5C59E8] hover:bg-[#3d3b9c] duration-300 rounded-lg font-inter font-[700] text-[14px] text-[#FFF]">
                            <span className="loading loading-spinner"></span>
                        </button> : 
                        e.status.toLowerCase() === 'pick up requested' ? 
                        <button onClick={() =>handleFirstClick(e)} 
                            className="flex h-[40px] mx-2 px-2 py-2 items-center bg-[#5C59E8] hover:bg-[#3d3b9c] duration-300 rounded-lg font-inter font-[700] text-[14px] text-[#FFF]">
                            Receive Case
                        </button> :
                        <button onClick={() => handleSecondClick(e)} 
                            disabled={e.status.toLowerCase().startsWith('delivery') ? true : false}
                            className={e.status.toLowerCase().startsWith('delivery') ? "btn btn-outline mt-2 rounded-lg h-[40px] font-inter font-[700] text-[14px] text-[#FFF]":
                            "flex h-[40px] mx-2 px-2 py-2 items-center bg-[#5C59E8] hover:bg-[#3d3b9c] duration-300 rounded-lg font-inter font-[700] text-[14px] text-[#FFF]" 
                            }>
                            Delivering
                        </button> }
                        </td>
                    </tr>
                 ))}
                </tbody>
            </table>
        </div>
  )
}

export default Admin