import React, { useState, useContext } from "react";
import ImageGallery from "react-image-gallery";
import axios from 'axios'
import generatePDF from "./PdfCreator";
import DoctorIconSVG from "../svg/DoctorIconSVG";
import PatientIconSVG from "../svg/PatientIconSVG";
import "./image-gallery.css";
import "./svg_icons.css";
import ItemIconSVG from "../svg/ItemIconSVG";
import EditIconSVG from "../svg/EditIconSVG";
import StatusSVG from "../svg/StatusSVG";
import ItemPointSVG from "../svg/ItemPointSVG";
import DocIconSVG from "../svg/DocIconSVG";
import CaseContext from '../contexts/CaseContext';
// import 'react-dropzone-uploader/dist/styles.css';
// import Dropzone from 'react-dropzone-uploader';
import CarouselGallery from "./CarouselGallery";
import { previousDay } from "date-fns";
import AddListSVG from "../svg/AddListSVG";
import AddSVG from "../svg/AddIconSVG";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Pdftest from "../pages/Pdftest";

const logo = (
  <div className="flex px-2 py-4 flex-row items-center">
    <div className="pl-2">
      <svg
        width="34"
        height="34"
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M17 12.75C14.6528 12.75 12.75 14.6528 12.75 17V29.75C12.75 32.0972 14.6528 34 17 34C19.3472 34 21.25 32.0972 21.25 29.75V21.25H29.75C32.0972 21.25 34 19.3472 34 17C34 14.6528 32.0972 12.75 29.75 12.75H17Z"
          fill="#7D7AED"
        />
        <path
          d="M17 0C14.6528 0 12.75 1.90279 12.75 4.25V12.75H4.25C1.90279 12.75 0 14.6528 0 17C0 19.3472 1.90279 21.25 4.25 21.25H17C19.3472 21.25 21.25 19.3472 21.25 17V4.25C21.25 1.90279 19.3472 0 17 0Z"
          fill="#42C1E9"
        />
        <path
          d="M21.25 12.75H17C14.6528 12.75 12.75 14.6528 12.75 17V21.25H17C19.3472 21.25 21.25 19.3472 21.25 17V12.75Z"
          fill="#5C59E8"
        />
      </svg>
    </div>
    <div className="font-inter font-[700] text-[24px] text-[#333843] pl-5">
      Dentopia
    </div>
  </div>
);

function CaseModal({ caseInfo, showInvoice, setShowInvoice }) {

  const { savedList, setSavedList } = useContext(CaseContext);
  const [ editNote, setEditNote] = useState("false");

  const capitalize = (str) => {
    if(typeof str === "string"){
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }else if (Array.isArray(str)){
      return str.join(', ');
    }
  }

  const filtArr = [
    "type",
    "teethArr",
    "images",
    "price_id",
    "invoice_item_id",
    "notes",
    "note",
    "check",
    "img"
  ];

  const ordin = {
    0: "1st",
    1: "2nd",
    2: "3rd",
    3: "4th",
    4: "5th",
    5: "6th",
  };

  function replaceUnderscoresWithSpaces(inputString) {
    // Split the string into an array of words
    var words = inputString.split('_');

    // Capitalize the first letter of each word and convert underscores to spaces
    for (var i = 0; i < words.length; i++) {
        words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
    }

    // Join the words back together with spaces
    var resultString = words.join(' ');

    return resultString;
}


  const setDateAsUTC = (x) => {
    let date = new Date(Date.parse(x));
    return date.toLocaleString([], { dateStyle: "full", timeStyle: "short" });
  };

  // function generatePDF2() {
  //   // const pdfElement = document.getElementById("pdf").cloneNode(true);
  //   let doc
  //   html2canvas(document.querySelector("#pdf"), {
  //     scale: 1,
  //     background: "#FFFFFF"
  //   }).then((canvas) => {
  //     doc = new jsPDF();
  //     const imgdata = canvas.toDataURL("image/jpeg");
  //     doc.addImage(imgdata, "JPEG", 0, 0, 10, 10);
  //   }).then(res => {
  //     window.open(doc.output("bloburl"))
  //   })
  // }

  // function generatePDF2() {
  //   html2canvas(document.querySelector("#pdf"), {
  //     scale: 1,
  //     background: "#FFFFFF",
  //     onclone: function (unhideLogo) {
  //       unhideLogo.getElementById("logo").style.display = "flex";
  //       unhideLogo.getElementById("logo").style.flexDirection = "row";
  //       unhideLogo.getElementById("logo").style.alignItems = "center";
  //     },
  //   }).then((canvas) => {
  //     const imgdata = canvas.toDataURL("image/jpeg");
  //     const doc = new jsPDF();
  //     doc.addImage(imgdata, "JPEG", 10, 10);
  //     window.open(doc.output("bloburl"));
  //   });
  // }

  // window.devicePixelRatio = 2;

  const imageEditReturn = (newImage, i, prevList) => {
    console.log(newImage, i, prevList, "imgeditreturn")
    let newList = [...prevList]
    console.log(newList, "old")
    newList[i]['images'] = newImage.images.concat(newList[i]['images'])
    console.log(newList, "new")
    return newList
  }

  // const testBut = (e, caseInfo, itemIndex) => {
  //   e.stopPropagation()
  //   console.log(caseInfo, itemIndex, "test")
  // }

  const editImage = async (caseInfo, itemIndex) => {
    console.log(caseInfo, 'case info')
    let images = document.querySelector(`#imagesInput_c${caseInfo?.id}_i${itemIndex}`)
    console.log(images, "imgs")
    let formData
    if (!!images.files.length) {
      formData = new FormData();
      for (const img of images.files) {
          formData.append("image", img);
      }
      console.log(images, "imgs2")
      const res = await fetch('/api/cases/images', {
        method: "POST",
        body: formData,
      });

      if (res.ok) {
          let data = await res.json();
          const response_case = await axios.put(`/api/cases/${caseInfo?.id}`,
          {
            items: imageEditReturn(data, itemIndex, caseInfo?.items)
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          });
          console.log(response_case)
      }
      else {
        console.log('err')
      }
    }}


  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3 // optional, default to 1.
    }
  }

  return (
    <div className=" bg-white relative modal-box bg-white max-w-none w-[620px] text-[#333843] rounded-lg drop-shadow-lg p-4">
      <div>
        <div id="pdf"  className="flex flex-row relative items-center hidden">
        {/* <div id="pdf"  className="flex px-2 py-4 flex-row items-center invisible h-0 w-0"> */}
          <div className="flex flex-row">
          <div id="logo"  className="pl-2">
            <svg
              width="34"
              height="34"
              viewBox="0 0 34 34"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17 12.75C14.6528 12.75 12.75 14.6528 12.75 17V29.75C12.75 32.0972 14.6528 34 17 34C19.3472 34 21.25 32.0972 21.25 29.75V21.25H29.75C32.0972 21.25 34 19.3472 34 17C34 14.6528 32.0972 12.75 29.75 12.75H17Z"
                fill="#7D7AED"
              />
              <path
                d="M17 0C14.6528 0 12.75 1.90279 12.75 4.25V12.75H4.25C1.90279 12.75 0 14.6528 0 17C0 19.3472 1.90279 21.25 4.25 21.25H17C19.3472 21.25 21.25 19.3472 21.25 17V4.25C21.25 1.90279 19.3472 0 17 0Z"
                fill="#42C1E9"
              />
              <path
                d="M21.25 12.75H17C14.6528 12.75 12.75 14.6528 12.75 17V21.25H17C19.3472 21.25 21.25 19.3472 21.25 17V12.75Z"
                fill="#5C59E8"
              />
            </svg>
          </div>
          <div data-html2canvas-ignore="true" className="m-auto font-inter font-[700] text-[24px] text-[#333843] pl-5">
            Dentopia
          </div>
          </div>
        </div>
        <div
          data-html2canvas-ignore="true"
          className="flex flex-row absolute top-4 right-4 gap-2 h-[25px]"
        >
          <button className="cursor-pointer add-icon tooltip tooltip-bottom"
          data-tip="Save Case"
            onClick={() => setSavedList(prev => {return [...prev, caseInfo]})}
          >
            <AddListSVG />
          </button>
          <Pdftest action={() => console.log('PDF')} type={1} data={caseInfo}/>
          <form method="dialog">
          <button className="btn btn-circle btn-outline btn-error btn-xs top-0 right-0 h-2">
                    <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="hover:white" viewBox="0 0 24 24" stroke="currentColor"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" /></svg>
          </button>
          </form>
          {/* <button
            data-html2canvas-ignore="true"
            className={`btn btn-primary btn-sm bg-[#DEDEFA] text-[#5C59E8] hover:text-white border-0 hover:bg-[#5C59E8] duration-300 font-inter border-0 ${
              caseInfo.invoice_id === null ? "btn-disabled btn-outline" : ""
            }`}
            onClick={() => setShowInvoice(true)}
          >
            View Invoice
          </button> */}
        </div>

        <div className="flex flex-col gap-1 my-2 mx-1">
          <div className="font-bold text-xl">{`Case #${caseInfo?.id}`}</div>
          <div class="font-bold text-[14px] text-[#1A1C21]">Requested Return Date:</div>
          <div className="text-[14px] text-[#667085]">
            {`${caseInfo?.return_date?.replace(/\s\d{2}:\d{2}:\d{2}\sGMT$/, '')}`}
          </div>
        </div>
        <div className="flex flex-row gap-1 my-2 mx-1 content-center">
          <div
            className="gap-1 my-2 content-center"
            data-html2canvas-ignore="true"
          >
            <PatientIconSVG />
          </div>
          <div className="flex flex-col gap-1 m-2">
            <div className="font-bold text-[16px] text-[#1A1C21]">Patient</div>
            <div className="text-[14px] text-[#667085]">{`${capitalize(
              caseInfo?.patient_last
            )}, ${capitalize(caseInfo?.patient_first)}`}</div>
          </div>
        </div>
        <div className="flex" data-html2canvas-ignore="true">
          <div className="flex flex-row gap-1 my-2 mx-1 content-center">
            <div
              className="gap-1 my-2 content-center"
            >
              <StatusSVG />
            </div>
          </div>
          <div className="flex flex-col gap-1 m-2 ">
            <div className="font-bold text-[16px] text-[#1A1C21]">Status</div>
            <button className="btn btn-info btn-sm no-animation text-[#13B2E4] cursor-default border-none hover:bg-[#E8F8FD] hover:border-none">
              {capitalize(caseInfo?.status)}
            </button>
          </div>
        </div>
      
      <div className="flex flex-row gap-1 my-2 mx-1 content-center">
        <div
          className="gap-1 my-2 content-center"
          data-html2canvas-ignore="true"
        >
          <DoctorIconSVG />
        </div>
        <div className="flex flex-col gap-1 m-2">
          <div className="font-bold text-[16px] text-[#1A1C21]">Doctor</div>
          <div className="text-[14px] text-[#667085]">{`Dr. ${capitalize(
            caseInfo?.doctor?.doctor_first
          )} ${capitalize(caseInfo?.doctor?.doctor_last)}`}</div>
        </div>
      </div>
      <div className="flex flex-row gap-1 my-2 mx-1 content-center">
        <div
          className="gap-1 my-2 content-center"
          data-html2canvas-ignore="true"
        >
          <ItemIconSVG />
        </div>
        <div className="flex flex-col gap-1 m-2">
          <div className="font-bold text-[16px] text-[#1A1C21]">Items</div>
        </div>
      </div>
      <div className="flex justify-center">
      <div className="carousel rounded-box w-[558px]">
        {caseInfo?.items?.map((item, ind) => (
          <div id={`${caseInfo?.id}item${ind + 1}`} className="carousel-item flex flex-row gap-2 m-2">
            {/* <div
              className="gap-1 my-2 content-center"
              data-html2canvas-ignore="true"
            >
              <ItemPointSVG />
            </div> */}
            <div
              key={ind}
              className="flex flex-row content-center px-2 border-solid border-[#CBCBCB] border-[1px] rounded-lg p-2"
            >
              <div className="flex flex-col w-[180px] gap-1">
                <div className="my-1 text-[16px] text-[#5C59E8] font-bold">{`${ordin[ind]} Item`}</div>
                <div>
                  <div className="font-bold text-[14px] text-[#1A1C21]">Type</div>
                  <div className="text-[14px] text-[#667085]">{`${capitalize(
                    item["type"]
                  )}`}</div>
                </div>
                <div>
                  <div className="font-bold text-[14px] text-[#1A1C21]">
                    Teeth
                  </div>
                  <div className="text-[14px] text-[#667085] break-words">
                    {item["teethArr"].toString()}
                  </div>
                </div>
                {Object.keys(item)
                  .filter((s) => !filtArr.includes(s))
                  .map((j, ind2) => (
                    <div key={`item_${ind2}`}>
                      <div className="font-bold text-[14px] text-[#1A1C21]">
                        {replaceUnderscoresWithSpaces(j)}
                      </div>
                      <div className="text-[14px] text-[#667085]">
                        {capitalize(item[j])}
                      </div>
                    </div>
                  ))}
                <div>
                  <div className="font-bold text-[14px] text-[#1A1C21]">
                    Images
                  </div>
                  <div className="text-[14px] text-[#667085]">{`${
                    item.images && item["images"].length
                      ? item["images"].length
                      : "0"
                  } ${
                    item.images && item["images"].length == 1 ? "File" : "Files"
                  }`}</div>
                </div>
              </div>
              <div className="flex flex-col sticky w-[360px]">
                  {/* {!!item.images && (
                        <ImageGallery
                          showPlayButton={false}
                          showNav={false}
                          autoPlay={false}
                          disableThumbnailSwipe={true}
                          items={item["images"].map((e) => ({
                            original:e,
                            thumbnail: e
                          }))} />
                      )} */}
                  {
                    <Carousel 
                    responsive={responsive}
                    draggable={false}
                    keyBoardControl={true}
                    itemClass="cstmImg"
                    >
                      <div className="flex items-center gap-2 h-full w-full px-2 bg-[#f5f5ff]"
                        onClick={(e) => {e.stopPropagation();
                          document.getElementById(`imagesInput_c${caseInfo?.id}_i${ind}`).click()
                        }}
                      >
                        <AddSVG fill={'black'} size={'24'}/>
                        Add an Image
                        <label className='editUserimg'  style={{left:"15%"}}>
                        <input
                          onChange={() => editImage(caseInfo, ind)}
                          id={`imagesInput_c${caseInfo?.id}_i${ind}`}
                          className='imagesInput'
                          type="file"
                          accept="image/*"
                          style={{display:"none"}}
                          />
                          </label>
                      </div>
                      {!!item.images && item["images"].map((e) => (
                        <img src={e}>
                        </img>
                      ))}
                    </Carousel>
                    // <CarouselGallery imageList={item["images"]} />
                  }
                {!!item["notes"] && (
                  <div className="relative">
                    <div className="font-bold text-[14px] text-[#1A1C21]">
                      Notes
                      <div
                        className="absolute top-0 right-0 cursor-pointer edit-div2"
                        onClick={() => setEditNote("true")}
                      >
                        <EditIconSVG />
                      </div>
                    </div>
                    <div
                      className="text-[14px] text-[#667085] break-words"
                      contenteditable={editNote}
                    >
                      {item["notes"]}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      </div>
      <div className="flex justify-center gap-2">
        {caseInfo?.items?.map((_, ind) => (
        <a href={`#${caseInfo?.id}item${ind + 1}`} className="btn btn-xs btn-active">{ind + 1}</a>
        ))}
      </div>
    </div>
    </div>
  );
  
}
export default CaseModal;
