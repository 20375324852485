import React, { useState, useContext } from "react";
import axios from "axios";
import AuthContext from "../contexts/AuthContext";
import AddSVG from "../svg/AddIconSVG";

function AddNewDoctor({ formSubmitted, setFormSubmitted }) {
  const { auth } = useContext(AuthContext);

  const [newDoctorData, setNewDoctorData] = useState({
    doctor_first: "",
    doctor_middle: "",
    doctor_last: "",
  });

  const onChange = (e) => {
    setNewDoctorData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    console.log('test123');
    e.preventDefault();
    try {
      let res = await axios.post(
        "/api/doctors",
        {
          user_id: auth.id,
          doctor_first: newDoctorData.doctor_first,
          doctor_middle: newDoctorData.doctor_middle,
          doctor_last: newDoctorData.doctor_last,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (res) {
        console.log(res.data);
        setNewDoctorData({
          doctor_first: "",
          doctor_middle: "",
          doctor_last: "",
        });
        if(setFormSubmitted){
        setFormSubmitted(!formSubmitted);
        }
        document.getElementById("add_dr_modal").close();

      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="modal-box bg-white text-[#333843] rounded-lg">
      <div className="font-bold text-xl text-[#4D5464] mb-4">Add New Doctor</div>
      <form className="gap-4 " onSubmit={(e) => handleSubmit(e)}>
          <div className="flex flex-col flex-nowrap">
            <label className="flex text-txt pl-2 whitespace-nowrap">First Name</label>
            <input
              id="doctor_first"
              name="doctor_first"
              value={newDoctorData.doctor_first}
              onChange={onChange}
              required={true}
              className="input input-bordered border-gray-300 input-sm bg-defbg mt-2"
            />
          </div>
          <div className="flex flex-col">
            <label className="text-txt pl-2 whitespace-nowrap mt-2">Middle Name</label>
            <input
              id="doctor_middle"
              name="doctor_middle"
              value={newDoctorData.doctor_middle}
              onChange={onChange}
              className="input input-bordered border-gray-300 input-sm bg-defbg mt-2"
            />
          </div>
          <div className="flex flex-col">
            <label className="text-txt pl-2 whitespace-nowrap mt-2">Last Name</label>
            <input
              id="doctor_last"
              name="doctor_last"
              value={newDoctorData.doctor_last}
              required={true}
              onChange={onChange}
              className="input input-bordered border-gray-300 input-sm bg-defbg mt-2"
            />
          </div>
          <div className="flex btn-group w-full">
            <button type="submit" className="btn btn-primary min-w-8 rounded-lg mt-6 mr-2 text-white">
              <AddSVG />
              Add Doctor
            </button>
          </div>
      </form>
    </div>
  );
}

export default AddNewDoctor;
