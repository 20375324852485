import React, {useState, useContext, useRef, useEffect } from 'react'
import DentureList from './DentureList'
import CaseContext from '../contexts/CaseContext';
import ImageMapper from 'react-img-mapper';

function DentureCard() {
    const [ area, setArea] = useState(null)
    const [ arch , setArch] = useState({
      upper: false,
      lower: false
    })
    const [imageLoaded, setImageLoaded] = useState(false);

    const handleImageLoad = async () => {
      setImageLoaded(true);
    };

    const { caseDentureData, setCaseDentureData, dentureTeethInt, setDentureTeethInt} = useContext(CaseContext);
    const teethRef = useRef()
    teethRef.current = caseDentureData.teethArr
    //TODO: Add scenario for upper and lower but only have one selected
    const teethStatusHandler = (t) => {
      // console.log(t)
    if(teethRef.current.includes(t.name)) {
        setCaseDentureData(prev => ({
        ...prev,
        teethArr: prev.teethArr.filter(e => t.name !== e).sort(compareNumbers)
    }))
    setDentureTeethInt(prev => {
        let newObj = prev
        // console.log(newObj);
        delete newObj[t.name]["preFillColor"]
        return newObj
      })
    }//if user selects a tooth thats not selected
    else {
        // console.log("else", t.name)
        setCaseDentureData(prev => ({
          ...prev,
          teethArr: [...prev.teethArr, t.name].sort(compareNumbers)
      }))
      setDentureTeethInt(prev => ({...prev, [t.name]:{...prev[t.name], preFillColor: "rgb(91, 89, 232, 0.8)"}}))
      }
  }    
  const onClickUpperHandler = () => {
    setArch(prevState => ({
      upper: !prevState.upper, // Toggling the value of upper
      lower: false // Keeping lower false
    }))
    setCaseDentureData(prev => ({
      ...prev,
      arch: 'upper',
  }))
  }

  const onClickLowerHandler = () => {
    setArch(prevState => ({
      lower: !prevState.lower, 
      upper: false, 
    }))
    setCaseDentureData(prev => ({
      ...prev,
      arch: 'lower',
  }))
  }
      const compareNumbers = (a, b) => {
        return a - b;
      }

  return (
        <div className="flex bg-white flex-col p-x-6 pb-6 rounded-b-lg rounded-r-lg shadow-md items-center">
              <div id='teethImg' className='w-[869px] h-[353px]' style={{display:"inline-flex", flexDirection:"row",alignItems:"center",position:"relative", width:"max-content"}}>
              <ImageMapper src="./UNS_denture.jpg" map={{
                name: "my-map",
                areas: Object.values(dentureTeethInt)
              }}
              onMouseEnter={(area) => setArea(area) }
              onMouseLeave={() => setArea(null)}
              onClick={(area) => (teethStatusHandler(area))}
              onLoad={() => handleImageLoad(true)}
              width={869}
              />
              {imageLoaded && <>
               <button className={`btn btn-lg absolute z-50 top-[190px] left-[118px] text-[42px] 
                ${arch.upper ? `bg-[#5b59e8cc] border-none hover:bg-[#5b59e8cc] text-white` : `hover:bg-[#2d415fcc] hover:text-white` } ` }
                onClick={() => onClickUpperHandler()} >Upper</button>
              <button className={`btn btn-lg absolute z-50 top-[190px] right-[143px] text-[44px] 
                ${arch.lower ? `bg-[#5b59e8cc] border-none hover:bg-[#5b59e8cc] text-white` : `hover:bg-[#2d415fcc] hover:text-white` } ` }
                onClick={() => onClickLowerHandler()}>Lower</button>
                </>}
              {
                !!area && <div style={{position:"absolute",backgroundColor:"white", top: `0%`, left: `45%`,zIndex:5,color:"black",fontSize:"4rem" }} >
                  {area.name === 'upper' || area.name === 'lower' ? area.name[0].toUpperCase() : area.name}
                  </div>
              }
              </div>
              <DentureList setArch={setArch} />
        </div>
  )
}

export default DentureCard