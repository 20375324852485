import React, {useState} from 'react'
import CrownCard from './CrownCard';
import DentureCard from './DentureCard';
import ImplantCard from './ImplantCard';
import ImplantList from './ImplantList'

function ItemCard() {

  const [ itemSelected, setItemSelected ] = useState(1);

  return (
    <div>
        <div className="w-full">
            <div className="flex space-x-4 mb-4">
              <div className='text-xl'>Select Type: </div>
              <button className={`btn btn-sm text-white ${itemSelected === 1 ? "btn-primary" : 'btn-primary btn-outline'}`} onClick={() => setItemSelected(1)}>Crown</button> 
              <button className={`btn btn-sm text-white ${itemSelected === 2 ? "btn-primary" : 'btn-primary btn-outline'}`} onClick={() => setItemSelected(2)}> Dentures</button> 
              <button className={`btn btn-sm text-white ${itemSelected === 3 ? "btn-primary" : 'btn-primary btn-outline'}`} onClick={() => setItemSelected(3)}> Implant</button> 
            </div> 
          </div> 
        <div className="flex flex-col gap-6">
          {itemSelected === 1 ? <CrownCard /> : itemSelected === 2 ? <DentureCard /> : <ImplantCard /> }
          </div>
      </div>
  )
}

export default ItemCard