import React, { useState, useEffect } from "react";
import { Link, NavLink, json, useNavigate } from "react-router-dom";
import CaseModal from "../components/CaseModal";
import axios from "axios";
import RushCaseIconSVG from "../svg/RushCaseIconSVG";
import CrownIconSVG from "../svg/CrownIconSVG";
import DentureIconSVG from "../svg/DentureIconSVG";
import ImplantIconSVG from "../svg/ImplantIconSVG";
import EmptySpaceSVG from "../svg/EmptySpaceSVG";
import SearchIconSVG from "../svg/SearchIconSVG";
import ChevronSVG from "../svg/ChevronSVG";
import InvoiceModal from "../components/InvoiceModal";
import { AcroFormCheckBox } from "jspdf";
import "./sheet.css";

function Cases() {
  // const [caseData, setCaseData] = useState({cases: teststuff, total_pages:12});
  const [caseData, setCaseData] = useState({});
  const [docFilter, setDocFilter] = useState([]);
  const [dateFilter, setDateFilter] = useState([]);
  const [sorted, setSorted] = useState(3);
  const [sorted2, setSorted2] = useState(3);
  const [drSelected, setDrSelected] = useState("");
  const [patSelected, setPatSelected] = useState("");
  const [caseSelected, setCaseSelected] = useState("");
  const [doctorData, setDoctorData] = useState("");
  const [patientData, setPatientData] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [query, setQuery] = useState("");
  const [currSelected, setCurrSelected] = useState("");
  const [showInvoice, setShowInvoice] = useState(false);
  const [modeSelected, setModeSelected] = useState(true);
  const [search, setSearch] = useState("");
  const [currPage, setCurrPage] = useState('');
  const [currDr, setCurrDr] = useState('');

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get("/api/cases/main");
        setCaseData(response.data);
        const response2 = await axios.get("/api/users/current/doctors");
        setDoctorData(response2.data);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  const dataHandler = (obj) => {
    const dict = {};
    obj.forEach((e) => {
      if (Object.keys(dict).includes(`${e.patient_last}, ${e.patient_first}`)) {
        dict[`${e.patient_last}, ${e.patient_first}`].push(e);
      } else {
        dict[`${e.patient_last}, ${e.patient_first}`] = [e];
      }
    });
    return dict;
  };

  const handleClick = () => {
    setIsEditing(true);
  };

  const handleInputChange = (e) => {
    setQuery(e.target.value);
  };

  const handleInputBlur = () => {
    setIsEditing(false);
  };

  const handleSearch = async (page, query, doctor) => {
    let url = "/api/cases/main?"
    let qObj = {'page':page, 'qs':query, 'did':doctor}
    if(page || query || doctor){
      let sub = ''
      let count = 0
      let newArr = Object.keys(qObj)
      for(let x in newArr){
        if(qObj[newArr[x]]) {
          if(count === 0){
            sub = sub.concat(`${newArr[x]}=${qObj[newArr[x]]}`)
            count++
          }
          else {
            sub = sub.concat(`&${newArr[x]}=${qObj[newArr[x]]}`)
          }
        }
      }
      url = url.concat(sub)
      await axios
      .get(url)
        .then(function (response) {
          setCaseData(response.data);;
        })
        .catch(function (error) {
          if(error.data.error){
            setCaseData({})
          }
        });
    }
    else{
      const response = await axios.get(url);
      setCaseData(response.data);
    }
  };

  const docFilterButton = (e) => {
    let num = parseInt(e);
    setDocFilter((prev) => {
      if (prev.includes(num)) {
        return [...prev].filter((i) => i != num);
      } else {
        let addedList = [...prev];
        addedList.push(num);
        return addedList;
      }
    });
  };
  // sort data by patient's first name alphabeticaly
  const sortFunc1 = (data) => {
    data.sort((a, b) => a.patient_first.localeCompare(b.patient_first));
  };
  // sort data by patient's first name alphabeticaly, descending
  const sortFunc2 = (data) => {
    data.sort((a, b) => b.patient_first.localeCompare(a.patient_first));
  };
  //sort default, case ID desc
  const sortFunc3 = (data) => {
    data.sort((a, b) => b.id - a.id);
  };
  const sortFunc4 = (data) => {
    data.sort((a, b) => a.patient_last.localeCompare(b.patient_last));
  };
  // sort data by patient's last name alphabeticaly, descending
  const sortFunc5 = (data) => {
    data.sort((a, b) => b.patient_last.localeCompare(a.patient_last));
    //sort default, case ID desc
  };

  const genFilter = (data, docFilter, dateFilter) => {
    let filteredList = data;
    if (!!docFilter.length) {
      filteredList = filteredList.filter((e) => {
        return docFilter.includes(e.doctor_id);
      });
    }
    if (sorted && sorted2 == 3) {
      if (sorted == 1) {
        sortFunc1(filteredList);
      } else if (sorted == 2) {
        sortFunc2(filteredList);
      } else if (sorted == 3){
        sortFunc3(filteredList);
      }
    }
    if (sorted2 && sorted == 3) {
      if (sorted2 == 1) {
        sortFunc4(filteredList);
      } else if (sorted2 == 2) {
        sortFunc5(filteredList);
      } else if (sorted2 == 3) {
        sortFunc3(filteredList);
      }
    }
    return filteredList;
  };

  const remTime = (date) => {
    let newDate = new Date(date)
    newDate.setHours(0,0,0,0)
    const dateWOtime = newDate.toDateString()
    return dateWOtime
  }

  const drCheck = (dr) => {
    console.log(currDr, dr, 'dr')
    if(currDr !== dr){
      setCurrDr(dr)
      handleSearch(null, search, dr)
    }
    else {
      setCurrDr('')
      handleSearch(null, search, null)
    }
  }

  return (
    <div className="flex flex-col p-6 gap-6">
      <div className="flex flex-col bg-white h-[665px] xl:h-[865px] w-[1100px] xl:w-[1500px] rounded-lg drop-shadow-lg">
        <div className="relative flex flex-row items-center">
          <div className="flex py-4 px-[24px] gap-10">
            <label className="text-[18px] font-medium m-auto">Cases</label>
            <div className="flex gap-6 items-center">
              <input
                id="searchInput"
                type="text"
                className="input input-bordered bg-[#FFFFFF] border border-[#E0E2E7] box-border h-[40px] outline-none focus:outline-none"
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value);
                }}
                onKeyUp={(e) => {
                  if(e.key === "Enter") document.getElementById("searchButton").click();
                }}
                autoFocus
              />
              <button
                id="searchButton"
                className="btn btn-sm m-auto text-white btn-primary px-4 h-[40px]"
                onClick={() => {
                  handleSearch(null,search, currDr);
                }}
              >
                <SearchIconSVG fill={"white"}/>
                Find
              </button>
              <div className="flex flex-row">
                {!!doctorData &&
                  doctorData.doctors.map((e) => (
                    <div className="flex flex-row gap-2">
                      <div className="form-control">
                        <label className="label cursor-pointer gap-2">
                          <span className="label-text">{`Dr. ${e.doctor_first} ${
                          e.doctor_middle ? e.doctor_middle.charAt(0) + "." : ""
                        } ${e.doctor_last}`} </span>
                          <input
                            type="checkbox"
                            id="drfilter"
                            value={e.id}
                            checked={currDr === e.id}
                            onClick={() => drCheck(e.id)}
                            className="checkbox checkbox-primary"
                          />
                        </label>
                      </div>
                    </div>
                    
                  ))}
              </div>
              {/* <div className="flex flex-row">
                <div className="form-control">
                  <label className="label cursor-pointer">
                    <span className="label-text">dr2 </span>
                    <input
                      type="checkbox"
                      id="drfilter"
                      value={2}
                      onChange={(e) => docFilterButton(e.target.value)}
                      className="checkbox checkbox-primary"
                    />
                  </label>
                </div>
              </div> */}
            </div>
          </div>
          <Link
            to={"/new_case"}
            className="absolute right-2 flex h-[40px] mx-2 px-4 py-2 items-center bg-[#5C59E8] hover:bg-[#3d3b9c] duration-300 rounded-md font-inter font-[700] text-[14px] text-[#FFF]"
          >
            + New Case
          </Link>
        </div>
        
          <div>
            <div className="flex flex-row py-4 xl:py-6 px-3 w-full bg-[#F9F9FC]">
              <div
                htmlFor="my-drawer-2"
                className="flex shrink-0 box-border justify-center px-3 xl:px-6 drawer-overlay w-36 xl:w-40 text-sm xl:text-base"
              >
                Case ID#
              </div>
              <div
                htmlFor="my-drawer-2"
                className="flex flex-row shrink-0 px-3 xl:px-6 drawer-overlay w-[175px] xl:w-[200px] text-sm xl:text-base"
                onClick={() =>
                  {setSorted(3)
                  setSorted2((prev) => {
                    if (prev == 3) {
                      return 1;
                    } else {
                      return prev + 1;
                    }
                  })}
                }
              >
                Last Name
                <div className={`flex justify-center item-center ${sorted2 == 1 ? 'rotate down' : sorted2 == 2 ? 'rotate' : 'hidden'}`} >
                <ChevronSVG fill={'#121212'} />
                </div>
              </div>

              <div
                htmlFor="my-drawer-2"
                className="flex shrink-0 px-3 xl:px-6 drawer-overlay w-[175px] xl:w-[200px] text-sm xl:text-base"
                onClick={() =>
                  {
                    setSorted2(3)
                    setSorted((prev) => {
                    if (prev == 3) {
                      return 1;
                    } else {
                      return prev + 1;
                    }
                  })
                }
                }
              >
                First Name
                <div className={`flex justify-center item-center ${sorted == 1 ? 'rotate down' : sorted == 2 ? 'rotate' : 'hidden'}`} >
                <ChevronSVG fill={'#121212'}/>
                </div>
              </div>
              <div
                htmlFor="my-drawer-2"
                className="flex shrink-0 px-3 xl:px-6 drawer-overlay w-[175px] xl:w-[200px] text-sm xl:text-base"
              >
                Doctor
              </div>
              <div
                htmlFor="my-drawer-2"
                className="flex shrink-0 px-3 xl:px-6 drawer-overlay w-[28rem] text-sm xl:text-base"
              >
               Requested Return Date 
              </div>
            </div>
            <div></div>
          </div>
          <div className="flex flex-row py-4 xl:py-6 px-3 w-full">
        <ul id="caseUL" className="flex flex-col">
          {caseData.cases &&
            genFilter(caseData.cases, docFilter, dateFilter).map((e, idx) => (
              <li
              onClick={() => {
                setCaseSelected(e);
                document
                  .getElementById(`my_modal_case_${idx}`)
                  .showModal();
              }}
              >
                <a
                  className={`flex flex-row rounded-lg items-center text-txt hover:cursor-pointer text-sm xl:text-base h-11 xl:h-12 hover:bg-[#f5f5ff]${
                    caseSelected == e ? " bg-[#DEDEFA]" : ""
                  }`}
                >
                  <div className="flex px-3 xl:px-6 justify-center w-36 xl:w-40">{e.id}</div>
                  <div className="flex px-3 xl:px-6 w-[175px] xl:w-[200px]">{e.patient_last}</div>
                  <div className="flex px-3 xl:px-6 w-[175px] xl:w-[200px]">{e.patient_first}</div>
                  <div className="flex px-3 xl:px-6 w-[175px] xl:w-[200px]">
                    {`Dr. ${e.doctor.doctor_first} ${
                      e.doctor.doctor_middle
                        ? e.doctor.doctor_middle.charAt(0) + "."
                        : ""
                    } ${e.doctor.doctor_last}`}
                  </div>
                  <div>
          
                      <div
                      >
                        <div className="flex gap-1 px-3 xl:px-6 w-120">
                          {e.is_rush_case ? (
                            <RushCaseIconSVG />
                          ) : (
                            <EmptySpaceSVG />
                          )}
                          {/* {`Return Date: ${e.return_date.replace(
                            / 00:00:00 GMT$/,
                            ""
                          )}`} */}
                          {
                            ` ${remTime(e.return_date)} `
                          }
                          {e.items.some((obj) => obj.type === "crown") ? (
                            <CrownIconSVG />
                          ) : (
                            <EmptySpaceSVG />
                          )}
                          {e.items.some((obj) => obj.type === "denture") ? (
                            <DentureIconSVG />
                          ) : (
                            <EmptySpaceSVG />
                          )}
                          {e.items.some((obj) => obj.type === "implant") ? (
                            <ImplantIconSVG />
                          ) : (
                            <EmptySpaceSVG />
                          )}
                        </div>
                      </div>
                      <dialog
                        id={`my_modal_case_${idx}`}
                        className="modal duration-0"
                      >
                        {showInvoice ? (
                          // <InvoiceModal
                          //   caseInfo={e}
                          //   setShowInvoice={setShowInvoice}
                          // />
                          <></>
                        ) : (
                          <CaseModal
                            caseInfo={e}
                            setShowInvoice={setShowInvoice}
                          />
                        )}
                        {/* NOTE: Commenting this out til we find out what they want us to do with rush cases*/}
                        <form method="dialog" className="modal-backdrop">
                          <button onClick={() => setShowInvoice(false)}>
                            close
                          </button>
                        </form>
                      </dialog>
                   
                  </div>
                </a>
              </li>
            ))}
        </ul>
        </div>
        <div className="absolute join flex w-full justify-center bottom-4">
          {/* {
            Array.from({length: 4}).map((e,i) => (
              <input className="join-item btn btn-square" type="radio" name="options" aria-label={i + 1} checked />
            ))
          } */}
          {
            !!caseData.total_pages && Array.from({length: caseData.total_pages}).map((e,i) => (
              <input
                className="join-item btn btn-square btn-sm"
                type="radio"
                name="options"
                aria-label={i + 1}
                checked={caseData.page === (i + 1)}
                onClick={() => handleSearch((i + 1), search, currDr)}
              />
            ))
          }
        </div>
      </div>
    </div>
  );
}
export default Cases;
