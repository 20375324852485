import React from "react";
import AuthContext from "../contexts/AuthContext";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import ProfileSVG from "../svg/ProfileSVG";

function Navbar() {
  const { auth, setAuth } = useContext(AuthContext);
  const navigate = useNavigate();

  const checkAndCloseDropDown = (e) => {
    let targetEl = e.currentTarget;
    if (targetEl && targetEl.matches(":focus")) {
      setTimeout(function () {
        targetEl.blur();
      }, 0);
    }
  };

  const handleClick = () => {
    localStorage.removeItem("appState");
    setAuth({});
    navigate("/login");
  };

  return (
    <div
      name="navbar"
      className="flex flex-row relative shrink-0 bg-slate-400 w-full h-16 px-4 py-4 bg-white"
    >
      <div className="absolute flex flex-row mx-4 left-[910px] xl:left-[1300px]">
        <div className="dropdown">
          <label
            tabIndex={0}
            className="flex flex-row m-1 mr-6 cursor-pointer gap-2"
            onMouseDown={(e) => checkAndCloseDropDown(e)}
          >
            <div className="outline outline-2 outline-[#858D9D] rounded-full">
              <ProfileSVG />
            </div>
            <div className="ml-2 text-[#858D9D] text-[14px]">{auth.email}</div>
            <svg
              width="24"
              height="24"
              viewBox="-8 -8 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M13.7071 0.292893C13.3166 -0.097631 12.6834 -0.097631 12.2929 0.292893L7 5.58579L1.70711 0.292893C1.31658 -0.097631 0.683418 -0.097631 0.292894 0.292893C-0.0976312 0.683417 -0.0976312 1.31658 0.292894 1.70711L6.64645 8.06066C6.84171 8.25592 7.15829 8.25592 7.35355 8.06066L13.7071 1.70711C14.0976 1.31658 14.0976 0.683417 13.7071 0.292893Z"
                fill="#858D9D"
              />
            </svg>
          </label>
          <ul
            tabIndex={0}
            className="dropdown-content z-[20] menu p-2"
          >
            <li className="bg-white hover:bg-[#DEDEFA] shadow w-50 h-10 rounded-t my-auto px-5">
              <a
                href="https://torpid-sandwich-e4c.notion.site/Getting-Started-0237ddc80ffc43ba910e1ffdb69d3445?pvs=4"
                target="_blank"
              >
                <span className="text-sm font-medium text-[#667085]">
                  Documentation
                </span>
              </a>
            </li>
            <li
              className="bg-white hover:bg-[#DEDEFA] shadow w-50 h-10 rounded-b my-auto px-5"
              onClick={() => document.getElementById("my_modal_1").showModal()}
            >
              <a>
                <span className="text-sm font-medium text-[#667085]">
                  Logout
                </span>
              </a>
            </li>
            <dialog id="my_modal_1" className="modal duration-0">
              <div className="modal-box bg-white">
                <h3 className="font-bold text-lg">
                  Are you sure you want to log out?
                </h3>
                <div className="modal-action">
                  <form method="dialog">
                    <button
                      className="btn btn-outline btn-primary"
                      onClick={handleClick}
                    >
                      Confirm
                    </button>
                    <button className="btn btn-primary ml-3">Cancel</button>
                  </form>
                </div>
              </div>
            </dialog>
          </ul>
        </div>
      </div>
    </div>
  );
}
export default Navbar;
