import React, { useContext, useState } from "react";
import CaseContext from "../contexts/CaseContext";
import ChevronRightSVG from "../svg/ChevronRightSVG";
import { IMPLANT_LIST, TEETH_INT } from "../constants";
import { domToDataUrl } from "modern-screenshot";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Project.css";

function ImplantList() {
  const {
    caseImplantData,
    setCaseImplantData,
    setCaseList,
    setImplantTeethInt,
    implantsList,
    setImplantsList,
  } = useContext(CaseContext);
  const [provided, setIsProvided] = useState(false);

  const [notes, setNotes] = useState("");

  const [categoryCrowns, setCategoryCrowns] = useState("");
  const [subCategoryCrowns, setSubCategoryCrowns] = useState("");

  const [categoryAbutment, setCategoryAbutment] = useState("");
  const [subCategoryAbutment, setSubCategoryAbutment] = useState("");
  const [subSubCategoryAbutment, setSubSubCategoryAbutment] = useState("");

  let showOptionForOther;

  if (categoryAbutment.toLowerCase() === "other") {
    showOptionForOther = "third-party";
  } else {
    showOptionForOther = subCategoryAbutment;
  }

  const handleIsProvided = (e) => {
    setIsProvided(!provided);
    setCaseImplantData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.checked,
    }));
  };

  const handleCategoryCrownsChange = (e) => {
    setCategoryCrowns(e.target.value);
    setCaseImplantData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
    setSubCategoryCrowns("");
  };

  const handleSubCategoryCrownsChange = (e) => {
    setSubCategoryCrowns(e.target.value);
    setCaseImplantData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const handleCategoryAbutmentChange = (e) => {
    setCategoryAbutment(e.target.value);
    setCaseImplantData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
    setSubCategoryAbutment("");
    setSubSubCategoryAbutment("");
  };

  const handleSubCategoryAbutmentChange = (e) => {
    setSubCategoryAbutment(e.target.value);
    setCaseImplantData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
    setSubSubCategoryAbutment("");
  };

  const handleSubSubCategoryAbutmentChange = (e) => {
    setSubSubCategoryAbutment(e.target.value);
    setCaseImplantData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const handleCaseNotesChange = (e) => {
    setNotes(e.target.value);
    setCaseImplantData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;

    switch (name) {
      case "incisal":
        setCaseImplantData((prev) => ({
          ...prev,
          incisal: value,
        }));
        break;
      case "body":
        setCaseImplantData((prev) => ({
          ...prev,
          body: value,
        }));
        break;
      case "cervical":
        setCaseImplantData((prev) => ({
          ...prev,
          cervical: value,
        }));
        break;
      default:
        break;
    }
  };

  const onClick = async (e) => {
    e.preventDefault();
    const target = document.getElementById("teethImg");
    const target2 = document.getElementById("implantChecks");
    let updatedState = { ...caseImplantData };
    await domToDataUrl(target, {
      quality: 1,
      scale: 2,
    }).then(async (data) => {
      await domToDataUrl(target2, {
        quality: 1,
        scale: 2,
        onCloneNode: function (logo) {
          // logo.style.columns = '3'
          const addButton = logo.querySelector("#addButton");
          addButton.remove();
        },
      }).then((data2) => {
        updatedState = { ...updatedState, check: data2 };
      });
      updatedState = { ...updatedState, img: data };
      setCaseList((prevState) => [...prevState, updatedState]);
      setCaseImplantData({
        teethArr: [],
        body: "",
        incisal: "",
        cervical: "",
        type: "implant",
        provided_by_doctor: false,
      });
      setImplantTeethInt(TEETH_INT);
      setNotes("");
      setImplantsList(IMPLANT_LIST);
      setIsProvided(false);
      setCategoryCrowns("");
      setSubCategoryCrowns("");
      setCategoryAbutment("");
      setSubCategoryAbutment("");
      setSubSubCategoryAbutment("");
      toast.success("Implant Added", {
        className: "toast-message",
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        progress: undefined,
        theme: "light",
      });
    });
  };
  return (
    <>
      <div
        style={{
          display: "inline-flex",
          flexDirection: "row",
          alignItems: "center",
          position: "relative",
          width: "max-content",
        }}
      >
        <div className="flex flex-row gap-1 ml-3">
          <label htmlFor="" className="mt-0.5">
            Incisal
          </label>
          <input
            name="incisal"
            value={caseImplantData["incisal"]}
            onChange={(e) => handleInputChange(e)}
            className="input input-bordered border-gray-500 input-sm w-[130px] max-w-xs bg-defbg ml-1"
          />
          <label className="ml-4 mt-0.5" htmlFor="">
            Body
          </label>
          <input
            name="body"
            value={caseImplantData["body"]}
            onChange={(e) => handleInputChange(e)}
            className="input input-bordered border-gray-500 input-sm w-[130px] max-w-xs bg-defbg ml-1"
          />
          <label className="ml-4 mt-0.5" htmlFor="">
            Cervical
          </label>
          <input
            name="cervical"
            value={caseImplantData["cervical"]}
            onChange={(e) => handleInputChange(e)}
            className="input input-bordered border-gray-500 input-sm w-[130px] max-w-xs bg-defbg ml-1"
          />
        </div>
      </div>
      <div className="gap-1 w-4/5">
        <div className="label">
          <span className="label-text-alt">
            Please add shade details if needed
          </span>
        </div>
        <textarea
          id="notes"
          value={notes}
          onChange={(e) => handleCaseNotesChange(e)}
          className="textarea textarea-bordered border-gray-500 w-full m-x-2 h-[155px]"
          placeholder="Notes"
        ></textarea>
      </div>
      <div
        id="implantChecks"
        className="flex w-4/5 bg-white flex-col gap-1 mt-4"
      >
        <label className="text-txt text-lg mb-2">Implant Crown</label>
        <div className="flex flex-row justify-start">
          <select
            id="Crown Category 1"
            value={categoryCrowns}
            onChange={handleCategoryCrownsChange}
            className="select select-bordered border-gray-300 w-3/5 max-w-xs bg-defbg"
          >
            <option value="" disabled selected>
              Select Option
            </option>
            {Object.keys(IMPLANT_LIST["Implant Crowns"]).map((category) => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}
          </select>
          {categoryCrowns && (
            <>
              <ChevronRightSVG />
              <select
                id="Crown Category 2"
                value={subCategoryCrowns}
                onChange={handleSubCategoryCrownsChange}
                className="select select-bordered border-gray-300 w-3/5 max-w-xs bg-defbg"
              >
                <option value="" disabled selected>
                  Select Option
                </option>
                {Object.keys(
                  IMPLANT_LIST["Implant Crowns"][categoryCrowns]
                ).map((subCategory) => (
                  <option key={subCategory} value={subCategory}>
                    {subCategory}
                  </option>
                ))}
              </select>
            </>
          )}
        </div>

        <label className="text-txt text-lg mb-2 mt-4">Implant Abutment</label>
        <div className="flex flex-row justify-start">
          <select
            id="Abutment Category 1"
            value={categoryAbutment}
            onChange={handleCategoryAbutmentChange}
            className="select select-bordered border-gray-300 w-1/4 max-w-xs bg-defbg"
          >
            <option value="" disabled selected>
              Select Option
            </option>
            {Object.keys(IMPLANT_LIST["Implant Abutment"]).map((category) => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}
          </select>

          {categoryAbutment?.toLowerCase() === "other" && categoryAbutment && (
            <>
              <ChevronRightSVG />
              <div className="flex flex-col">
                <label className="text-txt text-lg"></label>
                <input
                  id="third-party"
                  value={subCategoryAbutment}
                  placeholder=""
                  onChange={handleSubCategoryAbutmentChange}
                  className="input input-bordered border-gray-300 input-md w-full max-w-xs bg-defbg"
                ></input>
              </div>
            </>
          )}
          {categoryAbutment?.toLowerCase() !== "other" && categoryAbutment && (
            <>
              <ChevronRightSVG />
              <select
                id="Abutment Category 2"
                value={subCategoryAbutment}
                onChange={handleSubCategoryAbutmentChange}
                className="select select-bordered border-gray-300 w-1/4 max-w-xs bg-defbg"
              >
                <option value="" disabled selected>
                  Select Option
                </option>
                {Object.keys(
                  IMPLANT_LIST["Implant Abutment"][categoryAbutment]
                ).map((subCategory) => (
                  <option key={subCategory} value={subCategory}>
                    {subCategory}
                  </option>
                ))}
              </select>
            </>
          )}
          {subCategoryAbutment && (
            <>
              <ChevronRightSVG />
              <select
                id="Abutment Category 3"
                value={subSubCategoryAbutment}
                onChange={handleSubSubCategoryAbutmentChange}
                className="select select-bordered border-gray-300 w-1/4 max-w-xs bg-defbg"
              >
                <option value="" disabled selected>
                  Select Option
                </option>
                {Object.keys(
                  IMPLANT_LIST["Implant Abutment"][categoryAbutment]?.[
                    showOptionForOther
                  ]
                ).map((subSubCategory) => (
                  <option value={subSubCategory}>{subSubCategory}</option>
                ))}
              </select>
              {implantsList["Implant Abutment"][categoryAbutment][
                showOptionForOther
              ][subSubCategoryAbutment]?.provided ? (
                <label className="label cursor-pointer flex flex-col mt-[-24px] w-1/4">
                  <span className="text-md ml-3">Provided?</span>
                  <input
                    id="provided_by_doctor"
                    checked={provided}
                    type="checkbox"
                    className="checkbox checkbox-sm checkbox-primary border-gray-500"
                    onChange={handleIsProvided}
                  />
                </label>
              ) : null}
            </>
          )}
        </div>
        <div id="addButton" className="flex w-full justify-end">
          <button
            className={
              "btn btn-primary disabled:btn-outline disabled:border-[#CBCBCB] bg-[#DEDEFA] mt-10 w-1/4 ml-8 text-[#5C59E8] border-white disabled:text-gray-200 hover:text-white"
            }
            disabled={
              caseImplantData.teethArr.length === 0 ||
              caseImplantData["cervical"] === "" ||
              caseImplantData["body"] === "" ||
              caseImplantData["incisal"] === "" ||
              categoryCrowns === "" ||
              subCategoryCrowns === "" ||
              categoryAbutment === "" ||
              subCategoryAbutment === "" ||
              subSubCategoryAbutment === ""
            }
            onClick={(e) => onClick(e)}
          >
            + Add Implant
          </button>
          <ToastContainer />
        </div>
      </div>
    </>
  );
}

export default ImplantList;
