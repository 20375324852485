import React from 'react'

function DentureIconSVG() {
  return (
    <div className="tooltip text-black" data-tip="Denture">
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="10" cy="10" r="10" fill="#E8F8FD"/>
    <path d="M9.75 15H7.05682V6.27273H9.86932C10.7159 6.27273 11.4403 6.44744 12.0426 6.79688C12.6449 7.14347 13.1065 7.64205 13.4276 8.29261C13.7486 8.94034 13.9091 9.71591 13.9091 10.6193C13.9091 11.5284 13.7472 12.3111 13.4233 12.9673C13.0994 13.6207 12.6278 14.1236 12.0085 14.4759C11.3892 14.8253 10.6364 15 9.75 15ZM8.11364 14.0625H9.68182C10.4034 14.0625 11.0014 13.9233 11.4759 13.6449C11.9503 13.3665 12.304 12.9702 12.5369 12.456C12.7699 11.9418 12.8864 11.3295 12.8864 10.6193C12.8864 9.91477 12.7713 9.30824 12.5412 8.79972C12.3111 8.28835 11.9673 7.89631 11.5099 7.62358C11.0526 7.34801 10.483 7.21023 9.80114 7.21023H8.11364V14.0625Z" fill="#42C1E9"/>
    </svg>
    </div>
  )
}

export default DentureIconSVG