import { createContext, useState } from "react";
import { TEETH_INT, TEETH_INT_DENTURE, CROWNS_LIST, DENTURE_LIST, IMPLANT_LIST} from "../constants";
const CaseContext = createContext();

export const CaseProvider = ({ children }) => {
  const [savedList, setSavedList] = useState([])

  const [caseCrownData, setCaseCrownData] = useState({teethArr: [], body: "", incisal: "", cervical: "", is_custom_shade: false, price_id:""});
  const [caseDentureData, setCaseDentureData] = useState({ teethArr: [],teeth_shade: "", arch: { upper: false, lower: false } });
  const [caseImplantData, setCaseImplantData] = useState({teethArr: [], body: "", incisal: "", cervical: "", type: "implant", provided_by_doctor: false});

  const [crownTeethInt, setCrownTeethInt] = useState(TEETH_INT);
  const [dentureTeethInt, setDentureTeethInt] = useState(TEETH_INT_DENTURE);
  const [implantTeethInt, setImplantTeethInt] = useState(TEETH_INT);

  const [ crownsList, setCrownsList ] = useState(CROWNS_LIST);
  const [ denturesList, setDenturesList ] = useState(DENTURE_LIST);
  const [ implantsList, setImplantsList ] = useState(IMPLANT_LIST);

  const [caseList, setCaseList] = useState([]);

  const [isRushCase, setIsRushCase ] = useState(false);

  return (
    <CaseContext.Provider
      value={{
        crownTeethInt,
        setCrownTeethInt,
        dentureTeethInt,
        setDentureTeethInt,
        implantTeethInt,
        setImplantTeethInt,
        caseCrownData,
        setCaseCrownData,
        caseDentureData,
        setCaseDentureData,
        caseImplantData,
        setCaseImplantData,
        crownsList,
        setCrownsList,
        denturesList,
        setDenturesList,
        implantsList,
        setImplantsList,
        caseList,
        setCaseList,
        isRushCase,
        setIsRushCase,
        savedList,
        setSavedList
      }}
    >
      {children}
    </CaseContext.Provider>
  );
};

export default CaseContext;
