import React from 'react'

function ChevronSVG({fill, transform}) {
  return (
    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17 9.5L12 14.5L7 9.5" transform={transform} stroke={fill} stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

  )
}

export default ChevronSVG