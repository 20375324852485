import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import CaseSearch from "./pages/CaseSearch"
import NewCase from "./pages/NewCase";
import Billing from "./pages/Billing";
import PrivateRoute from "./components/PrivateRoute";
import { AuthProvider } from "./contexts/AuthContext";
import { CaseProvider } from "./contexts/CaseContext";
import Settings from "./pages/Settings";
import Pdftest from "./pages/Pdftest";
import Admin from "./pages/admin/Admin";
import AdminPrivateRoute from "./components/AdminPrivateRoute";
import ResetPasswordRequest from "./pages/ResetPasswordRequest";
import ResetPassword from "./pages/ResetPassword";
import AdminCompany from "./pages/admin/AdminCompany";
import AdminUser from "./pages/admin/AdminUser";
import AdminCaseStatus from "./pages/admin/AdminCaseStatus"
import AdminCaseView from "./pages/admin/AdminCaseView";

function App() {
  return (
    <AuthProvider>
      <CaseProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<PrivateRoute />}>
               <Route index element={<Navigate to="/dashboard" />} />
              <Route exact path="/dashboard" element={<Dashboard />} />
              <Route exact path="/cases" element={<CaseSearch />} />
              {/* <Route exact path="/cases/search" element={<CaseSearch />} /> */}
              <Route exact path="/new_case" element={<NewCase />} />
              <Route exact path="/billing" element={<Billing />} />
              <Route exact path="/settings" element={<Settings />} />
              <Route exact path="/pdftest" element={<Pdftest />} />
            </Route>
            <Route path="/" element={<AdminPrivateRoute />}>
              <Route exact path="/admin-page" element={<Admin />} />
              <Route exact path="/admin-page/company" element={<AdminCompany />} />
              <Route exact path="/admin-page/user" element={<AdminUser />} />
              <Route exact path="/admin-page/case" element={<AdminCaseStatus />} />
              <Route exact path="/admin-page/case/:id" element={<AdminCaseView />} />
            </Route>
            <Route path="/login" element={<Login />} exact={true} />
            <Route path="/reset_password_request" element={<ResetPasswordRequest />} exact={true} />
            <Route path="/reset_password/:token" element={<ResetPassword />} />
            <Route path="/404" exact={true} >test 404</Route>
          </Routes>
        </BrowserRouter>
      </CaseProvider>
    </AuthProvider>
  );
}

export default App;
