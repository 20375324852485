import React, {useState, useContext, useRef, useEffect } from 'react'
import CrownList from './CrownList'
import CaseContext from '../contexts/CaseContext';
import ImageMapper from 'react-img-mapper';

function CrownCard() {
    const [area, setArea] = useState(null)

    const { caseCrownData, setCaseCrownData, crownTeethInt, setCrownTeethInt} = useContext(CaseContext);
    const teethRef = useRef()
    teethRef.current = caseCrownData.teethArr

    const teethStatusHandler = (t) => {
        if(teethRef.current.includes(t.name)) {
          setCaseCrownData(prev => ({
            ...prev,
            teethArr: prev.teethArr.filter(e => t.name !== e).sort(compareNumbers)
        }))
        setCrownTeethInt(prev => {
            let newObj = prev
            console.log(newObj);
            delete newObj[t.name]["preFillColor"]
            return newObj
          })
        }
        else {
          // console.log("else", t.name)
          setCaseCrownData(prev => ({
            ...prev,
            teethArr: [...prev.teethArr, t.name].sort(compareNumbers)
        }))
        setCrownTeethInt(prev => ({...prev, [t.name]:{...prev[t.name], preFillColor: "rgb(91, 89, 232, 0.8)"}}))
        }
      }    

    const compareNumbers = (a, b) => {
        return a - b;
      }

  return (

        <div className="flex bg-white flex-col p-x-6 pb-6 rounded-b-lg rounded-r-lg shadow-md items-center">
            <div id="teethImg" className='w-[869px] h-[353px]' style={{display:"inline-flex", flexDirection:"row",alignItems:"center",position:"relative", width:"max-content"}}>
              <ImageMapper src="./UNS.jpg"  map={{
                name: "my-map",
                areas: Object.values(crownTeethInt)
              }}
              onMouseEnter={(area) => setArea(area) }
              onMouseLeave={() => setArea(null)}
              onClick={(area) => teethStatusHandler(area)}
              width={869}
              />
              {
                !!area && <div style={{position:"absolute",backgroundColor:"white", top: `5%`, left: `45%`,zIndex:5,color:"black",fontSize:"4rem" }} >{area.name}</div>
              }
              </div>
              <CrownList />
        </div>
  )
}

export default CrownCard