import React, {useState} from 'react'
import { NavLink } from "react-router-dom";
import axios from 'axios'

function AdminUser()   {  
    const [formData, setFormData] = useState({
    email: "",
    street: "",
    city: "",
    state: "",
    zip: "",
    phone: "",
  });

  const { email, street, city, state ,zip, phone} = formData;

const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.id]: e.target.value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let res = await axios.post(
        "/api/auth/signup",
        { email, street, city, state ,zip, phone},
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (res) {
        console.log(res)
        setFormData({
            email: "",
            street: "",
            city: "",
            state: "",
            zip: "",
            phone: "",
          }) 
      }
    } catch (error) {
      console.log(error.response, "ERROR");
    }
  };

return (
<div className='mt-5'>
    <NavLink to='/admin-page' className='w-24'>
  <button className='btn btn-primary w-24'>
          Back
  </button>
      </NavLink>
<div className="flex flex-col items-center justify-center min-w-[550px] max-w-100 min-h-[650px] p-[15px] m-[15px] items-center justify-center">
<form
  onSubmit={handleSubmit}
  className="min-w-[450px] min-h-[250px] items-center justify-center"
>
  <div className="grow w-full h-full items-center justify-center">
    <div className="flex flex-col gap-[8px]">
      <label className="text-[#4D5464] text-[14px] font-[500]">
        Email
      </label>
      <input
        type="email"
        className="input input-bordered bg-[#FFFFFF] w-full border-1 box-border pt-2 pb-2 pr-3 pl-3"
        id="email"
        name="email"
        value={email}
        onChange={onChange}
        placeholder="Enter your email"
        required
      />
    <label className="text-[#4D5464] text-[14px] font-[500]">Street</label>
      <input
        className="input input-bordered w-full bg-[#FFFFFF] w-full border-1 box-border pt-2 pb-2 pr-3 pl-3"
        id="street"
        name="street"
        value={street}
        onChange={onChange}
        placeholder="street Name"
        required
      />
    <label className="text-[#4D5464] text-[14px] font-[500]">City</label>
      <input
        className="input input-bordered w-full bg-[#FFFFFF] w-full border-1 box-border pt-2 pb-2 pr-3 pl-3"
        id="city"
        name="city"
        value={city}
        onChange={onChange}
        placeholder="City Name"
        required
      />
          <label className="text-[#4D5464] text-[14px] font-[500]">State</label>
      <input
        className="input input-bordered w-full bg-[#FFFFFF] w-full border-1 box-border pt-2 pb-2 pr-3 pl-3"
        id="state"
        name="state"
        value={state}
        onChange={onChange}
        placeholder="State"
        required
      />
          <label className="text-[#4D5464] text-[14px] font-[500]">zip</label>
      <input
        className="input input-bordered w-full bg-[#FFFFFF] w-full border-1 box-border pt-2 pb-2 pr-3 pl-3"
        id="zip"
        name="zip"
        value={zip}
        onChange={onChange}
        placeholder="zip"
        required
      />
          <label className="text-[#4D5464] text-[14px] font-[500]">phone</label>
      <input
        className="input input-bordered w-full bg-[#FFFFFF] w-full border-1 box-border pt-2 pb-2 pr-3 pl-3"
        id="phone"
        name="phone"
        value={phone}
        onChange={onChange}
        placeholder="phone"
        required
      />
      <button
        className="btn btn-block
non-italic normal bg-[#5C59E8] 
border-transparent normal-case hover:bg-[#34AFF9] 
hover:border-transparent rounded w-full h-[40px] m-t-[10px]"
      >
        <div className="text-[#FFFFFF]">Add User</div>
      </button>
    </div>
  </div>
</form>
</div>
</div>
)
}

export default AdminUser