import React from 'react'

function CasesSVG({fill}) {
  return (
    <svg width="24" height="24" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg" className="">
    <path fillRule="evenodd" clipRule="evenodd" d="M9.26632 19.8641C9.4823 19.8351 9.69462 19.7707 9.8944 19.6708L17.4472 15.8944C17.814 15.711 18.0188 15.3325 17.9989 14.9476C17.9996 14.9318 18 14.9159 18 14.9V5.02197L18.0002 4.99998C18.0002 4.95771 17.9974 4.91562 17.9921 4.87397C17.9737 4.7272 17.9234 4.59034 17.8484 4.47049C17.8262 4.43484 17.8017 4.40049 17.775 4.36767C17.7009 4.27664 17.6125 4.20103 17.5148 4.14251C17.4864 4.12543 17.4571 4.10972 17.427 4.09547L9.8944 0.329188C9.33135 0.0476601 8.6686 0.0476601 8.10555 0.329188L0.57319 4.09537C0.542069 4.11007 0.51183 4.12634 0.482571 4.14406C0.385017 4.20291 0.296786 4.27886 0.222884 4.37023C0.19837 4.40052 0.175747 4.4321 0.155078 4.4648C0.0963708 4.55729 0.052434 4.66008 0.0265809 4.76984C0.0084699 4.84616 -0.000305251 4.92337 -0.00024382 4.99998L-3.01123e-05 5.02143V14.9C-3.01123e-05 14.916 0.000345468 14.9319 0.00108829 14.9477C-0.0188501 15.3326 0.185981 15.711 0.552762 15.8944L8.10555 19.6708C8.30532 19.7707 8.51764 19.8351 8.7336 19.8641C8.81841 19.8875 8.90773 19.9 8.99997 19.9C9.0922 19.9 9.18152 19.8875 9.26632 19.8641ZM1.99997 6.61799V14.3819L7.99997 17.3819V9.61799L1.99997 6.61799ZM9.99997 9.61799V17.3819L16 14.3819V6.61799L13.4608 7.88759C13.4522 7.89202 13.4436 7.89633 13.4349 7.90051L9.99997 9.61799ZM14.7639 4.99998L12.9999 5.88195L7.23604 3.00001L8.99998 2.11804L14.7639 4.99998ZM10.7639 6.99998L4.99998 4.11804L3.23609 4.99998L8.99998 7.88192L10.7639 6.99998Z" fill={fill}/>
    </svg>
  )
}

export default CasesSVG