import React, { useState} from 'react'
import { NavLink } from "react-router-dom";
import axios from 'axios'

function AdminCompany() {

    const [formData, setFormData] = useState({
        company_name: "",
        practice_name: "",
        email: "",
      });

      const { company_name, practice_name, email } = formData;

    const onChange = (e) => {
        setFormData((prevState) => ({
          ...prevState,
          [e.target.id]: e.target.value,
        }));
      };

      const handleSubmit = async (e) => {
        e.preventDefault();
        try {
          let res = await axios.post(
            "/api/companies",
            { company_name, practice_name, email},
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          if (res) {
            console.log(res)
            setFormData({
                company_name: "",
                email: "",
              }) 
          }
        } catch (error) {
          console.log(error.response, "ERROR");
        }
      };

  return (
    <div className='mt-5'>
        <NavLink to='/admin-page' className='w-24'>
      <button className='btn btn-primary w-24'>
              Back
      </button>
          </NavLink>
    <div className="flex flex-col items-center justify-center min-w-[550px] max-w-100 min-h-[650px] p-[15px] m-[15px] items-center justify-center">
    <form
      onSubmit={handleSubmit}
      className="min-w-[450px] min-h-[250px] items-center justify-center"
    >
      <div className="grow w-full h-full items-center justify-center">
        <div className="flex flex-col gap-[8px]">
        <label className="text-[#4D5464] text-[14px] font-[500]">Company Name</label>
          <input
            className="input input-bordered w-full bg-[#FFFFFF] w-full border-1 box-border pt-2 pb-2 pr-3 pl-3"
            id="company_name"
            name="company_name"
            value={company_name}
            onChange={onChange}
            placeholder="Company Name"
            required
          />
        <label className="text-[#4D5464] text-[14px] font-[500]">Practice Name</label>
          <input
            className="input input-bordered w-full bg-[#FFFFFF] w-full border-1 box-border pt-2 pb-2 pr-3 pl-3"
            id="practice_name"
            name="practice_name"
            value={practice_name}
            onChange={onChange}
            placeholder="Practice Name"
            required
          />
          <label className="text-[#4D5464] text-[14px] font-[500]">
            Email
          </label>
          <input
            type="email"
            className="input input-bordered bg-[#FFFFFF] w-full border-1 box-border pt-2 pb-2 pr-3 pl-3"
            id="email"
            name="email"
            value={email}
            onChange={onChange}
            placeholder="Enter your email"
            required
          />
          <button
            className="btn btn-block
    non-italic normal bg-[#5C59E8] 
    border-transparent normal-case hover:bg-[#34AFF9] 
    hover:border-transparent rounded w-full h-[40px] m-t-[10px]"
          >
            <div className="text-[#FFFFFF]">Add Company</div>
          </button>
        </div>
      </div>
    </form>
    </div>
    </div>
  )
}

export default AdminCompany