import React from 'react'
import PatientIconSVG from '../svg/PatientIconSVG'
import DoctorIconSVG from '../svg/DoctorIconSVG'
import DateIconSVG from '../svg/DateIconSVG'

function CaseCard({patient, doctor, date }) {

  return (
    <div className="flex flex-col">
    <div className='flex flex-row mb-1'>
      <p className='font-black mr-2 text-slate-900'>
        Patient: 
      </p>
      <div className=''>
        {patient}
      </div>
    </div>
    <div className='flex flex-row mb-1'>
      <p className='font-black mr-2 text-slate-900'>
        Doctor:
      </p>
      <div className=''>
      {doctor}
      </div>
    </div>
    <div className='flex flex-row mb-1'>
       <p className='font-black mr-2 text-slate-900'>
        Return: 
      </p>
       <div className=''>
       {date}
      </div>
      </div>
  </div>
  )
}

export default CaseCard