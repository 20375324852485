import React from 'react'

function StatementModal({ invoice }) {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
  return (
    <div className=" bg-white relative modal-box bg-white max-w-none w-[1000px] text-[#333843] rounded-lg drop-shadow-lg p-4">
      <div className="flex flex-col gap-1 my-2 mx-1">
        <div className="font-bold text-xl">{invoice && invoice?.custom_fields?.[0]
                 ? invoice.custom_fields[0]['value'] + " Statement" : ""}</div>
        <div className="overflow-x-auto mt-5">
           <table className="table">
             {/* head */}
             <thead>
               <tr className='ml-2'>
                 <th className='w-16 text-lg'>Date</th>
                 <th className='w-24 text-lg'>Transaction</th>
                 <th className='w-24 text-lg'>Amount</th>
               </tr>
             </thead>
             <tbody>
        {invoice?.lines?.data?.map((item) => {
            const dateObject = new Date(item?.period?.start * 1000);
            const formatted = dateObject.toLocaleString(undefined, options);
            return (<tr>
                <td>{formatted}</td>
                <td>{item?.metadata['id']}</td>
                <td>{'$'+item?.amount/100}</td>
            </tr>);
        }
        )}
            </tbody>
            </table>
            <div className='flex justify-end mt-3'>
                <button className={
                    `btn btn-${invoice?.status?.toLowerCase() === "paid" ? 
                    "primary bg-[#E0E2E7] text-[#858D9D] btn-disabled" : invoice.status.toLowerCase() === "open" ?
                    "primary bg-[#DEDEFA] text-[#5C59E8] hover:text-white" : 
                    "primary bg-[#E0E2E7] text-[#858D9D] btn-disabled"} border border-[#F9F9FC] btn-md text-base`}>
                    <a href={invoice?.hosted_invoice_url} target="_blank" >{invoice?.status?.toLowerCase() === "open" ? 
                    "Pay Invoice" : invoice?.status?.toLowerCase() === "draft" ? 'Pending': 'Paid'}</a>
                </button>
            </div>
         </div>
      </div>
  </div>    
  )
}

export default StatementModal