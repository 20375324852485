import React, {useState, useContext, useRef } from 'react'
import CaseContext from '../contexts/CaseContext';
import ImplantList from './ImplantList';
import ImageMapper from 'react-img-mapper';

function ImplantCard() {
    const [area, setArea] = useState(null)
    const { caseImplantData, setCaseImplantData, implantTeethInt, setImplantTeethInt} = useContext(CaseContext);
    const teethRef = useRef()
    teethRef.current = caseImplantData.teethArr

    const teethStatusHandler = (t) => {
        if(teethRef.current.includes(t.name)) {
            setCaseImplantData(prev => ({
            ...prev,
            teethArr: prev.teethArr.filter(e => t.name !== e).sort(compareNumbers)
        }))
        setImplantTeethInt(prev => {
            let newObj = prev
            // console.log(newObj);
            delete newObj[t.name]["preFillColor"]
            return newObj
          })
        }
        else {
          // console.log("else", t.name)
          setCaseImplantData(prev => ({
            ...prev,
            teethArr: [...prev.teethArr, t.name].sort(compareNumbers)
        }))
        setImplantTeethInt(prev => ({...prev, [t.name]:{...prev[t.name], preFillColor: "rgb(91, 89, 232, 0.8)"}}))
        }
      }    

    const compareNumbers = (a, b) => {
        return a - b;
      }

  return (
    <div className="flex bg-white flex-col p-x-6 pb-6 pt-2 gap-2 rounded-b-lg rounded-r-lg shadow-md items-center">
        <div id='teethImg' className='w-[869px] h-[353px]' style={{display:"inline-flex", flexDirection:"row",alignItems:"center",position:"relative", width:"max-content", backgroundImage:"url(./UNS.jpg)"}}>
            <ImageMapper src="./UNS.jpg" map={{
               name: "my-map",
               areas: Object.values(implantTeethInt)
             }}
             onMouseEnter={(area) => setArea(area) }
             onMouseLeave={() => setArea(null)}
             onClick={(area) => teethStatusHandler(area)}
             width={869}
             />
    {
      !!area && <div style={{position:"absolute",backgroundColor:"white", top: `5%`, left: `45%`,zIndex:5,color:"black",fontSize:"4rem" }} >{area.name}</div>
    }
        </div>
        <ImplantList />
    </div>
  )
}

export default ImplantCard