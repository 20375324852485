import React, { useContext } from "react";
import AuthContext from "../contexts/AuthContext";
import { NavLink, useLocation } from "react-router-dom";
import DashboardSVG from "../svg/DashboardSVG";
import CasesSVG from "../svg/CasesSVG";
import BillingSVG from "../svg/BillingSVG";
import SettingsSVG from "../svg/SettingsSVG";
import AddSVG from "../svg/AddIconSVG";
import SubIconSVG from "../svg/SubIconSVG";
import "./keyframe.css"

function Navbar() {

  const isActiveCSS = "flex px-2 py-2 items-center bg-[#5C59E8] rounded-lg font-inter font-[700] text-[14px] text-[#FFF] hover:bg-[#3d3b9c] duration-300"
  const isNotActiveCSS = "flex px-2 py-2 items-center rounded-lg font-inter font-[600] text-[14px] text-[#667085] hover:bg-[#e6eefc] hover:text-[#fffff] duration-300"
 
  const { auth } = useContext(AuthContext);

  return (
    <div className="flex flex-col relative w-[264px] shrink-0 justify-start bg-slate-100 flex=[1_0_0%] self-stretch bg-white border-r border-[#F0F1F3] shadow-[4px_0px_30px_0px-rgba(131, 98, 234, 0.05)]">
      <div className="sticky top-0">
        <NavLink to ="/dashboard">
        <div className="flex px-2 py-4 flex-row items-center">
        <div className="pl-2">
        <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M17 12.75C14.6528 12.75 12.75 14.6528 12.75 17V29.75C12.75 32.0972 14.6528 34 17 34C19.3472 34 21.25 32.0972 21.25 29.75V21.25H29.75C32.0972 21.25 34 19.3472 34 17C34 14.6528 32.0972 12.75 29.75 12.75H17Z" fill="#7D7AED"/>
          <path d="M17 0C14.6528 0 12.75 1.90279 12.75 4.25V12.75H4.25C1.90279 12.75 0 14.6528 0 17C0 19.3472 1.90279 21.25 4.25 21.25H17C19.3472 21.25 21.25 19.3472 21.25 17V4.25C21.25 1.90279 19.3472 0 17 0Z" fill="#42C1E9"/>
          <path d="M21.25 12.75H17C14.6528 12.75 12.75 14.6528 12.75 17V21.25H17C19.3472 21.25 21.25 19.3472 21.25 17V12.75Z" fill="#5C59E8"/>
        </svg>
        </div>
        <div className="font-inter font-[700] text-[24px] text-[#333843] pl-5">Dentopia</div>
        </div>
        </NavLink>
      <div
        name="navbar"
        className="flex flex-col relative h-5/6 justify-start bg-white flex=[1_0_0%] self-stretch pt-6 pr-4 pb-8 pl-4 gap-2"
      >
        <NavLink
          to="/dashboard"
          className={({ isActive }) =>
            isActive
              ? isActiveCSS
              : isNotActiveCSS
          }
          end
          children={({ isActive }) => (
                          <>
                          <DashboardSVG className="pl-10" fill={isActive ? "white" : "#667085"}/>
                           <div className="pl-2">Dashboard</div>
                          </>)
                       } />         
        <NavLink
          to="/cases"
          className={({ isActive }) =>
            isActive
              ? isActiveCSS
              : isNotActiveCSS
          }
          children={({ isActive }) => (
            <>
            <CasesSVG fill={isActive ? "white" : "#667085"}/>
             <div className="pl-1.5">Cases</div>
            </>)
         } />
                 <div className="flex w-[100%]">
                <SubIconSVG />
                 <NavLink
          to="/new_case"
          // style={(currPath.pathname == '/cases' || currPath.pathname == '/cases/search') ? {
          //   display:"flex",
          //   animation: "fade_in 250ms"
          // } : {
          //   display:"none",
          //   animation: "fade_out 250ms"
          // }}
          className={({ isActive }) =>
            isActive
              ? isActiveCSS + ' w-full'
              : isNotActiveCSS + ' w-full'
          }
          children={({ isActive }) => (
            <>
            <AddSVG fill={isActive ? "white" : "#667085"}/>
             <div className="pl-1.5">New Case</div>
            </>)
         } />
</div>
        <NavLink to="/billing" className={({ isActive }) =>
            isActive
              ? isActiveCSS
              : isNotActiveCSS
            }
            children={({ isActive }) => (
              <>
              <BillingSVG className="pl-10" fill={isActive ? "white" : "#667085"}/>
               <div className="pl-2">Billing</div>
              </>)
           } />
        <div className="sticky top-0 bot-0 mt-auto">
        <NavLink to="/settings" className={({ isActive }) =>
            isActive
              ? isActiveCSS
              : isNotActiveCSS
            }
            children={({ isActive }) => (
              <>
              <SettingsSVG className="pl-10" fill={isActive ? "white" : "#667085"}/>
                <div className="pl-2">Settings</div>
              </>)
            } /> 
            </div>    
            {auth.view  && <div className="sticky top-0 bot-0 mt-auto">
          <NavLink to="/admin-page" className={({ isActive }) =>
            isActive
              ? isActiveCSS
              : isNotActiveCSS
            }
            children={({ isActive }) => (
              <>
                <div className={`pl-2 ${isActive ? 'text-white' : 'text-red-500'}`}>Admin View</div>
              </>)
            } /> 
            </div>  
          }
      </div>
    </div>
    </div>
  );
}
export default Navbar;
