import { jsPDF } from "jspdf";
import { PDFDocument } from "pdf-lib";
import { domToCanvas, domToDataUrl, domToPng } from 'modern-screenshot'
// import html2canvas from "html2canvas";

const generatePDF = async (itemLength, id) => {
  const pdfMain = await PDFDocument.create();

  for (let x = 0; x < itemLength; x++) {
    const doc = new jsPDF("l", "mm", [1122 / 1.89, 794 / 1.89]);

    let target = document.getElementById(`id${id}pdf${x}`)

    await domToPng(target,{
      scale:2,
      quality:1,
      width: 1122,
      height: 794,
      onCloneNode: function (logo) {
        logo.style.display = "flex"
      }
    }).then((data) => {
      doc.addImage(data, "JPEG", 0, 0);
    }
  )

  //   await html2canvas(document.getElementById(`pdf${x}`), {
  //     allowTaint: true,
  //     dpi: 2000,
  //     quality: 5,
  //     scale: 2,
  //     onclone: (document) => {
  //       Array.from(document.querySelectorAll("*")).forEach((e) => {
  //         let existingStyle = e.getAttribute("style") || "";
  //         e.setAttribute(
  //           "style",
  //           existingStyle + "; font-family: Helvetica, sans-serif !important"
  //         );
  //       });
  //     },
  //     onclone: function (unhideLogo) {
  //       unhideLogo.getElementById(`pdf${x}`).style.display = "flex";
  //     },
  //   }).then(() => {
  //     html2canvas(document.getElementById(`pdf${x}`), {
  //       allowTaint: true,
  //       dpi: 2000,
  //       quality: 5,
  //       scale: 2,
  //       onclone: (document) => {
  //         Array.from(document.querySelectorAll("*")).forEach((e) => {
  //           let existingStyle = e.getAttribute("style") || "";
  //           e.setAttribute(
  //             "style",
  //             existingStyle + "; font-family: Helvetica, sans-serif !important"
  //           );
  //         });
  //       },
  //       onclone: function (unhideLogo) {
  //         unhideLogo.getElementById(`pdf${x}`).style.display = "flex";
  //       },
  //     }).then((canvas) => {
  //       let imgdata = canvas.toDataURL("image/jpeg", 1);
  //       doc.addImage(imgdata, "JPEG", 0, 0);
  //     });
  //   })

  //   // const scalor = 1.89;

  //   // await html2canvas(target, {
  //   //   scale: 2,
  //   //   letterRendering:true,
  //   //   onclone: function (unhideLogo) {
  //   //     unhideLogo.getElementById(`pdf${x}`).style.display = "flex";
  //   //   },
  //   // }).then((canvas) => {
  //   //   const imgdata = canvas.toDataURL("image/png", 1);
  //   //   doc.addImage(imgdata, "PNG", 0 , 0);
  //   // });

    const arrayBuffer = doc.output("arraybuffer");
    const itemPDF = await PDFDocument.load(arrayBuffer);
    const [itemPage] = await pdfMain.copyPages(itemPDF, [0]);
    pdfMain.addPage(itemPage);
  }

  const pdfBytes = await pdfMain.save();

  const bytes = new Uint8Array(pdfBytes);
  const blob = new Blob([bytes], { type: "application/pdf" });
  const docUrl = URL.createObjectURL(blob);
  window.open(docUrl);
};
//   let scalor = 1.89;
//   let doc = new jsPDF("l", "mm", [1122 / scalor, 794 / scalor]);

//   let target = document.getElementById("pdf0");

//   await html2canvas(target, {
//     scale: 2,
//     onclone: function (unhideLogo) {
//       unhideLogo.getElementById("pdf0").style.display = "flex";
//     },
//   }).then((canvas) => {
//     const imgdata = canvas.toDataURL("image/png", 1);
//     doc.addImage(imgdata, "PNG", 0, 0);
//   });

//   // await html2canvas(document.querySelector("#testteeth"), {
//   //   dpi: 2000,
//   //   quality: 5,
//   //   async: false,
//   //   allowTaint: false,
//   //   scale: 1.25
//   // }).then((canvas) => {
//   //   const imgdata = canvas.toDataURL("image/png", 1);
//   //   doc.addImage(imgdata, "PNG", 20, 290);
//   // })

//   window.open(doc.output("bloburl"));
// }

// async function generatePDF(data) {
//   let doc = new jsPDF({
//     orientation: 'l'
//   });
//   doc.setFontSize(15);
//   doc.text("Doctor:", 10, 35);
//   doc.text(`Dr. ${data.doctor.doctor_first} ${data.doctor.doctor_last}`, 10, 40);
//   doc.text("Name:", 10, 50);
//   doc.text(`${data.patient_last}, ${data.patient_first}`, 10, 55);
//   let dist = 70
//   let iDist = 65
//   for (let x of data.items){
//     doc.text(`Item`, 10, dist-5)
//     if(x['notes'])
//       doc.text(`notes : ${x['notes']}`,110, iDist, {maxWidth:180})
//     for (let y of Object.keys(x)){
//       if( y != "images" && y != "notes" && y != "price_id"){
//         doc.text(`${y} : ${x[y]}`, 10, dist)
//         dist += 5
//         iDist += 5
//       }
//     }
//     dist += 10
//     iDist += 10
//   }
//   await html2canvas(document.querySelector("#pdf"), {
//     async:false,
//     allowTaint: false,
//     scale: 2,
//     backgroundColor:'white',
//     onclone: function (unhideLogo) {
//       unhideLogo.getElementById("pdf").style.display = "flex";
//     },
//   }).then((canvas) => {
//     const imgdata = canvas.toDataURL("image/png");
//     doc.addImage(imgdata, "PNG",2, 10);
//   }).then((res) => console.log('d'))
//   // await html2canvas(document.querySelector("#pdf"), {
//   //   dpi: 300,
//   //   scale: 1.5,
//   //   backgroundColor:'white',
//   //   onclone: function (unhideLogo) {
//   //     unhideLogo.getElementById("pdf").style.display = "flex";
//   //   },
//   // }).then((canvas) => {
//   //   const imgdata = canvas.toDataURL("image/jpeg");
//   //   doc.addImage(imgdata, "JPEG", 10, 10);
//   // }).then((res) => console.log('d'))
//   // await html2canvas(document.querySelector("#testteeth"), {
//   //   dpi: 2000,
//   //   quality: 5,
//   //   async:false,
//   //   allowTaint: false,
//   //   scale: .7,
//   //   backgroundColor:'white',
//   // }).then((canvas) => {
//   //   const imgdata = canvas.toDataURL("image/png");
//   //   doc.addImage(imgdata, "PNG", 80, 140);
//   // }).then((res) => console.log('d'))
//   doc.setFontSize(26);
//   doc.text("Dentopia", 30, 20);
//   window.open(doc.output("bloburl"))
// }

export default generatePDF;
