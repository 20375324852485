import React, { useContext, useState } from 'react'
import CaseContext from '../contexts/CaseContext';
import { CROWNS_LIST, TEETH_INT } from '../constants';
import { domToCanvas, domToDataUrl } from 'modern-screenshot';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const contextClass = {
  success: "color-[#0D894F]",
};

function CrownList() {

  const {  caseCrownData, setCaseCrownData, 
          setCaseList, setCrownTeethInt, 
          crownsList, setCrownsList} = useContext(CaseContext);
  const [ selected, setSelected ] = useState(false);
  const [ notes, setNotes ] = useState("");
  const [ isCustomShade, setIsCustomShade ] = useState(false);
    
      const onChange = (e) => {
        if(e.target.id in caseCrownData && caseCrownData[e.target.id] === e.target.value){
          delete caseCrownData[e.target.id]
          setCrownsList(prevState => ({
            ...prevState,
            [e.target.id]: {
              ...prevState[e.target.id],
              [e.target.value]: {
                ...prevState[e.target.id][e.target.value],
                isSelected: false
              }             
            }
          }))
          setSelected(false);
        }else {
          setCaseCrownData((prevState) => ({
            ...prevState,
            [e.target.id]: e.target.value,
            price_id: crownsList[e.target.id][e.target.value]['price_id'],
            type: "crown"
        }))
        setCrownsList((prevState) => ({
          ...prevState,
          [e.target.id]: {
            ...prevState[e.target.id],
            [e.target.value]: {
              ...prevState[e.target.id][e.target.value],
              isSelected: true
            } 
          }
        }))
        setSelected(true);
        }
        console.log(caseCrownData);
      }

      const handleCaseNotesChange = (e) => {
         setNotes(e.target.value);
         setCaseCrownData((prevState) => ({
          ...prevState,
          [e.target.id]:e.target.value
      }))
      };

      const handleInputChange = (event) => {
        const { name, value } = event.target;
    
        switch (name) {
          case 'incisal':
            setCaseCrownData(prev => ({
              ...prev,
              'incisal': value
          }))
            break;
          case 'body':
            setCaseCrownData(prev => ({
              ...prev,
              'body': value
          }))
            break;
          case 'cervical':
            setCaseCrownData(prev => ({
              ...prev,
              'cervical': value
          }))
            break;
          default:
            break;
        }
        console.log(caseCrownData)
      };

      const handleIsCustomShade = (e) => { 
        setIsCustomShade(!isCustomShade)
        setCaseCrownData((prevState) => ({
          ...prevState,
          [e.target.id]:!isCustomShade
      }))
      }
  
      const onClick = async (e) => {
        //check if custom shade, if it is delete other shades.
        e.preventDefault()
        const target = document.getElementById("teethImg");
        const target2 = document.getElementById("crownChecks");
        let updatedState = { ...caseCrownData }
        await domToDataUrl(target, {
          quality: 1,
          scale: 2,
        }).then(async (data) => {
          await domToDataUrl(target2, {
            quality: 1,
            scale: 2,
            onCloneNode: function (logo) {
              logo.style.columns = '3'
              logo.style.columnFill = 'balance-all'
              const addButton = logo.querySelector('#addButton')
              addButton.remove()
            }
          }).then((data2) =>{ 
            updatedState = { ...updatedState, 'check':data2 };
          })
          updatedState = { ...updatedState, 'img':data };
        if(isCustomShade) {
          delete updatedState['body'];
          delete updatedState['incisal'];
          delete updatedState['cervical'];
          setCaseCrownData(updatedState);
          setCaseList((prevState) => [...prevState, updatedState]); 
        }else {
          delete updatedState['is_custom_shade'];
          setCaseCrownData(updatedState)
          setCaseList((prevState) => [...prevState, updatedState]);
        }
        setCaseCrownData({teethArr: [], body: "", incisal: "", cervical: "", is_custom_shade: false, price_id: ""});
        setCrownTeethInt(TEETH_INT)
        setCrownsList(CROWNS_LIST)
        setNotes("")
        setSelected(false)
        setIsCustomShade(false)
        toast.success("Crown Added",{
          className: "toast-message",
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          progress: undefined,
          theme: "light",
          });
        });
        //check if custom shade, if it is delete other shades.
        //   const updatedState = { ...caseCrownData };
        // if(isCustomShade) {
        //   delete updatedState['body'];
        //   delete updatedState['incisal'];
        //   delete updatedState['cervical'];
        //   setCaseCrownData(updatedState);
        //   setCaseList((prevState) => [...prevState, updatedState]); 
        // }else {
        //   delete updatedState['is_custom_shade'];
        //   setCaseCrownData(updatedState)
        //   setCaseList((prevState) => [...prevState, caseCrownData]);
        // }
        // setCaseCrownData({teethArr: [], body: "", incisal: "", cervical: "", is_custom_shade: false, price_id: ""});
        // setCrownTeethInt(TEETH_INT)
        // setCrownsList(CROWNS_LIST)
        // setNotes("")
        // setSelected(false)
        // setIsCustomShade(false)
        // toast.success("Crown Added",{
        //   className: "toast-message",
        //   position: "top-right",
        //   autoClose: 5000,
        //   hideProgressBar: false,
        //   closeOnClick: true,
        //   pauseOnHover: true,
        //   progress: undefined,
        //   theme: "light",
        //   });
      }

  return (
    <>
    <div style={{display:"inline-flex", flexDirection:"column",alignItems:"center",position:"relative", padding: "5px 0px", width:"95%"}}>
      {isCustomShade ?   
        <label className='text-[#F04438]'>
          If you'd like a custom shade, please call the office at (703)-365-2635 for an appointment after you submit the case
          </label> 
      : <div className='flex flex-row gap-1 ml-3'>
        <label htmlFor="" className='mt-0.5'>Incisal</label>
          <input
            name='incisal'
            value={caseCrownData['incisal']} 
            onChange={(e) => handleInputChange(e)} 
            className="input input-bordered border-gray-500 input-sm w-[130px] max-w-xs bg-defbg ml-1" />
        <label className='ml-4 mt-0.5' htmlFor="">Body</label>
        <input 
          name='body'
          value={caseCrownData['body']} 
          onChange={(e) => handleInputChange(e)}
          className="input input-bordered border-gray-500 input-sm w-[130px] max-w-xs bg-defbg ml-1" />
        <label className='ml-4 mt-0.5' htmlFor="">Cervical</label>
        <input 
          name='cervical'
          value={caseCrownData['cervical']} 
          onChange={(e) => handleInputChange(e)}
          className="input input-bordered border-gray-500 input-sm w-[130px] max-w-xs bg-defbg ml-1" />
      </div>} 
      <div className='form-control'>
         <label className="label cursor-pointer">
           <input 
            id="is_custom_shade"
            checked={isCustomShade}
            type="checkbox" 
            className="checkbox checkbox-sm checkbox-primary border-gray-500" 
            onChange={handleIsCustomShade} />
            <span className="text-md ml-3">Custom Shade</span> 
           </label>
         </div>
     </div>
     <div className='gap-1 w-4/5'>
      <div className="label">
        <span className="label-text-alt">Please add shade details if needed</span>
       </div>
        <textarea id="notes" value={notes} 
          onChange={(e) => handleCaseNotesChange(e)} 
          className="textarea textarea-bordered border-gray-500 w-full m-x-2 h-[155px]" 
          placeholder="Notes"></textarea>
     </div>
    <div className="flex bg-white flex-col gap-6 mt-3">
    <form id='crownChecks' className="columns-3 whitespace-nowrap">
      { Object.keys(crownsList).map((group) => (
        <fieldset className="border p-2 break-inside-avoid" >
          <legend className="p-1 text-lg">{group}</legend>
          {Object.keys(crownsList[group]).map((select) => (
            <div className="flex p-0.5 items-center">
              <input type="checkbox" id={group} className="checkbox checkbox-primary" 
                value={select} 
                onChange={onChange} 
                checked={crownsList[group][select]['isSelected']} 
                disabled={selected && !crownsList[group][select]['isSelected']} 
                />
              <label className="pl-1 text-txt text-md">{select}</label>
            </div>
          ))}
        </fieldset>
      ))}
      <button id='addButton' className={"btn btn-primary disabled:btn-outline disabled:border-[#CBCBCB] bg-[#DEDEFA] mt-40 w-4/5 ml-8 text-[#5C59E8] border-white disabled:text-gray-200 hover:text-white"}
        disabled={
          !(caseCrownData?.teethArr.length > 0
        && ((caseCrownData?.incisal !== '' && caseCrownData?.body !== '' && caseCrownData?.cervical !== '') || caseCrownData.is_custom_shade)
        && (Object.keys(caseCrownData).filter(key => !['cervical', 'body', 'incisal', 'teethArr','type','notes', 'price_id', 'is_custom_shade'].includes(key)).length > 0) )}
          onClick={(e) => onClick(e)}>
          + Add Crown
      </button>
      <ToastContainer 
            toastClassName={({ type }) => contextClass[type || "default"]
          } />
    </form>
  </div>
</>
  )
}

export default CrownList