import { useState, useContext, useEffect } from "react";
import AuthContext from "../contexts/AuthContext";

export const useAuthStatus = () => {
  const { auth } = useContext(AuthContext);
  const [loggedIn, setLoggedIn] = useState(false);
  const [checkingStatus, setCheckingStatus] = useState(true);

  useEffect(() => {
    if (Object.keys(auth).length > 0) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
    setCheckingStatus(false);
  }, [auth]);

  return { loggedIn, checkingStatus};
};
