import React, { useState } from 'react'

function AdminTextInput({ field, value, label, onChange }) {

  return (
    <div className="flex flex-col">
    <label className="text-txt text-lg">{label}</label>
     { field === 'admin_notes' ?
        <textarea  value={value} 
            onChange={onChange}
            //onChange={(e) => handleCaseNotesChange(e)} 
            className="textarea textarea-bordered border-gray-500 w-full m-x-2 h-[155px]" 
            placeholder="Notes"></textarea> :
    //if not admin notes or dates
         <input
           value={value}
           onChange={onChange}
           className="input input-bordered border-gray-300 input-sm w-full max-w-xs bg-defbg" />
        }
  </div>
  )
}

export default AdminTextInput