import React, { useState, useEffect } from 'react'
import AdminTextInput from './AdminTextInput'
import axios from 'axios';
import UTCDatePicker from '../UTCDatePicker'

function AdminTextInputList({caseInfo}) {

  const [ isEdit,  setIsEdit ] = useState(false)
  const adminOnlyFields = ['pick_up_date','tech_date', 'koden_number', 'admin_notes', 'city_code', 'remake_reason' ]
  const filteredFields = adminOnlyFields.filter(field => field !== 'pick_up_date' && field !== 'tech_date');

  const [originalValues, setOriginalValues] = useState({});
  const [currentValues, setCurrentValues] = useState({});

  useEffect(() => {
    const initialValues = adminOnlyFields.reduce((acc, field) => {
      acc[field] = caseInfo?.[field] || '';
      return acc;
    }, {});
    setOriginalValues(initialValues);
    setCurrentValues(initialValues);
  }, [caseInfo]);

  const handleInputChange = (field, value) => {
    console.log(value);
    setCurrentValues(prevValues => ({
      ...prevValues,
      [field]: value,
    }));

    const hasChanges = adminOnlyFields.some(field => originalValues[field] !== value);
    setIsEdit(hasChanges);
  };

  function transformString(str) {
    return str
      .split('_') // Split the string by underscores
      .map(word => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
      .join(' '); // Join the words with spaces
  }

  const handleSubmit = async () => {
    try {
      await axios.put( `/api/cases/${caseInfo.id}`, currentValues);
      setOriginalValues(currentValues);
      setIsEdit(false);
    } catch (error) {
      console.error('Error updating settings:', error);
    }
  };

  return (
    <>  
      <label className='text-red-500 justify-start text-xl mb-4'>Admin Section</label>
      <label className="text-txt text-lg">Pick Up Date</label>
        <UTCDatePicker
            selected={currentValues?.['pick_up_date'] ?  new Date(currentValues?.['pick_up_date']): ""}
            onChange={(date) => {console.log(date); handleInputChange('pick_up_date', date)}}
            isAdminPage={true}
            /> 
        <label className="text-txt text-lg">Tech Date</label>
        <UTCDatePicker
            selected={currentValues?.['tech_date'] ? new Date(currentValues?.['tech_date']) : ""}
            onChange={(date) => handleInputChange('tech_date', date)}
            isAdminPage={true}
            /> 
      {filteredFields.map((field) => 
        (<AdminTextInput 
          key={field}
          field={field} 
          value={currentValues[field]} 
          label={transformString(field)} 
          onChange={(e) => handleInputChange(field, e.target.value)}
        />))}
        <button 
            className={"btn btn-primary disabled:btn-outline disabled:border-[#CBCBCB] bg-[#DEDEFA] mt-4 w-full text-[#5C59E8] border-white disabled:text-gray-200 hover:text-white"}
            disabled={!isEdit}
            onClick={handleSubmit}>
          Save Settings
        </button>
    </>
    )
}

export default AdminTextInputList