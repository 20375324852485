import React from 'react'

function AddListSVG() {
  return (
    <svg width="23px" height="23px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17 19V11M3 11H13M3 7H13M3 15H9M13 15H21" stroke="#667085" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

  )
}

export default AddListSVG