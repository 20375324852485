import React, { useEffect, useState } from 'react'
import Pdftest from '../Pdftest';
import { NavLink, useParams } from 'react-router-dom';
import axios from 'axios';
import AdminTextInputList from './AdminTextInputList';

function AdminCaseView() {
  
  const [ caseInfo, setCaseInfo ] = useState(null);
  const { id } = useParams();

  const filtArr = [
    "type",
    "teethArr",
    "images",
    "price_id",
    "invoice_item_id",
    "notes",
    "note",
    "check",
    "img"
  ];

  const ordin = {
    0: "1st",
    1: "2nd",
    2: "3rd",
    3: "4th",
    4: "5th",
    5: "6th",
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(`/api/cases/${id}`);
        console.log(response.data);
        setCaseInfo(response.data);
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);

  function replaceUnderscoresWithSpaces(inputString) {
    // Split the string into an array of words
    var words = inputString.split('_');

    // Capitalize the first letter of each word and convert underscores to spaces
    for (var i = 0; i < words.length; i++) {
        words[i] = words[i].charAt(0).toUpperCase() + words[i].slice(1);
    }

    // Join the words back together with spaces
    var resultString = words.join(' ');

    return resultString;
}

const capitalize = (str) => {
    if(typeof str === "string"){
      return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }else if (Array.isArray(str)){
      return str.join(', ');
    }
  }

  return (
    <div className='mt-5'>
        <NavLink to='/admin-page' className='w-24'>
            <button className='btn btn-primary w-24'>
                Back
            </button>
      </NavLink>
      <div>
      <div className="card mr-5 mt-5 ml-5 bg-white max-w-none w-11/12 text-[#333843]">
      <div className="flex flex-row justify-between font-bold text-xl card-body pb-2">
            {`Case #${caseInfo?.id}`}
            </div>
      <div className="flex flex-row">
      <div className='card-body pt-4'>
        <div
          data-html2canvas-ignore="true"
          className="flex flex-row absolute top-4 right-4 gap-2 h-[25px]"
        >
          <Pdftest action={() => console.log('PDF')} type={1} data={caseInfo}/>
        </div>
        <div className="flex flex-col gap-1">
          <div class="font-bold text-[14px] text-[#1A1C21]">Requested Return Date:</div>
          <div className="text-[14px] text-[#667085]">
            {`${caseInfo?.return_date?.replace(/\s\d{2}:\d{2}:\d{2}\sGMT$/, '')}`}
          </div>
        </div>
        <div className="flex flex-row content-center">
          <div
            className="gap-1 content-center"
            data-html2canvas-ignore="true"
          >
          </div>
          <div className="flex flex-col">
            <div className="font-bold text-[16px] text-[#1A1C21]">Patient</div>
            <div className="text-[14px] text-[#667085]">{`${capitalize(
              caseInfo?.patient_last
            )}, ${capitalize(caseInfo?.patient_first)}`}</div>
          </div>
        </div>
        <div className="flex" data-html2canvas-ignore="true">
          <div className="flex flex-row gap-1 content-center">
          </div>
          <div className="flex flex-col">
            <div className="font-bold text-[16px] text-[#1A1C21]">Status</div>
            <button className="btn btn-info btn-sm no-animation text-[#13B2E4] cursor-default border-none hover:bg-[#E8F8FD] hover:border-none">
              {capitalize(caseInfo?.status)}
            </button>
          </div>
        </div>
      <div className="flex flex-row gap-1 content-center">
        <div className="flex flex-col gap-1">
          <div className="font-bold text-[16px] text-[#1A1C21]">Doctor</div>
          <div className="text-[14px] text-[#667085]">{`Dr. ${capitalize(
            caseInfo?.doctor?.doctor_first
          )} ${capitalize(caseInfo?.doctor?.doctor_last)}`}</div>
        </div>
      </div>
      <div className="flex justify-start">
      <div className="carousel rounded-box w-[558px]">
        {caseInfo?.items?.map((item, ind) => (
          <div id={`${caseInfo?.id}item${ind + 1}`} className="carousel-item flex flex-row gap-2 m-2">
            <div
              key={ind}
              className="flex flex-row content-center px-2 p-2"
            >
              <div className="flex flex-col w-[180px] gap-1">
                <div className="my-1 text-[16px] text-[#5C59E8] font-bold">{`${ordin[ind]} Item`}</div>
                <div>
                  <div className="font-bold text-[14px] text-[#1A1C21]">Type</div>
                  <div className="text-[14px] text-[#667085]">{`${capitalize(
                    item["type"]
                  )}`}</div>
                </div>
                <div>
                  <div className="font-bold text-[14px] text-[#1A1C21]">
                    Teeth
                  </div>
                  <div className="text-[14px] text-[#667085] break-words">
                    {item["teethArr"].toString()}
                  </div>
                </div>
                {Object.keys(item)
                  .filter((s) => !filtArr.includes(s))
                  .map((j, ind2) => (
                    <div key={`item_${ind2}`}>
                      <div className="font-bold text-[14px] text-[#1A1C21]">
                        {replaceUnderscoresWithSpaces(j)}
                      </div>
                      <div className="text-[14px] text-[#667085]">
                        {capitalize(item[j])}
                      </div>
                    </div>
                  ))}
                <div>
                  <div className="font-bold text-[14px] text-[#1A1C21]">
                    Images
                  </div>
                  <div className="text-[14px] text-[#667085]">{`${
                    item.images && item["images"].length
                      ? item["images"].length
                      : "0"
                  } ${
                    item.images && item["images"].length == 1 ? "File" : "Files"
                  }`}</div>
                </div>
              </div>
              <div className="flex flex-col sticky w-[360px]">
                {!!item["notes"] && (
                  <div className="relative">
                    <div
                      className="text-[14px] text-[#667085] break-words"
                    >
                      {item["notes"]}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
      </div>
      <div className="flex justify-start gap-2">
        {caseInfo?.items?.map((_, ind) => (
        <a href={`#${caseInfo?.id}item${ind + 1}`} className="btn btn-xs btn-active">{ind + 1}</a>
        ))}
                </div>
             </div>
        <div className='card-body pt-4'>
            <AdminTextInputList caseInfo={caseInfo} />
        </div>
      </div>
    </div>  
      </div>
    </div>
  )
}

export default AdminCaseView