import React from "react";
import DashboardCalendar from "../components/DashboardCalendar";

function Dashboard() {

  return (
    <div className="flex flex-col p-6 gap-6">
        <DashboardCalendar/>
    </div>
  );
}
export default Dashboard;
